import React, {useState, useEffect} from 'react';
import {Alert, Box, Button, Divider, IconButton, styled, TextField, Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {
    downloadProfileVaccinationDocument
} from '../../../../../api/services/filesService';
import {STUDENT_PROFILE_CONFIRMATION_TYPES} from '../../const/StudentProfileConfirmationTypes';
import ConfirmationWrapper from '../../Components/ConfirmationWrapper';
import {FileDownload, UploadFile} from '@mui/icons-material';
import {useGetGpDetailsQuery, useManageGPDetailsMutation} from '../../../../../api/services/studentService';
/* eslint-disable react/prop-types */

const GPInfo = ({
    readOnly,
    studentId,
    downloadFile,
    isEdit,
    onConfirmSuccess
}) => {
    const {currentSchool, selectedProfile} = useSelector(state => state.profilesInfo);
    const [isVaccinationDeleteClicked, setIsVaccinationDeleteClicked] = useState(false);
    const [vaccinationFile, setVaccinationFile] = useState(null);
    const [vaccinationFileToDelete, setVaccinationFileToDelete] = useState(null);
    const [manageGPDetails] = useManageGPDetailsMutation();
    const [isFileSizeError, setIsFileSizeError] = useState(false);
    const [gpDetails, setGpDetails] = useState('');
    const {
        data: medicalInfo = {options: []},
        isLoading: isGpDetailsLoading,
        refetch: refetchGpDetailsData
    } = useGetGpDetailsQuery(studentId);

    useEffect(() => {
        if (!isGpDetailsLoading) {
            setGpDetails(medicalInfo.gpInfo);
        }
    }, [isGpDetailsLoading, medicalInfo]);

    const isNothingChanged = () => {
        const isGpDetailsInitial = gpDetails === medicalInfo.gpInfo;
        const isVaccinationFileInitial = vaccinationFile === null && vaccinationFileToDelete === null;
        return isGpDetailsInitial && isVaccinationFileInitial;
    };

    const DownloadIcon = styled(FileDownload)(({theme}) => ({
        color: theme.palette.primary.main, cursor: 'pointer'
    }));

    const isGpInfoInitialised = medicalInfo?.gpInfo !== null && medicalInfo?.gpInfo !== undefined && medicalInfo?.gpInfo !== '';

    const handleVaccinationFileUploaded = (file) => {
        if (!file) return;

        const fileSizeInMB = file.size / (1024 * 1024);
        if (fileSizeInMB > 15) {
            setIsFileSizeError(true);
            setVaccinationFile({name: file.name});
        } else {
            setIsFileSizeError(false);
            setVaccinationFile(file);
        }

        if (medicalInfo.vaccination?.fileId) {
            setVaccinationFileToDelete(medicalInfo.vaccination?.fileId);
        }

        setIsVaccinationDeleteClicked(false);
    };

    const handleVaccinationFileDelete = async (fileId) => {
        setIsVaccinationDeleteClicked(true);
        if (vaccinationFile !== null) {
            setVaccinationFile(null);
        } else {
            setVaccinationFileToDelete(fileId);
        }
        setIsFileSizeError(false);
    };
    const handleSave = async (confirmationType) => {
        const payload = {
            studentId,
            confirmationType,
            vaccination: vaccinationFile,
            gpDetails,
            deletedVaccinationFileId: vaccinationFileToDelete,
        };
        await manageGPDetails(payload)
        setVaccinationFile(null);
        setVaccinationFileToDelete(null);
    };

    return (
        isGpDetailsLoading
            ? ''
            : <>
                <ConfirmationWrapper
                    readonly={readOnly}
                    confirmData={{
                        confirmMessage: `I confirm that the information in the medical profile section of ${selectedProfile.name} ${selectedProfile.surname} student profile is correct and understand it will be relied upon by school staff and any other person or organisation who may have ${selectedProfile.name} ${selectedProfile.surname} in their care on behalf of ${currentSchool.name}`,
                        confirmedAt: medicalInfo?.gpInfoConfirmedAt,
                        confirmedBy: medicalInfo?.gpInfoConfirmedBy,
                        enableSaveButton: !isNothingChanged(),
                        enableConfirmButton: isNothingChanged() && isGpInfoInitialised,
                        studentId,
                        type: STUDENT_PROFILE_CONFIRMATION_TYPES?.GpInfo,
                        handleSave,
                        onConfirmSuccess: async () => {
                            await onConfirmSuccess();
                            await refetchGpDetailsData();
                        },
                        confirmButtonText: 'Confirm GP Details'
                    }}
                >
                    <Box>
                        <Typography variant="h5" py={2}>GP Details</Typography>
                        <Typography variant="body1">Please provide the name and address
                            of {`${selectedProfile.name}’s`} GP</Typography>
                        <TextField
                            multiline
                            fullWidth
                            value={gpDetails || ''}
                            onChange={event => event.target.value.length < 255 && setGpDetails(event.target.value)}
                            disabled={!isEdit}
                        />
                    </Box>
                    <Typography variant="body1" sx={{paddingTop: 3}}>Please upload {`${selectedProfile.name}’s`} vaccination
                        record. This can
                        be obtained from your GP surgery.</Typography>
                    <Box py={1} display={'flex'} justifyContent={'space-between'}>
                        <Box
                            display="flex"
                            alignItems="center"
                        >
                            <Button
                                component="label"
                                variant="outlined"
                                startIcon={<UploadFile/>}
                                sx={{marginRight: 1}}
                                disabled={!isEdit}
                            >
                                Upload
                                <input type="file" hidden
                                    onChange={event => handleVaccinationFileUploaded(event.target.files[0])}/>

                            </Button>
                            {!isVaccinationDeleteClicked &&
                                <>
                                    <Typography>
                                        {vaccinationFile ? vaccinationFile.name : medicalInfo.vaccination?.fileName}
                                    </Typography>
                                    {medicalInfo.vaccination?.fileId && vaccinationFile === null &&
                                        <IconButton onClick={async () => await downloadFile(
                                            downloadProfileVaccinationDocument,
                                            {
                                                documentId: medicalInfo.vaccination?.fileId,
                                                studentId
                                            })
                                        }>
                                            <DownloadIcon></DownloadIcon>
                                        </IconButton>
                                    }
                                </>
                            }
                        </Box>
                        <Button variant="outlined"
                            onClick={() => handleVaccinationFileDelete(medicalInfo.vaccination?.fileId)}
                            sx={{
                                width: '100px',
                                visibility: (!medicalInfo.vaccination?.fileId && vaccinationFile === null) || isVaccinationDeleteClicked ? 'hidden' : 'visible'
                            }}
                            disabled={!isEdit}
                        >
                            Delete
                        </Button>
                    </Box>
                    {
                        isFileSizeError && <Alert sx={{marginTop: 2}} severity="error">
                            The total size of the file exceeds 15 MB.
                        </Alert>
                    }
                </ConfirmationWrapper>
                <Divider sx={{mt: 2, mb: 2}}/>
            </>
    );
};

export default GPInfo;