import {apiService} from '../apiService';

export const schoolManagementService = apiService.injectEndpoints({
    endpoints: (builder) =>
        ({
            getSchool: builder.query({
                query: (schoolId) => ({
                    url: `/school/${schoolId}`
                }),
            }),
            getSchoolInvitesInfo: builder.query({
                query: (subdomain) => ({
                    url: `/school/${subdomain}/invites-info`
                }),
            }),
            createSchool: builder.mutation({
                query: (body) => ({
                    url: '/school',
                    method: 'POST',
                    data: body,
                })
            }),
            updateSchool: builder.mutation({
                query: (body) => ({
                    url: '/school',
                    method: 'PUT',
                    data: body,
                })
            }),
            getContactInvites: builder.query({
                query: (body) => ({
                    url: '/school/contact-invites',
                    method: 'POST',
                    data: body,
                }),
                providesTags: (_) => ['ContactInvites'],
            }),
            resendInvites: builder.mutation({
                query: (contactIds) => ({
                    url: '/school/contact-invites/resend',
                    method: 'POST',
                    data: {contactIds},
                }),
                invalidatesTags: ['ContactInvites'],
            }),
            getSchoolUsers: builder.query({
                query: (body) => ({
                    url: '/school/users',
                    method: 'POST',
                    data: body,
                }),
                providesTags: (_) => ['SchoolUsers'],
            }),
            getSyncDetails: builder.query({
                query: (body) => ({
                    url: '/school/sync-details',
                    method: 'POST',
                    data: body,
                })
            }),
            getApplicationStatuses: builder.query({
                query: () => ({
                    url: '/school/application-statuses'
                }),
                keepUnusedDataFor: 300
            }),
            getConsentDescription: builder.query({
                query: () => ({
                    url: '/school/consent-description'
                }),
                providesTags: (_) => ['GeneralConsentDescription'],
            }),
            updateConsentDescription: builder.mutation({
                query: (body) => ({
                    url: '/school/consent-description',
                    method: 'PATCH',
                    data: body,
                }),
                invalidatesTags: ['GeneralConsentDescription'],
            }),
        }),
});

export const {
    useGetSchoolQuery,
    useGetSchoolInvitesInfoQuery,
    useCreateSchoolMutation,
    useUpdateSchoolMutation,
    useGetContactInvitesQuery,
    useResendInvitesMutation,
    useGetSchoolUsersQuery,
    useGetSyncDetailsQuery,
    useGetApplicationStatusesQuery,
    useGetConsentDescriptionQuery,
    useUpdateConsentDescriptionMutation,
} = schoolManagementService;
