import EmployeeProfile from './EmployeeProfile';
import {useGetEmployeeByCurrentUserQuery} from '../../../api/services/employeeService';
import {Box} from '@mui/material';

const MyProfileEmployeeInformation = () => {
    const {data = [], isLoading} = useGetEmployeeByCurrentUserQuery();

    return <Box marginTop={2}>
        <EmployeeProfile data={data} isLoading={isLoading}/>
    </Box>;
};

export default MyProfileEmployeeInformation;