import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import api from '../axios';
import NOTIFICATION_TYPE from '../constants/notificationTypes';
import {ROLES} from '../constants/roles';
import {getToken} from '../utility/jwtUtil';
import {jwtDecode} from 'jwt-decode';

const initialAuthData = {
    roles: [ROLES.NONE],
    id: undefined,
    schoolId: undefined,
    domain: undefined,
    permissions: []
};

const getUserInfoFromToken = (token) => {
    if (!token) {
        return initialAuthData;
    }

    try {
        const decodedToken = jwtDecode(token);

        if (!decodedToken) {
            return initialAuthData;
        }

        return {
            ...initialAuthData,
            roles: Array.isArray(decodedToken.role)
                ? decodedToken.role
                : [decodedToken.role],
            id: decodedToken.sub,
            schoolId: decodedToken.SchoolId,
            domain: decodedToken.Domain,
            schoolLogoUrl: `${process.env.REACT_APP_BACKEND_URL}/school/${decodedToken.SchoolId}/logo/logo.png`
        };
    } catch (e) {
        console.error(e);
    }

    return initialAuthData;
};

const initialTokenValue = getToken();
const initialUser = getUserInfoFromToken(initialTokenValue);

const initialState = {
    notification: {
        type: NOTIFICATION_TYPE.STANDARD,
        open: false,
        severity: 'info',
        duration: 3000,
        snackbar: {
            message: ''
        },
        detailedSnackbar: {
            title: '',
            messages: []
        }
    },
    account: {
        firstName: '',
        lastName: '',
        schoolName: '',
        role: ROLES.NONE,
        isAuthenticated: false,
    },
    authData: initialUser,
    breadcrumbs: undefined,
    isLoadingUserData: false
};

export const login = createAsyncThunk(
    'user/login',
    async (credentials) => {
        await api.post('/account/login', credentials);

        return {
            token: getToken()
        };
    }
);

export const refresh = createAsyncThunk(
    'user/refreshToken',
    async (_) => {
        await api.post('/account/refresh');

        const getPermissionsRequest = await api.get('/account/user-permissions');
        const permissions = getPermissionsRequest.data || [];

        return {
            token: getToken(),
            permissions
        };
    }
);

export const logout = createAsyncThunk(
    'logout',
    async (thunkAPI) => {
        try {
            await api.post('/account/revoke');
        } catch (error) {
            return thunkAPI.rejectWithValue({error: error.message});
        }
    }
);

export const getUser = createAsyncThunk(
    'getUser',
    async (thunkAPI) => {
        try {
            const getPermissionsRequest = await api.get('/account/user-permissions');
            const getAccountRequest = await api.get('/account');

            return {
                account: getAccountRequest.data,
                permissions: getPermissionsRequest.data ?? []
            };
        } catch (error) {
            return thunkAPI.rejectWithValue({error: error.message});
        }
    }
);

const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        showSnackbar: (state, action) => {
            state.notification = {
                ...state.notification,
                type: NOTIFICATION_TYPE.STANDARD,
                open: true,
                severity: action.payload.severity,
                snackbar: {...state.notification.snackbar, message: action.payload.message}
            };
        },
        showDetailedSnackbar: (state, action) => {
            state.notification = {
                ...state.notification,
                type: NOTIFICATION_TYPE.DETAILED,
                open: true,
                severity: action.payload.severity,
                detailedSnackbar: {
                    ...state.notification.detailedSnackbar,
                    title: action.payload.title,
                    messages: action.payload.messages
                }
            };
        },
        hideSnackbar: (state) => {
            state.notification.open = false;
        },
        resetBreadcrumbs: state => {
            state.breadcrumbs = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(logout.fulfilled, (state) => {
                state.account = initialState.account;
                state.authData = getUserInfoFromToken('');
            })
            .addCase(getUser.pending, (state) => {
                state.isLoadingUserData = true;
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.account = action.payload.account;
                state.authData = {
                    ...state.authData,
                    permissions: action.payload.permissions
                };
                state.isLoadingUserData = false;
            })
            .addCase(getUser.rejected, (state) => {
                state.isLoadingUserData = false;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.authData = getUserInfoFromToken(action.payload.token);
                state.account.isAuthenticated = true;
            })
            .addCase(login.rejected, (state) => {
                state.account.isAuthenticated = false;
            })
            .addCase(refresh.fulfilled, (state, action) => {
                state.authData = {
                    ...getUserInfoFromToken(action.payload.token),
                    permissions: action.payload.permissions
                };
            })
            .addCase(refresh.rejected, (state) => {
                state.account.isAuthenticated = false;
            });
    }
});

export const {
    showSnackbar,
    hideSnackbar,
    showDetailedSnackbar,
} = applicationSlice.actions;

export default applicationSlice.reducer;
