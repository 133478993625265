import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import {getRouteForUser, ROLE_BASE_ROUTE, ROLES} from '../constants/roles';
import useUser from '../utility/hooks/useUser';
import {getRefreshToken, getToken} from '../utility/jwtUtil';
import {getUser} from '../AppLayout/ApplicationSlice';
import {useDispatch, useSelector} from 'react-redux';
import {Backdrop, CircularProgress, Stack} from '@mui/material';
import Typography from '@mui/material/Typography';

const ProtectedRoute = ({children, allowedRoles}) => {
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const userContext = useUser();
    const dispatch = useDispatch();
    const {user} = useUser();
    const isLoadingUserData = useSelector(state => state.application.isLoadingUserData);

    useEffect(() => {
        if (user.id) {
            dispatch(getUser());
        }
    }, [user.id]);

    const getNavigationProps = () => {
        if (!getRefreshToken() || !getToken()) {
            return {route: ROLE_BASE_ROUTE[ROLES.NONE].path, args: {state: {redirectTo: pathname}}};
        }

        if (userContext?.user) {
            if (!userContext.user.hasRoles(allowedRoles)) {
                return {route: getRouteForUser(userContext.user), args: {replace: true}};
            }
        }
    };

    useEffect(() => {
        const navProps = getNavigationProps();

        if (navProps) {
            navigate(navProps.route, navProps.args ?? {});
        }
    }, [userContext, pathname]);

    return !isLoadingUserData
        ? children
        : <Backdrop
            sx={(theme) => ({color: '#fff', zIndex: theme.zIndex.drawer + 1})}
            open={isLoadingUserData}
        >
            <Stack flexDirection="column" alignItems="center" gap={2}>
                <CircularProgress size={40} color="inherit"/>
                <Typography>Initializing user</Typography>
            </Stack>
        </Backdrop>;
};

ProtectedRoute.propTypes = {
    children: PropTypes.object.isRequired,
    allowedRoles: PropTypes.array.isRequired
};

export default ProtectedRoute;
