import React, {useMemo} from 'react';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {alpha, Box, Divider, ListItemIcon, Menu, MenuItem, styled} from '@mui/material';
import logo from '../img/admicity_logo.png';
import PropTypes from 'prop-types';
import {Logout, HelpOutline} from '@mui/icons-material';
import useUser from '../utility/hooks/useUser';
import {ROLES} from '../constants/roles';

const StyledAppBar = styled(AppBar)({
    color: 'rgba(0, 0, 0, 0.87)',
    borderRadius: 0,
    padding: 8,
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)',
    backgroundColor: alpha('#ffffff', 0.72),
    borderBottom: '1px solid #e0e0e0',
    zIndex: 10000,
});

const Header = ({account, displayActions, handleLogout}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const shouldOpenAccountMenu = Boolean(anchorEl);
    const {user} = useUser();

    const openAccountMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAccountMenuClose = () => {
        setAnchorEl(null);
    };

    const isHelpCentreVisible = useMemo(() => {
        return user.hasRoles([
            ROLES.SUPER_ADMIN,
            ROLES.SCHOOL_ADMIN,
            ROLES.MEMBER_OF_STAFF,
            ROLES.SENIOR_MEMBER_OF_STAFF,
        ]);
    }, [user.roles]);

    return (<>
        <StyledAppBar position="sticky">
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between">
                <img src={logo} alt="Admicity logo" style={{height: 47, marginTop: displayActions ? 4 : 4, marginBottom: displayActions ? 0 : 5, marginLeft: 3}} />
                {
                    displayActions
                        ? <>
                            <Box
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                            >
                                <Typography
                                    sx={{
                                        display: {
                                            xs: 'none',
                                            sm: 'none',
                                            md: 'block',
                                            lg: 'block',
                                        },
                                    }}
                                    color="inherit"
                                    variant="p"
                                    component="h4"
                                >
                                    {`${account.firstName} ${account.lastName}`}
                                </Typography>
                                <IconButton color="inherit" sx={{p: 1}} onClick={openAccountMenu}>
                                    <Avatar alt="My Avatar" src={account.schoolName}/>
                                </IconButton>
                            </Box>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={shouldOpenAccountMenu}
                                onClose={handleAccountMenuClose}
                                onClick={handleAccountMenuClose}
                                slotProps={{
                                    paper: {
                                        sx: {
                                            minWidth: 300,
                                            maxWidth: 350
                                        }
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        display: {
                                            xs: 'block',
                                            sm: 'block',
                                            md: 'none',
                                            lg: 'none',
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            paddingLeft: 2,
                                            paddingRight: 2,
                                            paddingTop: 1,
                                            paddingBottom: 1,
                                            wordWrap: 'break-word'
                                        }}
                                        color="inherit"
                                        variant="p"
                                        component="h4"
                                    >
                                        {`${account.firstName} ${account.lastName}`}
                                    </Typography>
                                    <Divider/>
                                </Box>
                                {isHelpCentreVisible && (
                                    <MenuItem
                                        sx={{ mt: 1 }}
                                        onClick={() => {
                                            window.open(
                                                'https://www.admicity.co.uk/help-centre/',
                                                '_blank',
                                                'noopener,noreferrer'
                                            );
                                        }}
                                    >
                                        <ListItemIcon>
                                            <HelpOutline fontSize="small" />
                                        </ListItemIcon>
                                        Help Centre
                                    </MenuItem>
                                )}
                                <MenuItem
                                    onClick={() => {
                                        handleLogout();
                                        handleAccountMenuClose();
                                    }}>
                                    <ListItemIcon><Logout fontSize="small"/></ListItemIcon>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </>
                        : ''
                }
            </Box>
        </StyledAppBar>
    </>);
};

Header.propTypes = {
    displayActions: PropTypes.bool,
    handleLogout: PropTypes.func,
    account: PropTypes.object
};

Header.defaultProps = {
    displayActions: true
};

export default Header;