import React, {useRef} from 'react';
import {Box, Card, CircularProgress, styled, TablePagination} from '@mui/material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import ProfileStatus from './ProfileStatus';
import PropTypes from 'prop-types';
import useDynamicHeight from '../../../../utility/hooks/useDynamicHeight';
import Tooltip from '@mui/material/Tooltip';

const ProfileCard = styled(Card)({
    width: '170px',
    height: '100px',
    padding: '5px',
    paddingTop: 0,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'space-between'
});

const ProfileInfoBox = styled(Box)({
    width: '100%',
    height: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
});

const ProfilesGrid = (
    {
        data,
        onProfileSelect,
        handlePaginationModelChange,
        totalItemCount,
        pageSize,
        pageNumber,
        isLoading,
        noRowsOverlay,
        readonly
    }) => {
    const parentRef = useRef(null);
    const paginationRef = useRef(null);
    const freeSpaceHeight = useDynamicHeight(parentRef);

    const handleChangePage = (event, newPage) => {
        handlePaginationModelChange(newPage, pageSize);
    };

    const handleChangeRowsPerPage = (event) => {
        handlePaginationModelChange(0, event.target.value);
    };

    const OverlayContainer = props => {
        let {children} = props;
        return <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flexGrow={1}
        >
            {children}
        </Box>;
    };

    OverlayContainer.propTypes = {children: PropTypes.any};

    const buildStudentName = (name) => name && name.length > 10 ? `${name.substring(0, 9)}...` : name;

    const buildConfirmIcon = (profile) => {
        if (profile.hasExistingContactAccount === false) {
            return <Tooltip title="None of the parent accounts have been created"><ErrorOutlineOutlinedIcon
                sx={{pt: '5px'}} style={{color: 'red'}}/></Tooltip>
        }

        return !profile.needsConfirmation || profile.needsConfirmation.length === 0
            ? <Tooltip title="Confirmed"><CheckOutlinedIcon color="success" sx={{pt: '5px'}}/></Tooltip>
            : <Tooltip title={`${profile.needsConfirmation} sections needs confirmation`}><ErrorOutlineOutlinedIcon
                sx={{pt: '5px'}} style={{color: 'orange'}}/></Tooltip>
    }

    const getProfileListGrid = () => {
        return data?.map((profile) =>
            (<Grid item key={profile.id}>
                <ProfileCard
                    elevation={5}
                    onClick={() => {
                        onProfileSelect(profile);
                    }}
                    sx={readonly ? {cursor: 'default'} : {}}
                >
                    <Avatar
                        variant="square"
                        sx={{
                            width: 60,
                            height: 90,
                            borderRadius: 1,
                            marginTop: '5px'
                        }}
                        src={`data:image/jpeg;base64,${profile.photo}`}
                    />
                    <Box display={'flex'} flexDirection={'column'} justifyContent={'space-between'} height={'100%'}>
                        {
                            profile.isApplicant !== undefined &&
                            <Box display={'flex'} justifyContent={'end'}>
                                {buildConfirmIcon(profile)}
                            </Box>
                        }
                        <ProfileInfoBox>
                            <Box>
                                <Typography variant="subtitle2" width={'100px'} >{buildStudentName(profile.name)}</Typography>
                                <Typography variant="subtitle2" width={'100px'}>{buildStudentName(profile.surname)}</Typography>
                            </Box>

                            {
                                profile.isApplicant
                                    ? <Box display={'flex'} justifyContent={'end'}>
                                        <ProfileStatus status={profile.status} statusCategory={profile.statusCategory}
                                                       isStudentProfile={false}/>
                                    </Box>
                                    : <></>
                            }
                        </ProfileInfoBox>
                    </Box>
                </ProfileCard>
            </Grid>));
    };

    const NoProfilesView = () => <OverlayContainer>
        {
            noRowsOverlay?.component
                ? noRowsOverlay?.component
                : <Typography variant="h6">No data</Typography>
        }
    </OverlayContainer>;

    return (
        <Box
            ref={parentRef}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1
            }}
        >
            {
                isLoading
                    ? <OverlayContainer>
                        <CircularProgress size={70}/>
                    </OverlayContainer>
                    : <>
                        {
                            data?.length === 0
                                ? <NoProfilesView/>
                                : <>
                                    <Box sx={{
                                        paddingBottom: 2,
                                        maxHeight: `${freeSpaceHeight - 55 - 16}px`,
                                        overflowY: 'scroll'
                                    }}>
                                        <Grid
                                            container
                                            spacing={2}
                                        >
                                            {getProfileListGrid()}
                                        </Grid>
                                    </Box>
                                    <TablePagination
                                        ref={paginationRef}
                                        sx={{flexShrink: 0, height: 52}}
                                        rowsPerPageOptions={[20, 50, 100]}
                                        labelRowsPerPage="Profiles per page"
                                        component="div"
                                        count={totalItemCount}
                                        rowsPerPage={pageSize}
                                        page={pageNumber}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </>
                        }
                    </>
            }
        </Box>
    );
};

ProfilesGrid.propTypes = {
    data: PropTypes.array,
    onProfileSelect: PropTypes.func,
    handlePaginationModelChange: PropTypes.func,
    totalItemCount: PropTypes.number,
    pageSize: PropTypes.number,
    pageNumber: PropTypes.number,
    isLoading: PropTypes.bool,
    noRowsOverlay: PropTypes.object,
    readonly: PropTypes.bool
};

export default ProfilesGrid;