import {apiService} from '../apiService';

const getFormResponsesBase = {
    transformErrorResponse: (_) => ({message: 'Failed to retrieve form requests'}),
    transformResponse: (response) => ({
        ...response,
        items: response.items.map(({data, ...rest}) => ({
            data: JSON.parse(data),
            ...rest
        }))
    })
};

const getFormRequestBaseOptions = {
    transformResponse: (response) => {
        const {
            id,
            name,
            schema,
            data,
            status,
            schoolName,
            studentForename,
            hisHer,
            heShe,
            himHer,
            studentFullName,
            placeholders,
            title,
            parentFullName,
            employeeFullName,
            studentId,
            isParent
        } = response;
        return {
            formId: id,
            formName: name,
            schema: schema ? JSON.parse(schema) : {},
            data: data ? JSON.parse(data) : {},
            isParent,
            placeholderValues: placeholders
                ? JSON.parse(placeholders)
                : {
                    schoolName,
                    studentForename,
                    studentFullName,
                    hisHer,
                    heShe,
                    himHer,
                    title,
                    parent: parentFullName,
                    employee: employeeFullName,
                },
            status,
            studentId
        };
    },
    transformErrorResponse: (error) => {
        const errorMessages = {
            500: 'Internal server error.',
            404: 'Could not find the requested form.'
        };

        return {
            statusText: error.status,
            message: errorMessages[error.status] ?? 'Something went wrong.'
        };
    }
};

export const formRequestsService = apiService.injectEndpoints({
    endpoints: (builder) =>
        ({
            getFormRequests: builder.query({
                query: (body) => ({
                    url: `/forms/${body.formId}/requests`,
                    method: 'POST',
                    data: body,
                }),
                ...getFormResponsesBase
            }),
            getFormRequest: builder.query({
                query: (requestId) => {
                    return ({url: `/forms/requests/${requestId}`});
                },
                ...getFormRequestBaseOptions
            }),
            getMyFormRequest: builder.query({
                query: (requestId) => ({
                        url: `/forms/my/requests/${requestId}`
                }),
                ...getFormRequestBaseOptions
            }),
            getGeneralFormRequest: builder.query({
                query: ({formId, studentId}) => ({
                    url: `/forms/general/${formId}/students/${studentId}`
                }),
                ...getFormRequestBaseOptions
            }),
            submitForm: builder.mutation({
                query: (body) => ({
                    url: '/forms/submit',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    data: body,
                }),
                transformErrorResponse: (error) => {
                    const defaultErrorMessage = ['Failed to submit form.'];
                    const errorMessages = {
                        400: error?.data?.validationErrors
                            ? error.data.validationErrors.map(x => x.errorMessage).join('; ')
                            : defaultErrorMessage,
                        409: ['This form is no longer available. It may be inactive, expired, or already submitted. Please refresh the page and try again.']
                    };

                    return errorMessages[error.status] ?? defaultErrorMessage;
                }
            }),
            submitPublicForm: builder.mutation({
                query: (body) => ({
                    url: '/forms/public/submit-form',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    data: body,
                }),
                transformErrorResponse: () => 'Failed to submit form.'
            }),
            getFormsByStudent: builder.query({
                query: (body) => ({
                    url: `/forms/requests/student/${body.studentId}`,
                    method: 'POST',
                    data: body,
                }),
                ...getFormResponsesBase
            }),
            getGeneralForms: builder.query({
                query: (studentId) => ({
                    url: `/forms/general/student/${studentId}`
                }),
                transformResponse: (response) => response?.map(({formName, ...rest}) => ({
                    name: formName,
                    ...rest
                }))
            }),
            getMyForms: builder.query({
                query: (body) => ({
                    url: '/forms/my/requests',
                    method: 'POST',
                    data: body,
                }),
                ...getFormResponsesBase
            }),
            getUsersSubmittedFormRequest: builder.query({
                query: (requestId) => ({
                    url: `/forms/requests/${requestId}/submitted-users`
                })
            }),
            resendFormRequest: builder.mutation({
                query: (body) => ({
                    url: '/forms/request/resend',
                    method: 'POST',
                    data: body,
                }),
                transformErrorResponse: () => 'Failed to resend the form.'
            }),
        }),
});

export const {
    useGetFormRequestsQuery,
    useGetFormRequestQuery,
    useGetMyFormRequestQuery,
    useGetGeneralFormRequestQuery,
    useSubmitFormMutation,
    useSubmitPublicFormMutation,
    useGetFormsByStudentQuery,
    useGetGeneralFormsQuery,
    useGetMyFormsQuery,
    useGetUsersSubmittedFormRequestQuery,
    useResendFormRequestMutation
} = formRequestsService;
