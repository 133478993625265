import React, {useState, useEffect} from 'react';
import {Box, Button, Divider, Typography} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import {downloadProfileMedicalNoteDocument} from '../../../../../api/services/filesService';
import {FileDownload, UploadFile} from '@mui/icons-material';
import {useSelector} from 'react-redux';
import AdmicityForm from '../../../../../shared-components/AdmicityForm';
import AdmicityDialog from '../../../../../shared-components/AdmicityDialog';
import EditIcon from '@mui/icons-material/Edit';
import Uploader from '../../../../SchoolManagement/Setup/Uploader';
import useUser from '../../../../../utility/hooks/useUser';
import {STUDENT_PROFILE_CONFIRMATION_TYPES} from '../../const/StudentProfileConfirmationTypes';
import ConfirmationWrapper from '../../Components/ConfirmationWrapper';
import {useGetMedicalNotesQuery, useManageMedicalNotesMutation} from '../../../../../api/services/studentService';
import {generateUUID} from '../../../../../utility/uuidUtil';
/* eslint-disable react/prop-types */

const MedicalNotes = ({
    readonly,
    downloadFile,
    studentId,
    onConfirmSuccess,
}) => {
    const {user} = useUser();
    const userHasParentRole = user.isParent();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isUploadSizeExceedsLimit, setIsUploadSizeExceedsLimit] = useState(false);
    const [totalUploadFilesSize, setTotalUploadFilesSize] = useState(25);
    const {currentSchool, selectedProfile} = useSelector(state => state.profilesInfo);
    const [openDialog, setOpenDialog] = useState(false);
    const [openAddNoteDocumentsDialog, setOpenAddNoteDocumentsDialog] = useState(false);
    const [selectedMedicalNote, setSelectedMedicalNote] = useState(undefined);
    const [manageMedicalNotes] = useManageMedicalNotesMutation();
    const {
        data: medicalInfo = {options: []},
        isLoading: isMedicalNotesLoading,
        refetch: refetchMedicalNotesData
    } = useGetMedicalNotesQuery(studentId);
    const [noteRows, setNoteRows] = useState([]);
    useEffect(() => {
        if (!isMedicalNotesLoading) {
            setNoteRows(medicalInfo.medicalNotes);
        }
    }, [isMedicalNotesLoading, medicalInfo]);

    const medicalNoteTextFields = [
        {
            label: 'Summary',
            name: 'summary',
            required: true,
            initialValue: selectedMedicalNote ? selectedMedicalNote.summary : '',
        },
        {
            label: 'Description',
            name: 'note',
            required: false,
            multiline: true,
            initialValue: selectedMedicalNote ? selectedMedicalNote.note : '',
        }
    ];

    const handleDocumentsUploaded = async () => {
        setTotalUploadFilesSize(totalUploadFilesSize - selectedFiles.reduce((total, file) => {
            return total + file.size;
        }, 0) / (1024 * 1024))

        const updatedNote = {
            ...selectedMedicalNote,
            fileName: selectedFiles[0].name,
            file: selectedFiles[0],
            fileId: undefined
        };

        updateMedicalNotes(updatedNote);
        setSelectedMedicalNote(undefined);
        setOpenAddNoteDocumentsDialog(false);
    };

    const isNothingChanged = () => {
        const isNoteRowsInitial = noteRows?.filter(row => row.isEdited || row.isDeleted || row.isNew).length === 0;
        const isDocumentsSummaryFilled = noteRows?.every(row => !row.documents || row.documents?.every(d => d.summary));
        return isNoteRowsInitial && isDocumentsSummaryFilled;
    };

    const isNewNote = () => {
        const isNew = noteRows?.some(row => row?.isNew)
        return isNew;
    }

    const isNotesInitialised = noteRows?.length > 0;

    const updateMedicalNotes = (updatedValue, isDelete = false) => {
        if (isDelete && updatedValue.isNew) {
            setNoteRows(noteRows.filter(e => updatedValue.id ? e.id !== updatedValue.id : e.uuid !== updatedValue.uuid));
            return;
        }

        if (!updatedValue.id && !updatedValue.uuid) {
            updatedValue.uuid = generateUUID();
            updatedValue.isNew = true;
            setNoteRows([updatedValue, ...noteRows]);
            return;
        }

        const updatedRows = noteRows.map(row => {
            if (updatedValue.id ? row.id === updatedValue.id : row.uuid === updatedValue.uuid) {
                return {
                    ...row,
                    ...updatedValue,
                    isEdited: !updatedValue.isNew,
                    isDeleted: isDelete,
                };
            }
            return row;
        });

        setNoteRows(updatedRows);
        setSelectedFiles([]);
    };

    const handleSave = async (confirmationType) => {
        const payload = {
            studentId,
            confirmationType,

            medicalNotesToDelete: noteRows.filter(e => e.isDeleted).map(e => e.id),
            medicalNotesToAdd: noteRows.filter(e => e.isNew).map(e => ({
                file: e.file,
                note: e.note,
                summary: e.summary
            })),
            medicalNotesToEdit: noteRows.filter(e => e.isEdited && !e.isDeleted).map(e => ({
                id: e.id,
                file: e.file,
                fileId: e.fileId,
                note: e.note,
                summary: e.summary
            })),
        };
        await manageMedicalNotes(payload)
        setTotalUploadFilesSize(25);
    };

    const handleSelectFiles = (files) => {
        const newDocuments = files.filter(file => !selectedFiles.some(s => s.name === file.name));
        setSelectedFiles([...selectedFiles, ...newDocuments]);
    };

    const handleFileDeleteFromUploadContext = (filename) => {
        const files = [...selectedFiles];
        setSelectedFiles(files.filter(f => f.name !== filename));
    };
    return (
        isMedicalNotesLoading
            ? ''
            : <>
                <ConfirmationWrapper
                    readonly={readonly}
                    confirmData={{
                        confirmMessage: `I confirm that the information in the medical profile section of ${selectedProfile.name} ${selectedProfile.surname} student profile is correct and understand it will be relied upon by school staff and any other person or organisation who may have ${selectedProfile.name} ${selectedProfile.surname} in their care on behalf of ${currentSchool.name}`,
                        confirmedAt: medicalInfo?.medicalNotesConfirmedAt,
                        confirmedBy: medicalInfo?.medicalNotesConfirmedBy,
                        enableSaveButton: !isNothingChanged() && (!readonly),
                        enableConfirmButton: isNothingChanged() && !isNewNote() && isNotesInitialised,
                        studentId,
                        type: STUDENT_PROFILE_CONFIRMATION_TYPES?.MedicalNotes,
                        handleSave,
                        onConfirmSuccess: async () => {
                            await onConfirmSuccess();
                            await refetchMedicalNotesData();
                        },
                        confirmButtonText: 'Confirm Medical Notes'
                    }}
                >
                    <Typography variant="h5" py={2}>Medical Notes</Typography>
                    <Typography>
                Please use this section to add details of any medical condition that does not appear on the above list
                or that has not yet been formally diagnosed. It can also be used to share any other medical information
                that you would like {currentSchool.name} to be aware of that is not related to a specific medical condition or
                medical event. {currentSchool.name} may also update this section during the school day and you will receive an
                email alert to inform you of any updates to {selectedProfile.name}’s Admicity profile.
                    </Typography>
                    {
                        !readonly && <Button
                            variant="contained"
                            sx={{mb: 2, mt: 2, width: 150}}
                            startIcon={<AddCircleOutlineIcon/>}
                            onClick={() => setOpenDialog(true)}
                        >
                    Add
                        </Button>
                    }
                    {noteRows?.filter(e => !e.isDeleted)?.map(note =>

                        <Box display={'flex'} key={note?.uuid || note?.id} justifyContent={'space-between'}>
                            <Box>
                                <Typography fontWeight="bold">{note.summary}</Typography>
                                <Typography>{note.note}</Typography>
                                {!readonly && ((userHasParentRole && note.uuid) || !userHasParentRole) && !note.file && !note.fileId &&
                                <Button
                                    sx={{my: 1}}
                                    variant="outlined"
                                    startIcon={<UploadFile/>}
                                    onClick={() => {
                                        setSelectedMedicalNote(note)
                                        setOpenAddNoteDocumentsDialog(true);
                                    }}
                                >
                                    Upload Document
                                </Button>
                                }
                                {!readonly && ((userHasParentRole && note.uuid) || !userHasParentRole) &&
                                note.fileName && <Box key={note} display={'flex'} alignItems={'center'}>
                                    <IconButton onClick={() => {
                                        setSelectedMedicalNote(note);
                                        setOpenAddNoteDocumentsDialog(true);
                                    }}>
                                        <UploadFile/>
                                    </IconButton>
                                    <Typography>{note.fileName}</Typography>
                                </Box>
                                }
                            </Box>
                            <Box display={'flex'} sx={{height: '40px'}}>
                                {note.fileId && !note.file &&
                                <IconButton
                                    onClick={async () => {
                                        await downloadFile(downloadProfileMedicalNoteDocument, {
                                            documentId: note.fileId, studentId
                                        })
                                    }}
                                >
                                    <FileDownload/>
                                </IconButton>
                                }
                                {
                                    !readonly && ((userHasParentRole && note.uuid) || !userHasParentRole) &&
                                <>
                                    <IconButton
                                        onClick={() => {
                                            setSelectedMedicalNote(note);
                                            setOpenDialog(true)
                                        }}>
                                        <EditIcon/>
                                    </IconButton>
                                    <IconButton
                                        onClick={() => updateMedicalNotes(note, true)}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </>
                                }
                            </Box>
                        </Box>

                    )}
                    <AdmicityDialog
                        handleClose={() => {
                            setOpenDialog(false);
                            setSelectedMedicalNote(undefined);
                        }}
                        title={'New Medical Note'}
                        open={openDialog}
                        actions={[]}
                    >
                        <AdmicityForm
                            textFields={medicalNoteTextFields}
                            handleSubmit={(data) => {
                                updateMedicalNotes({
                                    ...selectedMedicalNote,
                                    ...data
                                });
                                setOpenDialog(false);
                                setSelectedMedicalNote(undefined);
                            }}
                            buttonText="Save"
                        />
                    </AdmicityDialog>

                    <AdmicityDialog
                        handleClose={() => {
                            setOpenAddNoteDocumentsDialog(false)
                            setSelectedMedicalNote(undefined);
                        }}
                        title={'Add Documents'}
                        open={openAddNoteDocumentsDialog}
                        actions={[
                            {
                                label: 'Cancel',
                                onClick: () => {
                                    setOpenAddNoteDocumentsDialog(false)
                                    setSelectedMedicalNote(undefined);
                                }
                            },
                            {
                                label: 'Upload',
                                onClick: handleDocumentsUploaded,
                                disabled: selectedFiles?.length === 0 || isUploadSizeExceedsLimit,
                            }
                        ]}
                        maxWidth={'md'}
                    >
                        <Uploader
                            totalUploadSizeInMb={totalUploadFilesSize}
                            onFilesSelect={handleSelectFiles}
                            onFileDelete={handleFileDeleteFromUploadContext}
                            onUploadLimitExceed={setIsUploadSizeExceedsLimit}
                            singleFile={true}
                            value={selectedFiles}
                        />
                    </AdmicityDialog>
                </ConfirmationWrapper>
                <Divider sx={{mt: 2, mb: 2}}/>
            </>
    );
};

export default MedicalNotes;