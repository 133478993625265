import React, {useMemo} from 'react';
import {validateEmail, validateFieldLength} from '../../../utility/validationUtil';
import moment from 'moment';
import {EditableFields} from '../../../shared-components/EditableFields';
import useTextFields from '../../../utility/hooks/useTextFields';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import {Backdrop, Box, CircularProgress, Stack} from '@mui/material';
import AdmicityTableV2 from '../../../shared-components/Table/V2/AdmicityTableV2';

const StaffInfoSection = ({children, title}) => {
    return (
        <Card variant="outlined" sx={{marginBottom: 2}}>
            <CardContent>
                <Typography variant="h6" fontWeight="bold">{title}</Typography>
                {children}
            </CardContent>
        </Card>
    );
};

StaffInfoSection.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string.isRequired,
};

const EmployeeProfile = ({data, isLoading}) => {
    const basicInfoTextFields = useMemo(() =>
        [
            {
                id: 'profile_forename',
                label: 'Forename',
                name: 'forename',
                initialValue: data?.legalForename,
                required: true,
                validate: (value) => validateFieldLength(value, 60),
            },
            {
                id: 'profile_middleName',
                label: 'Middle Name',
                name: 'middleName',
                initialValue: data?.middleName,
                required: false,
                validate: (value) => validateFieldLength(value, 60)
            },
            {
                id: 'profile_surname',
                label: 'Surname',
                name: 'legalSurname',
                initialValue: data?.legalSurname,
                required: true,
                validate: (value) => validateFieldLength(value, 60)
            },
            {
                id: 'profile_preferredForename',
                label: 'Preferred Forename',
                name: 'preferredForename',
                initialValue: data?.preferredForename,
                required: false,
                validate: (value) => validateFieldLength(value, 60)
            },
            {
                id: 'profile_preferredSurname',
                label: 'Preferred Surname',
                name: 'preferredSurname',
                initialValue: data?.preferredSurname,
                required: true,
                validate: (value) => validateFieldLength(value, 60)
            },
            {
                id: 'profile_dateOfBirth',
                label: 'Date Of Birth',
                name: 'dateOfBirth',
                type: 'date',
                initialValue: data?.dateOfBirth ? moment(data?.dateOfBirth) : null,
                required: false,
                minDate: moment(new Date('1970-01-01')),
                nullableOnEmpty: true
            },
            {
                id: 'profile_email',
                label: 'Email',
                name: 'email',
                initialValue: data?.email,
                required: false,
                validate: validateEmail,
                nullableOnEmpty: true
            },
            {
                id: 'profile_phone',
                label: 'Mobile Number',
                name: 'mobileNumber',
                initialValue: data?.mobileNumber,
                required: false
            },
            {
                id: 'next_of_kin',
                label: 'Next of Kin',
                name: 'nextOfKin',
                initialValue: data?.nextOfKinSummary,
                required: false
            },
            {
                id: 'cars',
                label: 'Car registrations',
                name: 'cars',
                initialValue: data?.cars?.map(c => c.registration).join(','),
                required: false
            }
        ], [data]);

    const identificationBankingDetailsTextFields = useMemo(() =>
        [
            {
                id: 'profile_NINumber',
                label: 'NI Number',
                name: 'niNumber',
                initialValue: data?.niNumber,
                required: true,
                validate: (value) => validateFieldLength(value, 60),
            },
            {
                id: 'profile_bankName',
                label: 'Bank Name',
                name: 'bankName',
                initialValue: data?.bankName,
                required: true,
                validate: (value) => validateFieldLength(value, 60),
            },
            {
                id: 'profile_bankSortCode',
                label: 'Bank Sort Code',
                name: 'bankSortCode',
                initialValue: data?.bankSortCode,
                required: true,
                validate: (value) => validateFieldLength(value, 60),
            },
            {
                id: 'profile_bankAccount',
                label: 'Bank Account',
                name: 'bankAccount',
                initialValue: data?.bankAccount,
                required: true,
                validate: (value) => validateFieldLength(value, 60),
            }
        ], [data]);

    const addressTextFields = useMemo(() => {
        const address = data?.address;

        return [
            {
                id: 'profile_apartment',
                label: 'Apartment',
                name: 'apartment',
                initialValue: address?.apartment,
                required: true,
                validate: (value) => validateFieldLength(value, 60),
            },
            {
                id: 'profile_houseName',
                label: 'House Name',
                name: 'houseName',
                initialValue: address?.houseName,
                required: true,
                validate: (value) => validateFieldLength(value, 60),
            },
            {
                id: 'profile_houseNumber',
                label: 'House Number',
                name: 'houseNumber',
                initialValue: address?.houseNumber,
                required: true,
                validate: (value) => validateFieldLength(value, 60),
            },
            {
                id: 'profile_street',
                label: 'Street',
                name: 'street',
                initialValue: address?.street,
                required: true,
                validate: (value) => validateFieldLength(value, 60),
            },
            {
                id: 'profile_district',
                label: 'District',
                name: 'district',
                initialValue: address?.district,
                required: true,
                validate: (value) => validateFieldLength(value, 60),
            },
            {
                id: 'profile_town',
                label: 'Town',
                name: 'town',
                initialValue: address?.town,
                required: true,
                validate: (value) => validateFieldLength(value, 60),
            },
            {
                id: 'profile_county',
                label: 'County',
                name: 'county',
                initialValue: address?.county,
                required: true,
                validate: (value) => validateFieldLength(value, 60),
            },
            {
                id: 'profile_postcode',
                label: 'Postcode',
                name: 'postcode',
                initialValue: address?.postcode,
                required: true,
                validate: (value) => validateFieldLength(value, 60),
            },
            {
                id: 'profile_countryCode',
                label: 'Country',
                name: 'countryCode',
                initialValue: address?.country,
                required: true,
                validate: (value) => validateFieldLength(value, 60),
            }
        ];
    }, [data]);

    const {
        fields: basicInfoFields
    } = useTextFields(basicInfoTextFields);
    const {
        fields: identificationBankingFields
    } = useTextFields(identificationBankingDetailsTextFields);
    const {
        fields: addressFields
    } = useTextFields(addressTextFields);

    return (<Box
        flexGrow={1}
        position="relative"
    >
        <Grid container spacing={{ xs: 0, md: 2 }}>
            <Grid item xs={12} md={6}>
                <StaffInfoSection title="Basic Information">
                    <EditableFields fields={basicInfoFields} edit={false}/>
                </StaffInfoSection>
                {data?.contacts &&
                    <StaffInfoSection title="Contacts">
                        <AdmicityTableV2
                            data={data?.contacts}
                            columns={[
                                {
                                    id: 'fullName',
                                    sortable: false,
                                    minWidth: 200,
                                    accessorKey: 'fullName',
                                    header: 'Full name',
                                    Cell: ({row}) => `${row?.original.forename || ''} ${row?.original.surname || ''}`
                                },
                                {
                                    id: 'typeDescription',
                                    accessorKey: 'typeDescription',
                                    header: 'Type',
                                    sortable: false,
                                },
                                {
                                    id: 'email',
                                    accessorKey: 'email',
                                    header: 'Email',
                                    sortable: false,
                                    minWidth: 200
                                },
                                {
                                    id: 'phone',
                                    accessorKey: 'phone',
                                    header: 'Phone',
                                    sortable: false
                                },
                            ]}
                            slots={{
                                noRowsOverlay: () => null
                            }}
                            tableProps={{
                                enableSorting: false,
                                enableColumnResizing: false,
                                enableBottomToolbar: false,
                                enableTopToolbar: false,
                                enableRowActions: false,
                                muiTableContainerProps: {
                                    sx: {
                                        maxHeight: 500,
                                        minHeight: 'auto'
                                    }
                                },
                                muiTableBodyRowProps: {hover: false},
                            }}
                        />
                    </StaffInfoSection>
                }
            </Grid>
            <Grid item xs={12} md={6}>
                <StaffInfoSection title="Identification & Banking Details">
                    <EditableFields fields={identificationBankingFields} edit={false}/>
                </StaffInfoSection>
                <StaffInfoSection title="Home Address">
                    <EditableFields fields={addressFields} edit={false}/>
                </StaffInfoSection>
            </Grid>
        </Grid>
        <Backdrop
            sx={{
                position: 'absolute',
                backgroundColor: 'rgb(250, 250, 251)',
                zIndex: (theme) => theme.zIndex.drawer + 1
            }}
            open={isLoading}
        >
            <Stack flexDirection="column" alignItems="center">
                <CircularProgress size={40}/>
            </Stack>
        </Backdrop>
    </Box>);
};

EmployeeProfile.propTypes = {
    data: PropTypes.object,
    isLoading: PropTypes.bool,
};

export default EmployeeProfile;