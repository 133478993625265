import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useChangeVisibilityMutation, useGetGroupFiltersQuery } from '../../../../api/services/groupService';
import styled from '@mui/material/styles/styled';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import GroupFilterSettingsTree from './GroupFilterSettingsTree';
import useNotification from '../../../../utility/hooks/useNotification';
import AdmicityBackdrop from '../../../../shared-components/AdmicityBackdrop';

const StickyFooter = styled('div')(({ theme }) => ({
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: '1px solid',
    borderColor: theme.palette.grey[300],
    backgroundColor: 'rgb(250, 250, 251)',
    padding: theme.spacing(2),
    textAlign: 'right',
    zIndex: 1000
}));

const rootNodesWithVisibilityChangeAllowed = ['EMPLOYEES'];

const GroupFilters = () => {
    const {
        data: groupFilters,
        refetch: refetchGroupFilters,
        isFetching: isRefetchingGroupFilters,
        isLoading: isLoadingGroupFilters
    } = useGetGroupFiltersQuery({ fetchOnlyVisible: false, maxLevel: 2 });

    const [changeVisibility, {
        isSuccess: isChangeVisibilitySuccess,
        isError: isChangeVisibilityError,
        isLoading: isChangingVisibilityInProgress
    }] = useChangeVisibilityMutation();

    const [modifiedFilters, setModifiedFilters] = useState({});
    const { showErrorNotification, showSuccessNotification } = useNotification();

    useEffect(() => {
        if (isChangeVisibilityError) {
            showErrorNotification('Failed to update group filter visibility settings');
        } else if (isChangeVisibilitySuccess) {
            refetchGroupFilters();
            showSuccessNotification('Group filter visibility settings have been updated successfully');
        }
    }, [isChangeVisibilitySuccess, isChangeVisibilityError, refetchGroupFilters]);

    useEffect(() => {
        if (isRefetchingGroupFilters) {
            setModifiedFilters({});
        }
    }, [isRefetchingGroupFilters]);

    const handleTreeItemVisibilityChange = useCallback((node, makeVisible) =>
        setModifiedFilters(prev => {
            if (Object.prototype.hasOwnProperty.call(prev, node.nodeId) && node.isVisible === makeVisible) {
                const { [node.nodeId]: _, ...rest } = prev;

                return rest;
            }

            return { ...prev, [node.nodeId]: makeVisible };
        }), []);

        const filteredModules = useMemo(() => {
            return groupFilters?.modules?.filter(
                (module) => module.name === 'PRE-ADMISSION' || module.name === 'ON-ROLL' || module.name === 'EMPLOYEES'
            ) || [];
        }, [groupFilters]);

    return (
        <Box
            sx={{
                width: {
                    xs: '100%',
                    sm: '100%',
                    md: '75%',
                    lg: '50%',
                },
                position: 'relative',
            }}
        >
            {!isLoadingGroupFilters && filteredModules.length > 0
? (
                <>
                    <GroupFilterSettingsTree
                        data={filteredModules}
                        rootNodesWithVisibilityChangeAllowed={rootNodesWithVisibilityChangeAllowed}
                        onVisibilityChange={handleTreeItemVisibilityChange}
                    />
                    <StickyFooter>
                        <Button
                            variant="contained"
                            disabled={isChangingVisibilityInProgress || Object.keys(modifiedFilters).length === 0}
                            onClick={async () =>
                                await changeVisibility({
                                    groupFilters: Object.entries(modifiedFilters).map(([id, isVisible]) => ({
                                        id,
                                        isVisible
                                    }))
                                })
                            }
                        >
                            Save
                        </Button>
                    </StickyFooter>
                </>
            )
: (
                <Box paddingTop={2}>
                    <Typography variant="h5" gutterBottom>
                        No Group Filters Available
                    </Typography>
                    <Typography variant="body1" color="text.secondary" gutterBottom>
                        {'Group filters may not be available because they haven\'t been synchronized yet, or an error occurred while retrieving them. Please try again later.'}
                    </Typography>
                </Box>
            )}
            <AdmicityBackdrop
                open={isLoadingGroupFilters || isRefetchingGroupFilters || isChangingVisibilityInProgress}
            />
        </Box>
    );
};

export default GroupFilters;
