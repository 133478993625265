import {apiService} from '../apiService';
import {GROUP_MEMBERS_TYPE} from '../../constants/GroupMembersType';

export const profilesService = apiService.injectEndpoints({
    endpoints: (builder) =>
        ({
            getProfiles: builder.query({
                query: ({isParent, filterMembersType, ...body}) =>
                    ({
                        url: filterMembersType === GROUP_MEMBERS_TYPE.employee
                            ? '/employees/list'
                            : isParent
                                ? '/parent/student/list'
                                : '/student/list',
                        method: 'POST',
                        data: body,
                    }),
                transformErrorResponse: (_) => ({message: 'Failed to retrieve profiles'}),
                providesTags: (_) => ['GroupFilters']
            }),
            getContacts: builder.query({
                query: ({
                            isParent,
                            studentId
                        }) => ({url: isParent ? `/parent/student/${studentId}/contact` : `/student/${studentId}/contact`}),
                transformErrorResponse: (_) => ({message: 'Failed to retrieve contacts info'}),
                providesTags: (_) => ['Contacts']
            }),
            addContact: builder.mutation({
                query: ({studentId, body}) => ({
                    url: `/student/${studentId}/contact`,
                    method: 'POST',
                    data: body,
                }),
                invalidatesTags: ['Contacts']
            }),
            updateContact: builder.mutation({
                query: ({studentId, isParent, body}) => ({
                    url: isParent ? `/parent/student/${studentId}/contact` : `/student/${studentId}/contact`,
                    method: 'PUT',
                    data: body,
                }),
                invalidatesTags: ['Contacts']
            }),
            deleteContact: builder.mutation({
                query: ({studentId, contactId}) => ({
                    url: `/student/${studentId}/contact/${contactId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['Contacts']
            }),
            manageConfirm: builder.mutation({
                query: ({studentId, type}) => ({
                    url: `/student/${studentId}/confirm`,
                    method: 'PATCH',
                    data: {studentId, type},
                }),
                invalidatesTags: ['Contacts']
            }),
            getInviteData: builder.query({
                query: ({inviteId}) => ({
                    url: `/account/invite/${inviteId}`
                })
            })
        }),
});

export const {
    useGetProfilesQuery,
    useAddContactMutation,
    useUpdateContactMutation,
    useGetInviteDataQuery,
    useGetContactsQuery,
    useManageConfirmMutation,
    useDeleteContactMutation
} = profilesService;
