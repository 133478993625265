import React, {useEffect, useState} from 'react';
import {Box, Button, Container, Paper} from '@mui/material';
import {validateEmail} from '../../utility/validationUtil';
import AdmicityForm from '../../shared-components/AdmicityForm';
import api from '../../axios';
import {useNavigate} from 'react-router-dom';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import Typography from '@mui/material/Typography';
import {getSubdomain} from '../../utility/routeUtil';
import {useGetSchoolInvitesInfoQuery} from '../../api/services/schoolManagementService';
import {ROLES, ROLES_LABELS} from '../../constants/roles';

const InviteRecoveryPage = () => {
    const navigate = useNavigate();
    const [isEmailSentSuccess, setIsEmailSentSuccess] = useState(false);

    const {
        data: invitesInfo = {
            disableStudentInvitations: true,
            disableParentInvitations: true,
            disableEmployeeInvitations: true
        },
    } = useGetSchoolInvitesInfoQuery(getSubdomain(),
        {skip: !getSubdomain()}
    );

    useEffect(() => {
        return () => {
            setIsEmailSentSuccess(false);
        };
    }, []);

    const textFields =
        !invitesInfo.disableParentInvitations &&
        !invitesInfo.disableStudentInvitations &&
        !invitesInfo.disableEmployeeInvitations
            ? [
                {
                    name: 'role',
                    inputType: 'radio',
                    label: 'What role do you have on Admicity?',
                    options: [
                        {
                            title: 'I am a parent',
                            value: ROLES_LABELS[ROLES.PARENT]
                        },
                        {
                            title: 'I am a student',
                            value: ROLES_LABELS[ROLES.STUDENT]
                        },
                        {
                            title: 'I am a employee',
                            value: ROLES_LABELS[ROLES.EMPLOYEE]
                        }
                    ],
                },
                {
                    label: 'Email',
                    name: 'email',
                    validate: (value) => validateEmail(value)
                }
            ]
            : [
                {
                    label: 'Email',
                    name: 'email',
                    validate: (value) => validateEmail(value)
                }
            ];

    const handleSubmit = async (payload) => {
        if (!invitesInfo.disableParentInvitations ||
            !invitesInfo.disableEmployeeInvitations ||
            !invitesInfo.disableEmployeeInvitations) {

            await api.post('/account/regenerate-invite', {...payload, subDomain: getSubdomain()});
        }
        setIsEmailSentSuccess(true);
    };

    const SentEmailSuccessView = () =>
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
        >
            <TaskAltRoundedIcon color="success" sx={{fontSize: 64}}/>
            <Typography variant="h5" gutterBottom>Success</Typography>
            <Typography variant="h6" textAlign="center" gutterBottom>
                We have just sent you a new invite email!
            </Typography>
            <Typography textAlign="center" gutterBottom>
                Please follow the instructions in the email to create your account.
            </Typography>
            <Button sx={{marginTop: 1}} variant="contained" onClick={() => navigate('/login')}>
                Back to Login Page
            </Button>
        </Box>;

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100%"
            flexGrow={1}
        >
            <Container maxWidth="xs" key={isEmailSentSuccess}>
                {
                    isEmailSentSuccess
                        ? <SentEmailSuccessView/>
                        : <Paper
                            variant="outlined"
                            sx={{p: 3, display: 'flex', flexDirection: 'column'}}
                        >
                            <AdmicityForm
                                title={!invitesInfo.disableParentInvitations &&
                                !invitesInfo.disableStudentInvitations &&
                                !invitesInfo.disableEmployeeInvitations
                                    ? 'Please provide your account email and choose your account role'
                                    : 'Please provide your account email'}
                                textFields={textFields}
                                handleSubmit={handleSubmit}
                                buttonText="Resend invite"
                            />
                            <Button
                                variant={'text'}
                                sx={{textTransform: 'none'}}
                                onClick={() => navigate('/login')}>
                                Back to Sign In
                            </Button>
                        </Paper>
                }
            </Container>
        </Box>
    );
};

export default InviteRecoveryPage;