import {MenuItem, styled, Table, TableBody, TableCell, TableRow, TextField} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

const TableCellStyled = styled(TableCell)({
    paddingLeft: 0,
    paddingRight: 0
});

export const EditableFields = ({fields, edit}) => {
    const renderByType = ({textFieldProps, otherProps}) => ({
        select: () => (
            <TextField
                sx={{
                    '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)',
                    }
                }}
                key={`select_${textFieldProps.name}`}
                variant="outlined"
                margin="dense"
                fullWidth
                {...textFieldProps}
            >
                {otherProps.options
                    ? otherProps.options
                        .map(x => (
                            <MenuItem
                                key={x.value}
                                value={x.value}
                            >
                                {x.title}
                            </MenuItem>
                        ))
                    : <MenuItem/>
                }
            </TextField>
        ),
        date: () => (
            <DatePicker
                sx={{
                    '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)',
                    }
                }}
                key={`select_${textFieldProps.name}`}
                format={'D-MMM-YYYY'}
                maxDate={moment()}
                slotProps={{
                    textField: {
                        fullWidth: true,
                        name: textFieldProps.name,
                        margin: 'dense'
                    },
                    field: {
                        readOnly: true
                    }
                }}
                {...{
                    ...textFieldProps,
                    onChange: value => textFieldProps.onChange({target: {value, name: textFieldProps.name}}, {})
                }}
            />
        ),
        text: () => (
            <TextField
                sx={{
                    '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: 'rgba(0, 0, 0, 0.87)',
                    }
                }}
                key={`textField_${textFieldProps.name}`}
                variant="outlined"
                margin="dense"
                fullWidth
                InputLabelProps={{
                    style: {overflow: 'visible'},
                }}
                {...textFieldProps}
            />
        )
    })[textFieldProps.type ?? 'text']();

    return <>
        {
            !edit
                ? (
                    <Table>
                        <TableBody>
                            {
                                Object
                                    .values(fields)
                                    .map(({textFieldProps, otherProps}) => (
                                        <TableRow
                                            key={textFieldProps.label}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCellStyled sx={{width: '30%'}}>{textFieldProps.label}</TableCellStyled>
                                            <TableCell sx={{width: 5}}>:</TableCell>
                                            <TableCellStyled
                                                align="left"
                                                sx={{
                                                    wordBreak: 'break-all'
                                                }}>
                                                <div style={{ whiteSpace: 'pre-wrap' }}>
                                                    {
                                                        textFieldProps.type === 'date'
                                                            ? textFieldProps.value != null && textFieldProps.value !== ''
                                                                ? textFieldProps.value.format('DD-MMM-YYYY')
                                                                : ''
                                                            : textFieldProps.type === 'select'
                                                                ? otherProps.options?.find(x => x.value === textFieldProps.value)?.title
                                                                : textFieldProps.value
                                                    }
                                                </div>
                                            </TableCellStyled>
                                        </TableRow>
                                    ))}
                        </TableBody>
                    </Table>
                )
                : Object
                    .values(fields)
                    .map(renderByType)
        }
    </>;
};

EditableFields.propTypes = {
    fields: PropTypes.object.isRequired,
    edit: PropTypes.bool
};