import React, {useEffect, useState} from 'react';
import {Box, styled, Tab, Tabs, useMediaQuery, useTheme} from '@mui/material';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {NavLink, Outlet, useLocation} from 'react-router-dom';
import {retrieveProfilesByParent} from './Profiles/ProfilesSlice';
import {ROLES} from '../../constants/roles';
import useUser from '../../utility/hooks/useUser';

function CustomTabPanel(props) {
    const {children, value, index, ...other} = props;

    return (<div
        role="tabpanel"
        style={{flexGrow: 1, display: 'flex', flexDirection: 'column',}}
        hidden={value !== index}
        id={`simple-tabPanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
    >
        {value === index && <>{children}</>}
    </div>);
}

CustomTabPanel.propTypes = {
    children: PropTypes.node, index: PropTypes.number.isRequired, value: PropTypes.number.isRequired,
};

const DashboardContainer = styled(Box)({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
});

const tabsByRole = {
    [ROLES.SCHOOL_ADMIN]: [
        { label: 'School settings', route: 'school' },
        { label: 'Profiles', route: 'profiles' },
        { label: 'Forms', route: 'forms' },
        { label: 'Documents', route: 'documents' }
    ],
    [ROLES.MEMBER_OF_STAFF]: [
        { label: 'Profiles', route: 'profiles' },
        { label: 'Forms', route: 'forms' },
        { label: 'Documents', route: 'documents' }
    ],
    [ROLES.SENIOR_MEMBER_OF_STAFF]: [
        { label: 'Profiles', route: 'profiles' },
        { label: 'Forms', route: 'forms' },
        { label: 'Documents', route: 'documents' }
    ],
    [ROLES.EMPLOYEE]: [
        { label: 'My Profile', route: 'my-profile' },
        { label: 'Form Responses', route: 'request-and-responses' }
    ],
    [ROLES.PARENT]: [
        { label: 'Profiles', route: 'profiles' },
        { label: 'Form Responses', route: 'request-and-responses' }
    ],
    [ROLES.STUDENT]: [
        { label: 'Form Responses', route: 'request-and-responses' }
    ],
    DEFAULT: []
};

const getTabsByUserRoles = roles => {
    if (roles.includes(ROLES.SCHOOL_ADMIN)) {
        return tabsByRole[ROLES.SCHOOL_ADMIN];
    }

    if (roles.includes(ROLES.MEMBER_OF_STAFF)) {
        return tabsByRole[ROLES.MEMBER_OF_STAFF];
    }

    if (roles.includes(ROLES.SENIOR_MEMBER_OF_STAFF)) {
        return tabsByRole[ROLES.SENIOR_MEMBER_OF_STAFF];
    }

    const tabs = new Map();

    roles.forEach(role => {
        const roleTabs = tabsByRole[role] || tabsByRole.DEFAULT;

        roleTabs.forEach(tab => {
            if (!tabs.has(tab.route)) {
                tabs.set(tab.route, tab);
            }
        });
    });

    return Array.from(tabs.values());
};

const SchoolDashboardPage = () => {
    const {user} = useUser();
    const tabs = getTabsByUserRoles(user.roles);
    const location = useLocation().pathname;
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const dispatch = useDispatch();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const activeTabIndex = tabs.findIndex(tab => location.includes(tab.route));

        setCurrentTabIndex(activeTabIndex !== -1 ? activeTabIndex : 0);

        if (user.role === ROLES.PARENT) {
            dispatch(retrieveProfilesByParent({pageNumber: 0, pageSize: 20}));
        }
    }, [user]);

    const handleChange = (event, newValue) => {
        setCurrentTabIndex(newValue);
    };

    return (<DashboardContainer>
        <Tabs
            value={currentTabIndex}
            onChange={handleChange}
            variant={isSmallScreen
                ? 'scrollable'
                : tabs.length <= 1
                    ? 'standard'
                    : 'fullWidth'
        }
            textColor="inherit">
            {
                tabs.map((tab, index) => (
                    <Tab
                        key={index}
                        tabIndex={index}
                        label={tab.label}
                        component={NavLink}
                        to={tab.route}
                    />
                ))
            }
        </Tabs>
        <Outlet/>
    </DashboardContainer>);
};

export default SchoolDashboardPage;
