const ROLES = {
    SUPER_ADMIN: 'SuperAdmin',
    SCHOOL_ADMIN: 'SchoolAdmin',
    MEMBER_OF_STAFF: 'MemberOfStaff',
    SENIOR_MEMBER_OF_STAFF: 'SeniorMemberOfStaff',
    EMPLOYEE: 'Employee',
    PARENT: 'Parent',
    STUDENT: 'Student',
    NONE: 'None'
};

const ROLES_LABELS = {
    [ROLES.SUPER_ADMIN]: 'Super Admin',
    [ROLES.SCHOOL_ADMIN]: 'School Admin',
    [ROLES.MEMBER_OF_STAFF]: 'Member Of Staff',
    [ROLES.SENIOR_MEMBER_OF_STAFF]: 'Senior Member Of Staff',
    [ROLES.EMPLOYEE]: 'Employee',
    [ROLES.PARENT]: 'Parent',
    [ROLES.STUDENT]: 'Student',
    [ROLES.NONE]: 'None'
};

const ROLE_IDS = {
    [ROLES.SUPER_ADMIN]: 1,
    [ROLES.SCHOOL_ADMIN]: 2,
    [ROLES.MEMBER_OF_STAFF]: 3,
    [ROLES.PARENT]: 4,
    [ROLES.STUDENT]: 5,
    [ROLES.EMPLOYEE]: 6,
    [ROLES.SENIOR_MEMBER_OF_STAFF]: 7,
};

const ROLE_BASE_ROUTE = {
    [ROLES.SUPER_ADMIN]: {path: '/management', priority: 1},
    [ROLES.SCHOOL_ADMIN]: {path: '/school', priority: 2},
    [ROLES.MEMBER_OF_STAFF]: {path: '/profiles', priority: 3},
    [ROLES.SENIOR_MEMBER_OF_STAFF]: {path: '/profiles', priority: 4},
    [ROLES.EMPLOYEE]: {path: '/my-profile', priority: 5},
    [ROLES.PARENT]: {path: '/profiles', priority: 6},
    [ROLES.STUDENT]: {path: '/request-and-responses', priority: 7},
    [ROLES.NONE]: {path: '/login', priority: 8}
}

const getRouteForUser = user => {
    const eligibleRoutes = user.roles
        ?.map(role => ROLE_BASE_ROUTE[role])
        .filter(role => role != null)
        .sort((a, b) => a.priority - b.priority);

    return eligibleRoutes?.length > 0
        ? eligibleRoutes[0].path
        : ROLE_BASE_ROUTE[ROLES.NONE].path;
};

export {
    ROLES,
    ROLE_BASE_ROUTE,
    ROLE_IDS,
    ROLES_LABELS,
    getRouteForUser
}