import {memo} from 'react';
import AdmicityGroupFilterTree from '../../../../shared-components/AdmicityGroupFilterTree';
import {Checkbox} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import PropTypes from 'prop-types';

const GroupFilterSettingsTree = memo((
    {
        data,
        onVisibilityChange,
        rootNodesWithVisibilityChangeAllowed = []
    }) => {
    const rootFilterIds = data
        ? data
            .filter(module => !rootNodesWithVisibilityChangeAllowed.includes(module.name))
            .map(module => module.id)
        : [];

    return (
        <AdmicityGroupFilterTree
            sx={{
                '& .MuiTreeItem-content.Mui-selected': {
                    backgroundColor: 'transparent'
                },
                '& .MuiTreeItem-content.Mui-focused': {
                    backgroundColor: 'transparent'
                },
                '& .MuiTreeItem-content': {
                    backgroundColor: 'transparent',
                    ':hover': {
                        backgroundColor: (theme) => theme.palette.action.hover
                    }
                },

            }}
            expandMinLevel={1}
            data={data}
            renderItemActions={node => !rootFilterIds.some(module => module === node.nodeId)
                ? <Checkbox
                    onChange={e => onVisibilityChange(node, e.target.checked)}
                    defaultChecked={node.isVisible}
                    checkedIcon={<VisibilityIcon/>}
                    icon={<VisibilityOffRoundedIcon/>}
                />
                : null
            }
            onChange={() => {
            }}
        />
    );
});

GroupFilterSettingsTree.displayName = 'GroupFilterSettingsTree';

GroupFilterSettingsTree.propTypes = {
    data: PropTypes.array,
    onVisibilityChange: PropTypes.func,
    rootNodesWithVisibilityChangeAllowed: PropTypes.array
};

export default GroupFilterSettingsTree;