import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
    Alert,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Paper,
    Stack,
    Step,
    StepLabel,
    Stepper,
    styled,
    Tab,
    Tabs
} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useNavigate, useParams} from 'react-router-dom';
import FormStructure from './FormStructure';
import PrivateFormSettings from './PrivateFormSettings/PrivateFormSettings';
import PropTypes from 'prop-types';
import {FORM_ACCESS_LEVELS} from '../../../constants/formAccessLevels';
import PublicFormSettings from './PublicFormSettings';
import {
    useCreateFormMutation,
    useGetFormTemplatesQuery,
    useLazyGetFormQuery,
    useUpdateFormMutation,
    useUpdateFormStatusMutation
} from '../../../api/services/formsService';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import useNotification from '../../../utility/hooks/useNotification';
import {FORM_STATUSES} from '../../../constants/formStatuses';
import {useGetFormCategoriesQuery} from '../../../api/services/formCategoriesService';
import useUser from '../../../utility/hooks/useUser';
import FORM_AUDIENCE_TYPE from '../../../constants/FormAudienceType';
import FormTemplateType from '../../../constants/FormTemplateType';
import {FormBuilderComponentTypes} from './FormBuilder/ComponentList';
import {
    useCreateExternalUserFormAssignmentsMutation,
    useCreateFormAssignmentMutation,
    useCreateFormQuestionAssignmentMutation,
    useCreateFormStudentAssignmentMutation,
    useDeleteExternalUserFormAssignmentsMutation,
    useDeleteFormAssignmentsMutation,
    useDeleteFormQuestionAssignmentMutation,
    useDeleteFormStudentsAssignmentsMutation
} from '../../../api/services/formAssignmentsService';
import {useConfirm} from 'material-ui-confirm';
import Tooltip from '@mui/material/Tooltip';

const StickyFooter = styled('div')(({theme}) => ({
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.background.paper,
    borderTop: '1px solid #e0e0e0',
    padding: theme.spacing(2),
    textAlign: 'right',
    zIndex: 1000
}));

const validateSchema = (schema) => {
    const offerChoiceComponent = schema.find(x => x.type === FormBuilderComponentTypes.OfferStatusChoice);

    if (offerChoiceComponent) {
        return offerChoiceComponent.options?.length >= 1 &&
            offerChoiceComponent.options?.every(o => o.value !== '' && o.value != null);
    }

    return true;
};

const FormEditor = ({mode, title}) => {
    const isEditMode = mode === 'edit';
    const {id} = useParams();
    const confirm = useConfirm();
    const {showErrorNotification, showSuccessNotification, showDetailedErrorNotification} = useNotification();
    const [formId, setFormId] = useState(null);
    const [schema, setSchema] = useState(undefined);
    const [componentToEditIndex, setComponentToEditIndex] = useState(undefined);
    const [formStatus, setFormStatus] = useState(undefined);
    const [templateId, setTemplateId] = useState(undefined);
    const [accessLevel, setAccessLevel] = useState();
    const [expirationDate, setExpirationDate] = useState();
    const [remindInterval, setRemindInterval] = useState(0);
    const [strictRecipientsFiltering, setStrictRecipientsFiltering] = useState(false);
    const [allParentsCanSubmit, setAllParentsCanSubmit] = useState(false);
    const [allowMultipleSubmissions, setAllowMultipleSubmissions] = useState(false);
    const [name, setName] = useState('');
    const [categories, setCategories] = useState([]);
    const [audience, setAudience] = useState(FORM_AUDIENCE_TYPE.none);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [activeStep, setActiveStep] = useState(0);
    const [privateFormSettings, setPrivateFormSettings] = useState({
        groupFilters: [],
        studentFilters: [],
        applicationFilters: [],
        externalUserEmailAssignments: [],
        triggerFormSettings: {},
        validation: {
            isValid: true,
            error: ''
        }
    });
    const navigate = useNavigate();
    const {data: templates} = useGetFormTemplatesQuery(undefined);
    const {data: formCategories} = useGetFormCategoriesQuery();
    const [saveForm, {isLoading: isFormSaving}] = isEditMode || formId != null
        ? useUpdateFormMutation()
        : useCreateFormMutation();
    const [updateFormStatus, {isLoading: isPublishingForm}] = useUpdateFormStatusMutation();
    const [getForm] = useLazyGetFormQuery();
    const [createFormAssignments] = useCreateFormAssignmentMutation();
    const [createFormStudentAssignments] = useCreateFormStudentAssignmentMutation();
    const [createExternalUserFormAssignments] = useCreateExternalUserFormAssignmentsMutation();
    const [deleteFormAssignments] = useDeleteFormAssignmentsMutation();
    const [deleteFormStudentsAssignments] = useDeleteFormStudentsAssignmentsMutation();
    const [deleteExternalUserFormAssignments] = useDeleteExternalUserFormAssignmentsMutation();
    const [createFormQuestionAssignment] = useCreateFormQuestionAssignmentMutation();
    const [deleteFormQuestionAssignments] = useDeleteFormQuestionAssignmentMutation();

    const formRef = useRef(null);
    const {user} = useUser();
    const stateRef = useRef();

    const schoolLogoSchemaComponent = useMemo(() => ({
        type: 'schoolLogo',
        url: user.schoolLogoUrl
    }), []);

    useEffect(() => {
        if (isEditMode) {
            getForm(id)
                .then(({data: form}) => {
                    setFormId(form.id);
                    setSchema({
                        value: form.schema,
                        isValid: validateSchema(form.schema)
                    });
                    setName(form.name);
                    setFormStatus(form.status);
                    setStrictRecipientsFiltering(form.strictRecipientsFiltering);
                    setAllParentsCanSubmit(form.allParentsCanSubmit);
                    setAllowMultipleSubmissions(form.allowMultipleSubmissions);
                    if (form.accessLevel) {
                        setAccessLevel(form.accessLevel);
                    }
                    setExpirationDate(form.expirationDate);
                    setRemindInterval(form.remindInterval);
                    setCategories(form.categories?.map(x => x.id) ?? []);
                    setAudience(form.audience);
                    setTemplateId(form.template?.id);
                })
                .catch(_ => {
                    showErrorNotification('Failed to retrieve form data');
                });
        }
    }, [mode]);

    const handleTemplateChange = (event) => {
        const templateId = event.target.value;
        const template = templates?.find(template => template.id === templateId);
        const isTransitionForm = template.type === FormTemplateType.transition;
        const isOfferForm = template.type === FormTemplateType.offer;

        setTemplateId(templateId);
        setAccessLevel(isTransitionForm || isOfferForm
            ? FORM_ACCESS_LEVELS.publicWithStudentLink
            : FORM_ACCESS_LEVELS.private);

        if (template?.schema) {
            const value = [...template.schema, schoolLogoSchemaComponent];
            setSchema({
                value,
                isValid: validateSchema(value)
            });
        }
    };

    const handleExpirationDateChange = (value) => setExpirationDate(value);

    const handleRemindIntervalChange = (value) => {
        const intValue = parseInt(value, 10);
        if (!isNaN(intValue) && intValue >= 0 && intValue <= 1000) {
            setRemindInterval(intValue);
        } else if (value === '') {
            setRemindInterval('');
        }
    };

    const handleNameChange = (event) => setName(event.target.value);

    stateRef.current = schema?.value;

    const buildUpdateFormPayload = (properties = {}) => ({
        id: formId,
        schema: schema.value,
        accessLevel,
        name,
        strictRecipientsFiltering,
        allParentsCanSubmit,
        allowMultipleSubmissions,
        expirationDate,
        remindInterval,
        categoryIds: categories,
        audience,
        ...properties
    })

    const handleSaveForm = async (callback) => {
        await setComponentToEditIndex(-1);
        confirm({
            title: 'Confirmation',
            description: 'Are you sure you want to save the changes and proceed?',
            confirmationText: 'Save'
        })
            .then(async () => {
                try {
                    if (isEditMode || formId != null) {
                        await saveForm(buildUpdateFormPayload({schema: stateRef.current}));
                    } else {
                        const response = await saveForm({
                            templateId,
                            schema: stateRef.current,
                            name,
                            strictRecipientsFiltering,
                            allParentsCanSubmit,
                            allowMultipleSubmissions,
                            expirationDate,
                            remindInterval,
                            categoryIds: categories,
                            accessLevel,
                            audience
                        });
                        const savedFormId = response.data?.formId;

                        if (savedFormId) {
                            setFormId(savedFormId);
                            setActiveStep(activeStep + 1);
                        }
                    }

                    await handleSaveFormSettings();

                    showSuccessNotification('Form saved successfully');

                    if (callback && {}.toString.call(callback) === '[object Function]') {
                        callback();
                    }
                } catch (error) {
                    if (error.type === 'SaveFormSettingsError') {
                        showDetailedErrorNotification('Failed to save form settings', error.details);
                    } else {
                        showErrorNotification('Failed to save form');
                    }
                }
            })
            .catch(() => {
            });

    };

    const handleSaveFormSettings = async () => {
        const classifyFilters = (filters) => {
            return filters.reduce(
                (acc, filter) => {
                    filter.isDeleted && !filter.isNew
                        ? acc.deleted.push(filter)
                        : filter.isNew && acc.created.push(filter);
                    return acc;
                },
                {created: [], deleted: []}
            );
        };

        const studentFilters = classifyFilters([
            ...privateFormSettings.studentFilters,
            ...privateFormSettings.applicationFilters
        ]);
        const groupFilters = classifyFilters(privateFormSettings.groupFilters);
        const externalUserFormAssignments = classifyFilters(privateFormSettings.externalUserEmailAssignments);
        const tasks = [];

        if (groupFilters.deleted.length) {
            tasks.push({
                action: () => deleteFormAssignments({
                    formId,
                    formAssignmentIds: groupFilters.deleted.map(x => x.groupId)
                }),
                label: 'deleting group filters'
            });
        }

        if (groupFilters.created.length) {
            tasks.push({
                action: () => createFormAssignments({
                    formId,
                    formAssignments: groupFilters.created.map(x => ({
                        filterId: x.id,
                        excluded: x.excluded
                    }))
                }),
                label: 'creating group filters'
            });
        }

        if (studentFilters.deleted.length) {
            tasks.push({
                action: () => deleteFormStudentsAssignments({
                    formId,
                    ids: studentFilters.deleted.map(x => x.assignmentId)
                }),
                label: 'deleting student filters'
            });
        }

        if (studentFilters.created.length) {
            tasks.push({
                action: () => createFormStudentAssignments({
                    formId,
                    assignments: studentFilters.created.map(x => ({
                        studentId: x.id,
                        name: x.label
                    }))
                }),
                label: 'creating student filters'
            });
        }

        if (externalUserFormAssignments.deleted.length) {
            tasks.push({
                action: () => deleteExternalUserFormAssignments({
                    formId,
                    assignmentIds: externalUserFormAssignments.deleted.map(x => x.id)
                }),
                label: 'deleting external user form assignments'
            });
        }

        if (externalUserFormAssignments.created.length) {
            tasks.push({
                action: () => createExternalUserFormAssignments({
                    formId,
                    emails: externalUserFormAssignments.created.map(x => x.email)
                }),
                label: 'creating external user form assignments'
            });
        }

        if (privateFormSettings.triggerFormSettings) {
            const {selectedForm, original, relatedComponent, targetState} = privateFormSettings.triggerFormSettings;

            if (!selectedForm && original) {
                tasks.push({
                    action: () => deleteFormQuestionAssignments(original.id),
                    label: 'deleting form trigger'
                });
            }

            if (selectedForm && (
                original == null ||
                selectedForm.id !== original.selectedForm.id ||
                relatedComponent.id !== original.relatedComponent.id ||
                targetState !== original.targetState
            )) {
                tasks.push({
                    action: () => createFormQuestionAssignment({
                        formId,
                        targetState: typeof targetState === 'string' ? targetState : `${targetState}`,
                        relatedComponentId: relatedComponent.id,
                        relatedFormId: selectedForm.id,
                        displayName: `${selectedForm.name} - ${relatedComponent.description}`,
                        emails: externalUserFormAssignments.created.map(x => x.email)
                    }),
                    label: 'creating form trigger'
                });
            }
        }

        if (!tasks.length) return;

        const errors = [];

        for (const task of tasks) {
            const result = await task.action();

            if (result.error) {
                const reason = result.error.data?.validationErrors?.map(x => x.errorMessage).join('; ');

                errors.push({
                    errorMessage: `Error while ${task.label}. ${reason}`
                });
            }
        }

        if (errors.length) {
            const error = new Error('Saving failed');

            error.type = 'SaveFormSettingsError';
            error.details = errors;

            throw error;
        }
    };

    const handleTabChange = (event, newValue) => {
        setCurrentTabIndex(newValue);
    };

    const navigateBack = () => {
        navigate('/forms');
    };

    const isFormIncomplete = () => !schema?.value ||
        !schema?.isValid ||
        !accessLevel ||
        !name ||
        remindInterval === '' ||
        !privateFormSettings.validation.isValid;

    const getFormValidationMessage = () => {
        if (!name) {
            return 'Please enter form name';
        }

        if (schema?.value && !schema?.isValid) {
            return 'Please configure the statuses for Application Status Choice component.';
        }

        if (!privateFormSettings.validation.isValid) {
            return privateFormSettings.validation.error;
        }

        return null;
    };
    const makeFormLive = async () => {
        const result = await updateFormStatus({id: formId});

        if (result.error) {
            showErrorNotification('Failed to publish form');
        } else {
            showSuccessNotification('Form published successfully');
            navigateBack();
        }
    };

    const handleSetStrictRecipientsFiltering = async (value) => {
        if (isEditMode) {
            setStrictRecipientsFiltering(value);
            return;
        }

        try {

            await saveForm(buildUpdateFormPayload({strictRecipientsFiltering: value}));
            setStrictRecipientsFiltering(value);
        } catch (e) {
            showErrorNotification('Failed to update "Must appear in all these groups" option');
        }
    };

    const handleAllParentsCanSubmitChange = async (value) => {
        if (isEditMode) {
            setAllParentsCanSubmit(value);
            return;
        }

        try {
            await saveForm(buildUpdateFormPayload({allParentsCanSubmit: value}));
            setAllParentsCanSubmit(value);
        } catch (e) {
            showErrorNotification('Failed to update "Require both parents to complete the form" option');
        }
    };

    const handleAllowMultipleSubmissionsChange = async (value) => {
        if (isEditMode) {
            setAllowMultipleSubmissions(value);
            return;
        }

        try {
            await saveForm(buildUpdateFormPayload({allowMultipleSubmissions: value}));
            setAllowMultipleSubmissions(value);
        } catch (e) {
            showErrorNotification('Failed to update "Require both parents to complete the form" option');
        }
    };

    const handleSetAudience = async (value) => {
        const newAccessLevel = value === FORM_AUDIENCE_TYPE.externalUsers || value === FORM_AUDIENCE_TYPE.externalUsersBasedOnConditionalTrigger
            ? FORM_ACCESS_LEVELS.publicWithStudentLink
            : accessLevel;

        if (isEditMode) {
            setAudience(value);
            setAccessLevel(newAccessLevel);
            return;
        }

        try {
            await saveForm(buildUpdateFormPayload({
                accessLevel: newAccessLevel,
                audience: value,
                allParentsCanSubmit: false
            }));
            setAudience(value);
        } catch (e) {
            showErrorNotification('Failed to update form audience');
        }
    };

    const handleCategoriesChange = value => setCategories(value.target.value);

    const audienceOptions = useMemo(() => {
        const template = templates?.find(template => template.id === templateId);
        const isTransitionForm = template?.type === FormTemplateType.transition;
        const isOfferForm = template?.type === FormTemplateType.offer;

        if (isTransitionForm) {
            return [
                {
                    value: FORM_AUDIENCE_TYPE.previousSchool,
                    label: 'Previous School'
                }
            ];
        }

        if (isOfferForm) {
            return [
                {
                    value: FORM_AUDIENCE_TYPE.parentsForChildCompletion,
                    label: 'Parents'
                }
            ];
        }

        return [
            {
                value: FORM_AUDIENCE_TYPE.parentsForChildCompletion,
                label: 'Parents'
            },
            {
                value: FORM_AUDIENCE_TYPE.externalUsers,
                label: 'External Users'
            },
            {
                value: FORM_AUDIENCE_TYPE.students,
                label: 'Students'
            },
            {
                value: FORM_AUDIENCE_TYPE.employees,
                label: 'Employees'
            }
        ];
    }, [templates, templateId]);

    const tabs = [
        {
            label: 'Form Structure',
            component: <FormStructure
                formRef={formRef}
                accessLevel={accessLevel}
                name={name}
                schema={schema?.value}
                templateId={templateId}
                templates={templates}
                formCategories={formCategories}
                onNameChange={handleNameChange}
                handleTemplateChange={handleTemplateChange}
                setSchema={(value) =>
                    setSchema({
                        value,
                        isValid: validateSchema(value)
                    })}
                editMode
                expirationDate={expirationDate}
                remindInterval={remindInterval}
                onRemindIntervalChange={handleRemindIntervalChange}
                onExpirationDateChange={handleExpirationDateChange}
                isFormArchived={formStatus === FORM_STATUSES.archived}
                formId={formId}
                categories={categories}
                onCategoriesChange={handleCategoriesChange}
                componentToEditIndex={componentToEditIndex}
                setComponentToEditIndex={setComponentToEditIndex}
            />
        },
        formStatus !== FORM_STATUSES.archived && {
            label: 'Form Settings',
            component: accessLevel === FORM_ACCESS_LEVELS.private ||
            accessLevel === FORM_ACCESS_LEVELS.publicWithStudentLink
                ? <PrivateFormSettings
                    formId={formId}
                    isFormLive={formStatus === 'Active'}
                    strictRecipientsFiltering={strictRecipientsFiltering}
                    audience={audience}
                    audienceOptions={audienceOptions}
                    allParentsCanSubmit={allParentsCanSubmit}
                    allowMultipleSubmissions={allowMultipleSubmissions}
                    onSetStrictRecipientsFiltering={handleSetStrictRecipientsFiltering}
                    onAudienceChange={handleSetAudience}
                    settings={privateFormSettings}
                    onSettingsChange={setPrivateFormSettings}
                    onAllParentsCanSubmitChange={handleAllParentsCanSubmitChange}
                    onAllowMultipleSubmissionsChange={handleAllowMultipleSubmissionsChange}
                />
                : <PublicFormSettings formId={formId}/>
        }
    ];

    const areRecipientsConfigured = () => {
        if (allowMultipleSubmissions) {
            return true;
        }

        if (audience === FORM_AUDIENCE_TYPE.externalUsers) {
            return privateFormSettings.externalUserEmailAssignments.length > 0;
        }

        return (privateFormSettings.groupFilters.length ||
                privateFormSettings.studentFilters.length ||
                privateFormSettings.applicationFilters.length) > 0 ||
            privateFormSettings.triggerFormSettings.selectedForm != null;
    };

    const steps = [
        {
            label: 'Form Setup',
            component: <FormStructure
                formRef={formRef}
                accessLevel={accessLevel}
                name={name}
                schema={schema?.value}
                templateId={templateId}
                setSchema={(value) =>
                    setSchema({
                        value,
                        isValid: validateSchema(value)
                    })}
                templates={templates}
                formCategories={formCategories}
                onNameChange={handleNameChange}
                handleTemplateChange={handleTemplateChange}
                remindInterval={remindInterval}
                onRemindIntervalChange={handleRemindIntervalChange}
                expirationDate={expirationDate}
                onExpirationDateChange={handleExpirationDateChange}
                isFormArchived={formStatus === FORM_STATUSES.archived}
                categories={categories}
                onCategoriesChange={handleCategoriesChange}
                componentToEditIndex={componentToEditIndex}
                setComponentToEditIndex={setComponentToEditIndex}
            />,
            actions: [
                {
                    label: 'Save form',
                    disabled: isFormIncomplete(),
                    onClick: handleSaveForm,
                    tooltip: getFormValidationMessage()
                }
            ]
        },
        {
            label: 'Define Recipients / Configure Embedding',
            component: accessLevel === FORM_ACCESS_LEVELS.private ||
            accessLevel === FORM_ACCESS_LEVELS.publicWithStudentLink
                ? <PrivateFormSettings
                    formId={formId}
                    strictRecipientsFiltering={strictRecipientsFiltering}
                    allParentsCanSubmit={allParentsCanSubmit}
                    allowMultipleSubmissions={allowMultipleSubmissions}
                    onSetStrictRecipientsFiltering={handleSetStrictRecipientsFiltering}
                    audience={audience}
                    audienceOptions={audienceOptions}
                    onAudienceChange={handleSetAudience}
                    settings={privateFormSettings}
                    onSettingsChange={setPrivateFormSettings}
                    onAllParentsCanSubmitChange={handleAllParentsCanSubmitChange}
                    onAllowMultipleSubmissionsChange={handleAllowMultipleSubmissionsChange}
                />
                : <PublicFormSettings formId={formId}/>,
            actions: [
                {
                    label: 'Next',
                    disabled: !privateFormSettings.validation.isValid,
                    tooltip: getFormValidationMessage(),
                    onClick: async () => {
                        try {
                            await handleSaveFormSettings();
                        } catch (error) {
                            showDetailedErrorNotification('Failed to save form settings', error.details);
                        }

                        setActiveStep(activeStep + 1);
                    }
                }
            ]
        },
        {
            label: 'Go Live (Optional)',
            component: <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign={'center'}
                marginTop={2}
            >
                <Typography variant="h5" gutterBottom>Form Setup Complete!</Typography>
                <Typography gutterBottom>Click below to publish it now and start collecting responses</Typography>
                <div>
                    <Button
                        disabled={!areRecipientsConfigured()}
                        onClick={makeFormLive}
                    >Make Form Live
                    </Button>
                </div>
                {
                    !areRecipientsConfigured()
                        ? <Alert
                            sx={{
                                maxWidth: 600,
                                marginLeft: 2,
                                marginRight: 2
                            }}
                            severity="warning"
                        >Form cannot be published due to no recipients defined. Please go
                            back and add recipients, or you can configure them later</Alert>
                        : ''
                }
                <Typography gutterBottom marginTop={1}>Or</Typography>
                <div>
                    <Button onClick={navigateBack}>Return to Forms Page</Button>
                </div>
            </Box>,
            actions: [
                {
                    label: 'Go back',
                    onClick: () => setActiveStep(activeStep - 1)
                }
            ]
        }
    ];

    const getBackdropMessage = () => {
        if (isFormSaving) return 'Saving form';
        if (isPublishingForm) return 'Publishing form';

        return '';
    };

    return (
        <>
            <Box>
                <Button
                    variant="text"
                    startIcon={<ArrowBackRoundedIcon/>}
                    onClick={navigateBack}
                >
                    Back
                </Button>
                <Typography
                    variant="h5"
                    py={2}>{title}
                </Typography>
            </Box>
            <Box
                sx={{
                    overflowX: 'auto',
                    maxWidth: '100%',
                    '&::-webkit-scrollbar': {
                        height: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: '#f1f1f1',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#c1c1c1',
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#a1a1a1',
                    },
                    scrollbarWidth: 'thin',
                    scrollbarColor: '#c1c1c1 #f1f1f1',
                }}
            >
                <Paper
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        minHeight: '100%',
                        minWidth: '1000px',
                    }}
                    variant="outlined"
                >
                    {
                        !isEditMode
                            ? <>
                                <Stepper
                                    sx={{padding: 2}}
                                    activeStep={activeStep}
                                >
                                    {steps.map(({label}) => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                                <Box display="flex" flexDirection="column" flexGrow={1}>
                                    <Box flexGrow={1}>
                                        {
                                            steps[activeStep].component
                                        }
                                    </Box>
                                    {
                                        steps[activeStep].actions
                                            ? <StickyFooter style={{zIndex: 1000}}>
                                                {
                                                    steps[activeStep].actions.map(({label, ...actionProps}) => (
                                                        <Tooltip
                                                            key={`${label}-tooltip`}
                                                            title={actionProps.tooltip}>
                                                            <span>
                                                                <Button
                                                                    key={label}
                                                                    variant="contained"
                                                                    {...actionProps}
                                                                >{label}
                                                                </Button>
                                                            </span>
                                                        </Tooltip>
                                                    ))
                                                }
                                            </StickyFooter>
                                            : ''
                                    }
                                </Box>
                            </>
                            : <>
                                <Tabs
                                    value={currentTabIndex}
                                    onChange={handleTabChange}
                                    textColor="inherit"
                                >
                                    {
                                        tabs.map((tab, index) => <Tab
                                            key={index}
                                            value={index}
                                            label={tab.label}/>)
                                    }
                                </Tabs>
                                <Box display="flex" flexDirection="column" flexGrow={1}>
                                    <Box flexGrow={1}>
                                        {
                                            tabs[currentTabIndex].component
                                        }
                                    </Box>
                                    <StickyFooter style={{zIndex: 1000}}>
                                        <Tooltip
                                            title={getFormValidationMessage()}>
                                            <span>
                                                <Button
                                                    variant="contained"
                                                    disabled={isFormIncomplete()}
                                                    onClick={async () => {
                                                        await handleSaveForm(navigateBack);
                                                    }}
                                                >Save form
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    </StickyFooter>
                                </Box>
                            </>
                    }
                    <Backdrop
                        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                        open={isFormSaving || isPublishingForm}
                    >
                        <Stack flexDirection="column" alignItems="center">
                            <CircularProgress size={40}/>
                            <Typography>{getBackdropMessage()}</Typography>
                        </Stack>
                    </Backdrop>
                </Paper>
            </Box>
        </>
    );
};

FormEditor.propTypes = {
    mode: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

export default FormEditor;