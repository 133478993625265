import React, {useEffect, useRef, useState} from 'react';
import {
    Box,
    Button,
    Checkbox,
    Paper,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import RequiredCheckbox from './Shared/RequiredCheckbox';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import RichEditor from './Shared/RichEditor';
import RichEditorResultText from './Shared/RichEditorResultText';
import {stripHtmlTags} from './AdditionalNotes';

const BoxGrid = ({
                     index,
                     id,
                     value,
                     onChange,
                     description,
                     isEditMode,
                     enableValidation,
                     isFormArchived,
                     rows,
                     columns,
                     readOnly,
                     type,
                     readonly,
                     placeholderValues,
                     accessLevel,
                     onUpdateDescription,
                     componentToEditIndex,
                 }) => {
    const [currentValue, setCurrentValue] = useState(description);
    const prevPropRef = useRef();
    const theme = useTheme();

    useEffect(() => {
        setCurrentValue(description)
    }, [description]);

    useEffect(() => {
        if (prevPropRef.current === index && componentToEditIndex !== prevPropRef.current) {
            onUpdateDescription({index, description: stripHtmlTags(currentValue), formattedDescription: currentValue});
        }
        prevPropRef.current = componentToEditIndex;
    }, [componentToEditIndex]);

    const handleToggle = (row, column) => {
        const updatedSelections = {...value};
        if (!updatedSelections[row.id]) {
            updatedSelections[row.id] = [];
        }
        if (updatedSelections[row.id].includes(column.id)) {
            updatedSelections[row.id] = updatedSelections[row.id].filter(item => item !== column.id);
        } else {
            updatedSelections[row.id].push(column.id);
        }
        onChange({id, value: updatedSelections});
    };

    const handleToggleRadio = (row, column) => {
        const updatedSelections = {...value};
        if (updatedSelections[row.id] === column.id) {
            delete updatedSelections[row.id];
        } else {
            updatedSelections[row.id] = column.id;
        }
        onChange({id, value: updatedSelections});
    };

    const isChecked = (row, column) => {
        return value && value[row.id]?.includes(column.id);
    };

    const isCheckedRadio = (row, column) => {
        return value && value[row.id] === column.id;
    };

    const buildEditableList = (data, listKey) =>
        <>
            {data.map((el, optIndex) => {
                return <Box display={'flex'} key={optIndex} alignItems={'center'}
                            justifyContent={'space-between'} width={'90%'}>
                    <Box display={'flex'} alignItems={'center'} gap={1} width={'90%'}>
                        <Typography>{optIndex + 1}.</Typography>
                        <TextField variant={'standard'} value={el.title} fullWidth onChange={e => onChange({
                            index,
                            option: e.target.value,
                            optionId: optIndex,
                            listKey
                        })}></TextField>
                    </Box>
                    <DeleteIcon
                        onClick={() => isFormArchived
                            ? {}
                            : onChange({
                                index,
                                isRemove: true,
                                optionId: optIndex,
                                listKey
                            })}
                        disabled={isFormArchived} cursor={isFormArchived ? '' : 'pointer'}/>
                </Box>;
            })}
            <Button onClick={() => onChange({index, isAdd: true, listKey})} startIcon={<AddIcon/>} cursor={'pointer'}
                    disabled={isFormArchived}>
                Add option
            </Button>
        </>;

    return (
        <Box>
            {isEditMode
                ? <>
                    <RichEditor value={description} onChange={onChange} index={index} isEditMode={isEditMode}
                                isFormLive={isFormArchived} accessLevel={accessLevel} currentDescription={currentValue}
                                setCurrentDescription={setCurrentValue}/>
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <Box width={'100%'}>
                            <Typography fontWeight={'bold'}>Rows</Typography>
                            {buildEditableList(rows, 'rows')}
                        </Box>
                        <Box width={'100%'}>
                            <Typography fontWeight={'bold'}>Columns</Typography>
                            {buildEditableList(columns, 'columns')}
                        </Box>
                    </Box>
                    <RequiredCheckbox enableValidation={enableValidation} index={index} onChange={onChange}
                                      isFormArchived={isFormArchived}/>
                </>
                : <>
                    <RichEditorResultText id={id} description={description} placeholderValues={placeholderValues}
                                          readonly={readonly} enableValidation={enableValidation}/>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    {columns.map((column, index) => (
                                        <TableCell key={index} align="center"
                                        sx={{
                                            ...theme.typography.body1,
                                            color: theme.palette.text.primary
                                        }}
                                        >{column.title}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        <TableCell component="th" scope="row"
                                         sx={{
                                            ...theme.typography.body1,
                                            color: theme.palette.text.primary
                                        }}
                                        >
                                            {row.title}
                                        </TableCell>
                                        {columns.map((column, columnIndex) => (
                                            <TableCell key={columnIndex} align="center">
                                                {type === 'tickBoxGrid'
                                                    ? <Checkbox
                                                        disabled={readOnly}
                                                        checked={isChecked(row, column)}
                                                        onChange={() => handleToggle(row, column)}
                                                        size={'small'}
                                                    />
                                                    : <Radio disabled={readOnly}
                                                             size={'small'}
                                                             key={`radioButton-${rowIndex}-${columnIndex}-${value && value[row.id] ? value[row.id] : ''}`}
                                                             checked={isCheckedRadio(row, column)}
                                                             onClick={() => handleToggleRadio(row, column)}
                                                    />
                                                }
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            }
        </Box>
    );
};

BoxGrid.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    rows: PropTypes.array,
    columns: PropTypes.array,
    index: PropTypes.number,
    description: PropTypes.string,
    enableValidation: PropTypes.bool,
    onChange: PropTypes.func,
    isEditMode: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    readOnly: PropTypes.bool,
    type: PropTypes.string,
    readonly: PropTypes.bool,
    placeholderValues: PropTypes.object,
    accessLevel: PropTypes.number,
    onUpdateDescription: PropTypes.func,
    componentToEditIndex: PropTypes.number
};

export default BoxGrid;