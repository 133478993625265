import React, {useState, useEffect} from 'react';
import {Box, Checkbox, Divider, Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {STUDENT_PROFILE_CONFIRMATION_TYPES} from '../../const/StudentProfileConfirmationTypes';
import ConfirmationWrapper from '../../Components/ConfirmationWrapper';
import {useGetMedicalOtcQuery, useManageMedicalOtcMutation} from '../../../../../api/services/studentService';
import {useGetMedicalConsentOptionsQuery} from '../../../../../api/services/lookupsService';
/* eslint-disable react/prop-types */

const MedicalOtc = ({
    readOnly,
    studentId,
    onConfirmSuccess
}) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [deselectedOptions, setDeselectedOptions] = useState([]);
    const {currentSchool, selectedProfile} = useSelector(state => state.profilesInfo);
    const [manageMedicalOtc] = useManageMedicalOtcMutation();
    const {
        data: medicalInfo = {options: []},
        isLoading: isMedicalOtcLoading,
        refetch: refetchMedicalOtcData
    } = useGetMedicalOtcQuery(studentId);

    const {
        data: medicalConsentOptions = {options: []},
        isLoading: isMedicalConsentOptionsLoading,
        refetch: refetchMedicalConsentsData
    } = useGetMedicalConsentOptionsQuery();

    useEffect(() => {
        if (!isMedicalOtcLoading) {
            setSelectedOptions(medicalInfo.medicalConsentOptions?.options.map(x => x.optionId));
        }
    }, [isMedicalOtcLoading, medicalInfo]);

    const handleCheckMedicalConsents = (event) => {
        const {checked, value} = event.target;
        const optionId = parseInt(value);

        if (checked) {
            setSelectedOptions([...selectedOptions, optionId]);
            setDeselectedOptions(deselectedOptions.filter(id => id !== optionId));
        } else {
            setDeselectedOptions([...deselectedOptions, optionId]);
            setSelectedOptions(selectedOptions.filter(id => id !== optionId));
        }
    };
    const isNothingChanged = () => {
        const isSelectedOptionsClear = selectedOptions
            ?.filter(x => !medicalInfo.medicalConsentOptions?.options.some(v => v.optionId === x)).length === 0;
        const isDeselectedOptionsClear = deselectedOptions
            ?.filter(x => medicalInfo.medicalConsentOptions?.options.some(v => v.optionId === x)).length === 0;
        return isSelectedOptionsClear && isDeselectedOptionsClear;
    };

    const handleSave = async (confirmationType) => {
        const payload = {
            studentId,
            confirmationType,
            selectedConsents: selectedOptions
                .filter(x => !medicalInfo.medicalConsentOptions?.options.some(v => v.optionId === x)),
            deselectedConsents: deselectedOptions
                .filter(x => medicalInfo.medicalConsentOptions?.options.some(v => v.optionId === x))
        };
        await manageMedicalOtc(payload)
    };
    return (
        isMedicalOtcLoading || isMedicalConsentOptionsLoading
            ? ''
            : <>
                <ConfirmationWrapper
                    readonly={readOnly}
                    confirmData={{
                        confirmMessage: `I confirm that the information in the medical profile section of ${selectedProfile.name} ${selectedProfile.surname} student profile is correct and understand it will be relied upon by school staff and any other person or organisation who may have ${selectedProfile.name} ${selectedProfile.surname} in their care on behalf of ${currentSchool.name}`,
                        confirmedAt: medicalInfo?.medicalOtcConfirmedAt,
                        confirmedBy: medicalInfo?.medicalOtcConfirmedBy,
                        enableSaveButton: !isNothingChanged(),
                        enableConfirmButton: isNothingChanged() && selectedOptions?.length > 0,
                        studentId,
                        type: STUDENT_PROFILE_CONFIRMATION_TYPES?.MedicalOtc,
                        handleSave,
                        onConfirmSuccess: async () => {
                            await onConfirmSuccess();
                            await refetchMedicalConsentsData();
                            await refetchMedicalOtcData();
                        },
                        confirmButtonText: 'Confirm OTC Medication'
                    }}
                >
                    <Typography variant='h5' pb={2}>Non-Prescription (OTC) Medication</Typography>
                    <Typography
                        variant="body1">Do you give consent for the {medicalInfo.schoolName} to administer the
                        following medication to {selectedProfile.name} during the school day if required?
                    </Typography>
                    {medicalConsentOptions.options?.map((field, index) => (
                        <Box
                            key={index}
                            display={'flex'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Typography>{field.description}</Typography>
                            <Checkbox
                                sx={{width: '25px', height: '25px'}}
                                value={field.id}
                                checked={selectedOptions?.some(id => id === field.id)}
                                onChange={handleCheckMedicalConsents}
                                disabled={readOnly}
                            />
                        </Box>
                    ))}
                </ConfirmationWrapper>
                <Divider sx={{mt: 2, mb: 2}}/>
            </>
    );
};

export default MedicalOtc;