import React, {useEffect} from 'react';
import FormRequest from '../FormRequest';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {
    useGetMyFormRequestQuery,
    useGetFormRequestQuery,
    useGetGeneralFormRequestQuery,
    useSubmitFormMutation
} from '../../../../../api/services/formRequestsService';
import {showSnackbar} from '../../../../../AppLayout/ApplicationSlice';
import useUser from '../../../../../utility/hooks/useUser';
import PropTypes from 'prop-types';
import objectToFormData from '../../../../../utility/objectToFormData';
import {generateUUID} from '../../../../../utility/uuidUtil';
import useFileDownloader from '../../../../../utility/hooks/useFileDownloader';
import {downloadFormRequestAttachment} from '../../../../../api/services/filesService';

const SubmitPrivateForm = ({navigateBackRoute, personalForm}) => {
    const {user} = useUser();
    const isPersonalForm = typeof personalForm === 'function'
        ? personalForm({user})
        : personalForm;
    const {formRequestId, formId, studentId} = useParams();
    const dispatch = useDispatch();
    const {
        data: formRequestData,
        isLoading: isLoadingFormRequest,
        isFetching: isFetchingFormRequest,
        error: formRequestError,
        refetch: refetchFormRequest
    } = useGetFormRequestQuery(formRequestId, { skip: isPersonalForm || !formRequestId });
    const {
        data: myFormRequestData,
        isLoading: isLoadingMyFormRequestData,
        isFetching: isFetchingMyFormRequestData,
        error: myFormRequestError,
        refetch: refetchMyFormRequest
    } = useGetMyFormRequestQuery(formRequestId, { skip: !formRequestId || !isPersonalForm });
    const {
        data: generalFormData,
        isLoading: isLoadingGeneralForm,
        isFetching: isFetchingGeneralForm,
        error: generalFormError
    } = useGetGeneralFormRequestQuery(
        { formId, studentId },
        { skip: !formId || !studentId }
    );
    const [submitForm, {
        isLoading: isFormSubmitLoading,
        isSuccess: isFormSubmitSuccess,
        isError: isFormSubmitError,
        error: submitError
    }] = useSubmitFormMutation();
    const downloadFile = useFileDownloader();
    const navigate = useNavigate();

    const isLoading = isLoadingFormRequest || isLoadingGeneralForm || isLoadingMyFormRequestData;
    const isFetching = isFetchingFormRequest || isFetchingMyFormRequestData || isFetchingGeneralForm;
    const error = formRequestError || generalFormError || myFormRequestError;
    const formRequest = formRequestData || generalFormData || myFormRequestData;

    useEffect(() => {
        if (isFormSubmitError || isFormSubmitSuccess) {
            if (isFormSubmitSuccess) {
                if (formRequestId) {
                    if (isPersonalForm) {
                        refetchMyFormRequest();
                    } else {
                        refetchFormRequest();
                    }
                } else {
                    navigate('submit-success', {replace: true});
                }

            }
            showNotification(isFormSubmitSuccess);
        }
    }, [isFormSubmitSuccess, isFormSubmitError]);

    const showNotification = success => success !== undefined && dispatch(
        {
            true: () => showSnackbar({
                message: `${formRequest.formName} submitted successfully`,
                severity: 'success'
            }),
            false: () => showSnackbar({
                message: submitError,
                severity: 'error'
            })
        }[success]()
    );

    const handleDownloadAttachment = async (fileId) => {
        await downloadFile(downloadFormRequestAttachment, {
            fileId,
            formRequestId,
            roles: user.roles,
            studentId: formRequest.studentId,
            shouldDownloadByCurrentUser: isPersonalForm
        });
    };

    const handleSubmit = async (data, placeholders) => {
        const responses = {};
        const attachments = [];
        const attachmentKeys = [];

        for (const key in data) {
            if (data[key] instanceof Blob) {
                const file = data[key];
                const fileKey = generateUUID();

                attachments.push(file);
                attachmentKeys.push(fileKey);
                responses[key] = {name: file.name, fileKey};
            } else {
                responses[key] = data[key];
            }
        }

        await submitForm(objectToFormData({
            id: formRequestId,
            responses: JSON.stringify(responses),
            formId,
            studentId,
            attachments,
            attachmentKeys,
            placeholders
        }));
    };

    return <FormRequest
        generalForm={formId !== undefined && studentId !== undefined}
        formRequest={formRequest}
        isLoading={isLoading || isFetching || isFormSubmitLoading}
        error={error}
        onSubmit={handleSubmit}
        onDownloadAttachment={handleDownloadAttachment}
        navigateBackRoute={navigateBackRoute}
    />;
};

SubmitPrivateForm.defaultProps = {
    personalForm: false
}

SubmitPrivateForm.propTypes = {
    navigateBackRoute: PropTypes.string.isRequired,
    personalForm: PropTypes.bool,
};
export default SubmitPrivateForm;