import {apiService} from '../apiService';

export const employeeService = apiService.injectEndpoints({
    endpoints: (builder) => {
        return {
            getEmployeeById: builder.query({
                query: (staffId) => ({
                    url: `/employees/${staffId}`
                }),
                transformErrorResponse: (_) => ({message: 'Failed to retrieve staff profile'}),
            }),
            getEmployeeByCurrentUser: builder.query({
                query: () => ({
                    url: '/employees/current'
                }),
                transformErrorResponse: (_) => ({message: 'Failed to retrieve profile information'}),
            }),
            getAllEmployees: builder.query({
                query: (body) => ({
                    url: '/employees/list',
                    method: 'POST',
                    data: body
                }),
                transformErrorResponse: (_) => ({message: 'Failed to retrieve staff profiles'}),
            }),
            getAllEmployeeProfiles: builder.query({
                query: (body) => ({
                    url: '/employees/all',
                    method: 'POST',
                    data: body
                }),
                transformErrorResponse: (_) => ({message: 'Failed to retrieve staff profiles'}),
            }),
        };
    },
});

export const {
    useGetEmployeeByIdQuery,
    useGetEmployeeByCurrentUserQuery,
    useGetAllEmployeesQuery,
    useGetAllEmployeeProfilesQuery
} = employeeService;
