import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import PropTypes from 'prop-types';
import RichEditor from './Shared/RichEditor';
import RichEditorResultText from './Shared/RichEditorResultText';

export const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
};

const Payment = ({
    index,
    id,
    description,
    paymentLink = '',
    onChange,
    onUpdateDescription,
    isEditMode,
    isFormArchived,
    placeholderValues,
    readOnly,
    accessLevel,
    componentToEditIndex,
}) => {
    const [currentValue, setCurrentValue] = useState(description);
    const [errorText, setErrorText] = useState('');
    const prevPropRef = useRef();

    useEffect(() => {
        setCurrentValue(description);
    }, [description]);

    useEffect(() => {
        if (prevPropRef.current === index && componentToEditIndex !== prevPropRef.current) {
            onUpdateDescription({
                index,
                description: stripHtmlTags(currentValue),
                formattedDescription: currentValue,
            });
        }
        prevPropRef.current = componentToEditIndex;
    }, [componentToEditIndex, currentValue, index, onUpdateDescription]);

    const validateUrl = (url) => {
        const trimmedUrl = url.trim();
        if (!trimmedUrl) {
            setErrorText('');
            return false;
        }

        if (!trimmedUrl.startsWith('https://')) {
            setErrorText('URL must start with https://');
            return false;
        }

        setErrorText('');
        return true;
    };

    const handleUrlChange = (e) => {
        const newUrl = e.target.value;
        validateUrl(newUrl);

        onUpdateDescription({
            index,
            paymentLink: newUrl
        });
    };
    const openPopout = () => {
        if (!errorText && paymentLink) {
            window.open(paymentLink, '_blank');
        }
    };

    return (
        <Box>
            {isEditMode
                ? (
                    <RichEditor
                        value={description}
                        onChange={onChange}
                        id={id}
                        index={index}
                        isEditMode={isEditMode}
                        isFormLive={isFormArchived}
                        accessLevel={accessLevel}
                        componentToEditIndex={componentToEditIndex}
                        currentDescription={currentValue}
                        setCurrentDescription={setCurrentValue}
                        sx={{ mb: 2 }}
                    />
                )
                : (
                    <RichEditorResultText
                        id={id}
                        description={description}
                        placeholderValues={placeholderValues}
                        readonly={readOnly}
                        enableValidation={false}
                        sx={{ mb: 2 }}
                    />
                )
            }

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                {isEditMode && (
                    <TextField
                        placeholder="Enter payment link here"
                        value={paymentLink}
                        onChange={handleUrlChange}
                        error={!!errorText}
                        helperText={errorText}
                        sx={{ flexGrow: 1, minWidth: '200px', pr: 1, py: 1 }}
                    />
                )}
                <Button
                    onClick={openPopout}
                    startIcon={<PaymentOutlinedIcon />}
                    variant="outlined"
                    disabled={!!errorText || !paymentLink || readOnly}
                >
                    Make Payment
                </Button>
            </Box>
        </Box>
    );
};

Payment.propTypes = {
    id: PropTypes.string,
    description: PropTypes.string,
    paymentLink: PropTypes.string,
    index: PropTypes.number,
    onChange: PropTypes.func,
    onUpdateDescription: PropTypes.func,
    isEditMode: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    readOnly: PropTypes.bool,
    accessLevel: PropTypes.number,
    placeholderValues: PropTypes.object,
    componentToEditIndex: PropTypes.number,
};

export default Payment;
