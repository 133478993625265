import {formatDateTime} from '../../../../utility/dateUtil';
import DateRangePicker from '../../../../shared-components/Table/V2/components/columnFilters/DateRangePicker';
import FILTER_OPERATIONS from '../../../../constants/filterOperations';
import React, {useEffect, useMemo} from 'react';
import RuleRoundedIcon from '@mui/icons-material/RuleRounded';
import withPaginationV2 from '../../../../hoc/pagination/withPaginationV2';
import AdmicityTableV2 from '../../../../shared-components/Table/V2/AdmicityTableV2';
import {useGetSyncDetailsQuery} from '../../../../api/services/schoolManagementService';
import {SYNC_STATUSES} from '../../../../constants/syncStatuses';
import AdmicityStatus from '../../../../shared-components/AdmicityStatus';
import {useDispatch, useSelector} from 'react-redux';
import {ListSubheader, MenuItem} from '@mui/material';
import {resetTableState} from '../../../../store/tableSlice';

const getSyncTaskStatusColor = (status) => ({
    label: status,
    color: {
        [SYNC_STATUSES.success]: 'green',
        [SYNC_STATUSES.failure]: 'red'
    }[status] ?? 'grey'
});
const quickFilterOptionValues = {
    updateApplicant: 'UPDATE_APPLICANT',
    updateStudent: 'UPDATE_STUDENT',
    updateApplicantMedicalConditions: 'UPDATE_APPLICANT_MEDICAL_CONDITIONS',
    updateStudentMedicalConditions: 'UPDATE_STUDENT_MEDICAL_CONDITIONS',
    updateApplicantGpInfo: 'UPDATE_APPLICANT_GP_INFO',
    updateStudentGpInfo: 'UPDATE_STUDENT_GP_INFO',
    syncGroups: 'SYNC_GROUPS',
    syncApplications: 'SYNC_APPLICATIONS',
    syncStudents: 'SYNC_STUDENTS',
    syncApplicationAvatar: 'SYNC_APPLICATION_AVATARS',
    syncStudentAvatars: 'SYNC_STUDENT_AVATARS',
    syncLookup: 'SYNC_LOOKUP',
    syncEmployee: 'SYNC_STAFF',
    updateApplicantContact: 'UPDATE_APPLICANT_CONTACTS',
    updateStudentContact: 'UPDATE_STUDENT_CONTACTS',
    createApplicant: 'CREATE_APPLICANT',
    updateApplicantSenNeed: 'UPDATE_APPLICANT_SEN_NEED',
    updateApplicantSenStatus: 'UPDATE_APPLICANT_SEN_STATUS',
    updateStudentSenStatus: 'UPDATE_STUDENT_SEN_STATUS',
    updateStudentSenNeed: 'UPDATE_STUDENT_SEN_NEED',
    updateApplicantDisability: 'UPDATE_APPLICANT_DISABILITY',
    updateStudentDisability: 'UPDATE_STUDENT_DISABILITY',
    updateApplicantSenDocuments: 'UPDATE_APPLICANT_SEN_DOCUMENT',
    updateApplicantEhcpDocuments: 'UPDATE_APPLICANT_EHCP_DOCUMENT',
    updateStudentSenDocuments: 'UPDATE_STUDENT_SEN_DOCUMENT',
    updateStudentEhcpDocuments: 'UPDATE_STUDENT_EHCP_DOCUMENT',
    updateApplicantConsent: 'UPDATE_APPLICANT_CONSENT',
    updateStudentConsent: 'UPDATE_STUDENT_CONSENT',
    updateStudentEthnicity: 'UPDATE_STUDENT_ETHNICITY',
    updateApplicantEthnicity: 'UPDATE_APPLICANT_ETHNICITY',
    updateApplicantEthnicityPassport: 'UPDATE_APPLICANT_ETHNICITY_PASSPORT',
    updateStudentEthnicityPassport: 'UPDATE_STUDENT_ETHNICITY_PASSPORT',
    updateApplicantDietaryNeeds: 'UPDATE_APPLICANT_DIETARY_NEEDS',
    updateStudentDietaryNeeds: 'UPDATE_STUDENT_DIETARY_NEEDS',
    updateStudentTravelMode: 'UPDATE_STUDENT_TRAVEL_MODE',
    updateApplicantTravelMode: 'UPDATE_APPLICANT_TRAVEL_MODE',
    updateApplicantMedicalEvents: 'UPDATE_APPLICANT_MEDICAL_EVENTS',
    updateApplicantMedicalNotes: 'UPDATE_APPLICANT_MEDICAL_NOTES',
    updateStudentMedicalEvents: 'UPDATE_STUDENT_MEDICAL_EVENTS',
    updateStudentMedicalNotes: 'UPDATE_STUDENT_MEDICAL_NOTES'
};

const quickFilterOptions = {
    sync: [
        {value: quickFilterOptionValues.syncGroups, label: 'Sync Groups'},
        {value: quickFilterOptionValues.syncApplications, label: 'Sync Applications'},
        {value: quickFilterOptionValues.syncStudents, label: 'Sync Students'},
        {value: quickFilterOptionValues.syncApplicationAvatar, label: 'Sync Application Avatars'},
        {value: quickFilterOptionValues.syncStudentAvatars, label: 'Sync Student Avatars'},
        {value: quickFilterOptionValues.syncLookup, label: 'Sync Lookup'},
        {value: quickFilterOptionValues.syncEmployee, label: 'Sync Employee'},
    ],
    nonSync: [
        {value: quickFilterOptionValues.updateApplicant, label: 'Update Applicant'},
        {value: quickFilterOptionValues.updateStudent, label: 'Update Student'},
        {value: quickFilterOptionValues.updateApplicantMedicalConditions, label: 'Update Applicant Medical Conditions'},
        {value: quickFilterOptionValues.updateStudentMedicalConditions, label: 'Update Student Medical Conditions'},
        {value: quickFilterOptionValues.updateApplicantGpInfo, label: 'Update Applicant GP Info'},
        {value: quickFilterOptionValues.updateStudentGpInfo, label: 'Update Student GP Info'},
        {value: quickFilterOptionValues.updateApplicantContact, label: 'Update Applicant Contacts'},
        {value: quickFilterOptionValues.updateStudentContact, label: 'Update Student Contacts'},
        {value: quickFilterOptionValues.createApplicant, label: 'Create Applicant'},
        {value: quickFilterOptionValues.updateApplicantSenNeed, label: 'Update Applicant SEN Need'},
        {value: quickFilterOptionValues.updateApplicantSenStatus, label: 'Update Applicant SEN Status'},
        {value: quickFilterOptionValues.updateStudentSenStatus, label: 'Update Student SEN Status'},
        {value: quickFilterOptionValues.updateStudentSenNeed, label: 'Update Student SEN Need'},
        {value: quickFilterOptionValues.updateApplicantDisability, label: 'Update Applicant Disability'},
        {value: quickFilterOptionValues.updateStudentDisability, label: 'Update Student Disability'},
        {value: quickFilterOptionValues.updateApplicantSenDocuments, label: 'Update Applicant SEN Documents'},
        {value: quickFilterOptionValues.updateApplicantEhcpDocuments, label: 'Update Applicant EHCP Documents'},
        {value: quickFilterOptionValues.updateStudentSenDocuments, label: 'Update Student SEN Documents'},
        {value: quickFilterOptionValues.updateStudentEhcpDocuments, label: 'Update Student EHCP Documents'},
        {value: quickFilterOptionValues.updateApplicantConsent, label: 'Update Applicant Consent'},
        {value: quickFilterOptionValues.updateStudentConsent, label: 'Update Student Consent'},
        {value: quickFilterOptionValues.updateStudentEthnicity, label: 'Update Student Ethnicity'},
        {value: quickFilterOptionValues.updateApplicantEthnicity, label: 'Update Applicant Ethnicity'},
        {value: quickFilterOptionValues.updateApplicantEthnicityPassport, label: 'Update Applicant Ethnicity Passport'},
        {value: quickFilterOptionValues.updateStudentEthnicityPassport, label: 'Update Student Ethnicity Passport'},
        {value: quickFilterOptionValues.updateApplicantDietaryNeeds, label: 'Update Applicant Dietary Needs'},
        {value: quickFilterOptionValues.updateStudentDietaryNeeds, label: 'Update Student Dietary Needs'},
        {value: quickFilterOptionValues.updateStudentTravelMode, label: 'Update Student Travel Mode'},
        {value: quickFilterOptionValues.updateApplicantTravelMode, label: 'Update Applicant Travel Mode'},
        {value: quickFilterOptionValues.updateApplicantMedicalNotes, label: 'Update Applicant Medical Notes'},
        {value: quickFilterOptionValues.updateApplicantMedicalEvents, label: 'Update Applicant Medical Events'},
        {value: quickFilterOptionValues.updateStudentMedicalNotes, label: 'Update Student Medical Notes'},
        {value: quickFilterOptionValues.updateStudentMedicalEvents, label: 'Update Student Medical Events'},
    ],
};

const taskLabels = Object.values(quickFilterOptions)
    .flatMap(x => x)
    .reduce((acc, x) => {
        acc[x.value] = x.label;

        return acc;
    }, {});

const Table = withPaginationV2(AdmicityTableV2, {func: useGetSyncDetailsQuery});

const TABLE_ID = 'syncLogs';

const SyncLog = () => {
    const quickFilter = useSelector(state => state.table.tables.default?.quickFilters)?.globalFilter;
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(resetTableState(TABLE_ID));
        };
    }, [dispatch]);

    /* eslint-disable react/prop-types */
    const columns = useMemo(() =>
        [
            {
                accessorKey: 'name',
                header: 'Name',
                enableColumnFilter: false,
                Cell: ({cell}) => taskLabels[cell.getValue()] ?? cell.getValue()
            },
            {
                accessorKey: 'status',
                header: 'Status',
                filterFn: FILTER_OPERATIONS.EQUALS,
                filterVariant: 'select',
                filterSelectOptions: Object.values(SYNC_STATUSES).map(x => ({value: x, label: x})),
                Cell: ({cell}) => cell.getValue() && <AdmicityStatus {...getSyncTaskStatusColor(cell.getValue())}/>,
            },
            {
                accessorKey: 'syncTime',
                header: 'Sync Time',
                Cell: ({cell}) => formatDateTime(cell.getValue()),
                Filter: DateRangePicker,
                filterFn: FILTER_OPERATIONS.BETWEEN_INCLUSIVE,
            },
            ...(!quickFilter || !quickFilter?.toUpperCase().startsWith('SYNC')
                ? [{
                    accessorKey: 'studentFullName',
                    header: 'Student',
                    enableColumnFilter: false,
                }]
                : [])
        ], [quickFilter]);
    /* eslint-enable react/prop-types */

    return (
        <Table
            id={TABLE_ID}
            columns={columns}
            tableProps={{
                enableSorting: false,
                enableColumnResizing: false,
                enableHiding: false,
                showGlobalFilter: true,
                enableRowActions: true
            }}
            toolbarActions={[
                {
                    type: 'quickFilterSelect',
                    accessorKey: 'globalFilter',
                    label: 'Filters',
                    variant: 'small',
                    options: quickFilterOptions,
                    defaultValue: '',
                    textFieldProps: {
                        SelectProps: {
                            displayEmpty: true
                        },
                        InputLabelProps: {
                            shrink: true
                        }
                    },
                    render: options =>
                        options
                            ? [
                                <MenuItem value="" key="default">All</MenuItem>,
                                <ListSubheader key="sync-header">Sync from SIMS to Admicity</ListSubheader>,
                                ...options.sync.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                )),
                                <ListSubheader key="non-sync-header">Sync from Admicity to SIMS</ListSubheader>,
                                ...options.nonSync.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                )),
                            ]
                            : ''
                }
            ]}
            noRowsOverlay={{
                icon: <RuleRoundedIcon/>,
                text: 'No logs to display'
            }}
        />
    );
};

export default SyncLog;