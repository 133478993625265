import {useSelector} from 'react-redux';
import {ROLES} from '../../constants/roles';

export const useUser = () => {
    const {authData, account} = useSelector((state) => state.application);

    return {
        account,
        user: {
            ...authData,
            hasRole(role) {
                return this.roles.includes(role);
            },
            isParent() {
                return this.roles.includes(ROLES.PARENT) &&
                    ![ROLES.MEMBER_OF_STAFF, ROLES.SENIOR_MEMBER_OF_STAFF, ROLES.SCHOOL_ADMIN].some(role => this.roles.includes(role));
            },
            hasElevatedAccess() {
                return [ROLES.MEMBER_OF_STAFF, ROLES.SENIOR_MEMBER_OF_STAFF, ROLES.SCHOOL_ADMIN].some(role => this.roles.includes(role));
            },
            hasRoles(roles) {
                return roles.some(role => this.roles.includes(role));
            }
        }
    };
};

export default useUser;