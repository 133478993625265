import {STUDENT_PROFILE_CONFIRMATION_TYPES} from '../../const/StudentProfileConfirmationTypes';
import ConfirmationWrapper from '../../Components/ConfirmationWrapper';
import {useGetConsentsQuery, useUpdateConsentsMutation} from '../../../../../api/services/studentService';
import {useParams} from 'react-router-dom';
import React, {useEffect, useMemo, useState} from 'react';
import useNotification from '../../../../../utility/hooks/useNotification';
import {Checkbox, Divider, FormControlLabel, TextField} from '@mui/material';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import useFileDownloader from '../../../../../utility/hooks/useFileDownloader';
import {downloadConsentFile} from '../../../../../api/services/filesService';
import Paper from '@mui/material/Paper';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import {useGetConsentDescriptionQuery} from '../../../../../api/services/schoolManagementService';

const Consents = (
    {
        readonly,
        profile,
        school,
        onConfirmSuccess
    }) => {
    const {studentId} = useParams();
    const {
        data: consentsData = {},
        isLoading: isConsentsLoading,
        isFetching: isConsentsFetching,
        refetch: refetchConsentsData
    } = useGetConsentsQuery(studentId);
    const [updateConsents, {
        isSuccess: isUpdateConsentsSuccess,
        isError: isUpdateConsentsError
    }] = useUpdateConsentsMutation();
    const {data: consentDescriptionData} = useGetConsentDescriptionQuery();
    const [comment, setComment] = useState('');
    const [isEdited, setIsConsentFieldsEdited] = useState(false);
    const [selectedConsentsCodes, setSelectedConsentsCodes] = useState([]);
    const {showErrorNotification, showSuccessNotification} = useNotification();
    const downloadFile = useFileDownloader();
    const isLoading = isConsentsLoading || isConsentsFetching;

    useEffect(() => {
        if (consentsData.comment) {
            setComment(consentsData.comment);
        }
        if (consentsData.consents) {
            setSelectedConsentsCodes(consentsData.consents
                .filter(c => c.isChecked)
                .map(c => c.code)
            );
        }
    }, [consentsData]);

    useEffect(() => {
        if (isUpdateConsentsSuccess) {
            showSuccessNotification('Consents updated successfully');
        } else if (isUpdateConsentsError) {
            showErrorNotification('Failed to update consents');
        }
    }, [isUpdateConsentsSuccess, isUpdateConsentsError]);

    const handleChangeCheckbox = (consentCode, isChecked) => {
        setIsConsentFieldsEdited(true);
        setSelectedConsentsCodes(isChecked
            ? [...selectedConsentsCodes, consentCode]
            : selectedConsentsCodes.filter(code => code !== consentCode));
    };

    const handleChangeComment = (comment) => {
        setComment(comment);
        setIsConsentFieldsEdited(true);
    };

    const handleSave = async () => {
        const activeCodes = consentsData.consents
            .filter(x => selectedConsentsCodes.includes(x.code))
            .map(x => ({code: x.code, isGlobal: x.isGlobalConsent}));

        await updateConsents({studentId, body: {activeCodes, studentId, comment}});
        setIsConsentFieldsEdited(false);
    };

    const consentOptions = useMemo(() => {
        if (!consentsData.consents) return [];

        return [...consentsData.consents]
            .sort((a, b) => (a.name || '').localeCompare(b.name || ''))
            .map(x => ({...x, description: x.description?.replaceAll('@studentForename', profile.name)}));
    }, [consentsData, profile]);

    const handleDownloadFile = async (consentCode) =>
        await downloadFile(
            downloadConsentFile,
            consentCode);

    return (
        isLoading
            ? ''
            : <ConfirmationWrapper
                readonly={readonly}
                confirmData={{
                    confirmMessage: `I confirm that the information in the consents profile section of ${profile.name} ${profile.surname} student profile is correct and understand it will be relied upon by school staff and any other person or organisation who may have ${profile.name} ${profile.surname} in their care on behalf of ${school.name}`,
                    confirmedAt: consentsData?.consentsConfirmedAt,
                    confirmedBy: consentsData?.consentsConfirmedBy,
                    enableSaveButton: isEdited,
                    studentId,
                    type: STUDENT_PROFILE_CONFIRMATION_TYPES.Consents,
                    handleSave,
                    onConfirmSuccess: async () => {
                        await onConfirmSuccess();
                        await refetchConsentsData();
                    },
                    confirmButtonText: 'Confirm Consents'
                }}
            >
                <Typography variant="h6">CONSENTS</Typography>
                {
                    consentDescriptionData.description
                        ? <Typography variant="subtitle2" gutterBottom>{consentDescriptionData.description}</Typography>
                        : null
                }
                {
                    consentOptions.map(consent =>
                        profile.isApplicant && !consent.isApplicantVisible
                            ? ''
                            : <FormControl key={consent?.name}>
                                <FormControlLabel
                                    key={consent?.name}
                                    control={<Checkbox
                                        sx={{
                                            padding: 0,
                                            marginRight: 1,
                                            marginLeft: 1
                                        }}
                                        checked={selectedConsentsCodes.includes(consent?.code)}
                                        onChange={e => handleChangeCheckbox(consent?.code, e.target.checked)}
                                        disabled={readonly}
                                    />}
                                    label={consent?.name}
                                />
                                <FormHelperText
                                    sx={{
                                        marginLeft: 4.2,
                                        marginTop: 0,
                                    }}
                                >
                                    {consent?.description}
                                    {
                                        consent.file != null
                                            ? <Paper
                                                variant="outlined"
                                                sx={{
                                                    width: 'fit-content',
                                                    display: 'flex',
                                                    flexWrap: 'nowrap',
                                                    alignItems: 'center',
                                                    paddingLeft: 1,
                                                    paddingRight: 1,
                                                    borderRadius: 2,
                                                    maxWidth: '100%',
                                                    gap: 1
                                                }}>
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis'
                                                    }}
                                                >{consent.file.name}</Typography>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => handleDownloadFile(consent.code)}
                                                ><DownloadRoundedIcon fontSize="small"/></IconButton>
                                            </Paper>
                                            : null
                                    }
                                </FormHelperText>
                            </FormControl>
                    )
                }
                <Divider sx={{py: 1}}/>
                <Typography variant={'h6'} sx={{py: 1}}>Comments</Typography>
                <TextField
                    multiline
                    value={comment}
                    disabled={readonly}
                    onChange={(e) => e.target.value.length < 255 && handleChangeComment(e.target.value)}
                />
            </ConfirmationWrapper>
    );
};

Consents.propTypes = {
    readonly: PropTypes.bool,
    profile: PropTypes.object.isRequired,
    school: PropTypes.object.isRequired,
    onConfirmSuccess: PropTypes.func,
};

export default Consents;