import {jwtDecode} from 'jwt-decode';
import {ROLES} from '../constants/roles';
import Cookies from 'js-cookie';

export const getToken = () => Cookies.get('jwtHeaderPayload');

export const getRefreshToken = () => Cookies.get('refreshToken');

export const clearCookies = () => {
    Cookies.remove('jwtHeaderPayload');
    Cookies.remove('refreshToken');
};

export const getRoles = () => {
    const token = getToken();

    try {
        const decodedToken = jwtDecode(token);

        if (!decodedToken) {
            return [ROLES.NONE];
        }

        return Array.isArray(decodedToken.role)
            ? decodedToken.role
            : [decodedToken.role];
    } catch (e) {
        console.error(e);
    }

    return [ROLES.NONE];
};
