import AdmicityTableV2 from '../../../../shared-components/Table/V2/AdmicityTableV2';
import {
    useAddFormCategoryMutation, useDeleteFormCategoryMutation,
    useGetFormCategoriesQuery,
    useUpdateFormCategoryMutation
} from '../../../../api/services/formCategoriesService';
import React, {useEffect, useState} from 'react';
import useNotification from '../../../../utility/hooks/useNotification';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CategoryIcon from '@mui/icons-material/Category';
import {Box, FormControl, FormLabel, Stack, TextField, Typography} from '@mui/material';
import {CirclePicker} from 'react-color';
import * as muiColors from '@mui/material/colors';
import AdmicityDialog from '../../../../shared-components/AdmicityDialog';
import {validateFieldLength} from '../../../../utility/validationUtil';
import useTextField from '../../../../utility/hooks/useTextField';
import CircleIcon from '@mui/icons-material/Circle';

/* eslint-disable react/prop-types */
const columns = [
    {
        accessorKey: 'name',
        header: 'Name',
        Cell: ({cell, row}) =>
            <Stack direction="row" alignItems="center" gap={1}>
                <CircleIcon fontSize="small" style={{color: row.original.color}}/>
                <Typography>{cell.getValue()}</Typography>
            </Stack>,
    }
];
/* eslint-enable react/prop-types */

const colors = Object.values(muiColors)
    .filter(color => color[300])
    .map(color => color[300]);

const FormCategories = () => {
    const {data, isLoading, isFetching, isError, error} = useGetFormCategoriesQuery();
    const {showErrorNotification, showSuccessNotification, showDetailedErrorNotification} = useNotification();
    const [editId, setEditId] = useState();
    const [color, setColor] = useState();
    const [openCreateFormCategoryDialog, setOpenCreateFormCategoryDialog] = useState(false);
    const [openEditFormCategoryDialog, setOpenEditFormCategoryDialog] = useState(false);
    const nameField = useTextField({
        initialValue: '',
        validate: (value) => validateFieldLength(value, 100)
    });
    const [createCategory, createCategoryResult] = useAddFormCategoryMutation();
    const [updateCategory, updateCategoryResult] = useUpdateFormCategoryMutation();
    const [deleteCategory] = useDeleteFormCategoryMutation();

    useEffect(() => {
        if (isError) {
            showErrorNotification(error?.message ?? '');
        }
    }, [isError, error]);

    useEffect(() => {
        const isSuccess = createCategoryResult.isSuccess || updateCategoryResult.isSuccess;
        const isError = createCategoryResult.isError || updateCategoryResult.isError;
        const error = createCategoryResult.error || updateCategoryResult.error;

        if (isSuccess) {
            showSuccessNotification('Form category saved');
        } else if (isError) {
            showDetailedErrorNotification('Failed to save form category', error.data.validationErrors);
        }
    }, [createCategoryResult, updateCategoryResult]);

    const handleChangeComplete = (color) => {
        setColor(color.hex);
    };

    const handleEdit = (params) => {
        nameField.setValue(params.name);
        setColor(params.color);
        setEditId(params.id);
        setOpenEditFormCategoryDialog(true);
    };

    const handleDelete = async (params) => await deleteCategory({formCategoryId: params.id});

    const handleDialogClose = () => {
        setOpenCreateFormCategoryDialog(false);
        setOpenEditFormCategoryDialog(false);
        nameField.reset();
        setColor(undefined);
        setEditId(undefined);
    };

    const handleSaveCategory = async () => {
        const func = openCreateFormCategoryDialog
            ? createCategory
            : openEditFormCategoryDialog
                ? updateCategory
                : (_) => {
                };

        await func({
            name: nameField.value,
            color,
            ...(openEditFormCategoryDialog ? {formCategoryId: editId} : {})
        });

        handleDialogClose();
    };

    return (
        <>
            <AdmicityTableV2
                columns={columns}
                data={data ?? []}
                isLoading={isLoading}
                isRefetching={isFetching}
                toolbarActions={[
                    {
                        type: 'button',
                        label: 'New category',
                        startIcon: <AddCircleOutlineIcon/>,
                        onClick: () => setOpenCreateFormCategoryDialog(true)
                    }
                ]}
                rowActions={[
                    {
                        label: 'Edit',
                        action: handleEdit
                    },
                    {
                        label: 'Delete',
                        action: handleDelete
                    }
                ]}
                noRowsOverlay={{
                    icon: <CategoryIcon/>,
                    text: 'No categories have been added'
                }}
                tableProps={{
                    enableSorting: false,
                    enableFilters: false,
                    enableColumnResizing: false,
                    enableBottomToolbar: false,
                }}
            />
            <AdmicityDialog
                handleClose={handleDialogClose}
                title={openCreateFormCategoryDialog ? 'Create new form category' : 'Update  form category'}
                open={openCreateFormCategoryDialog || openEditFormCategoryDialog}
                actions={[
                    {
                        label: 'Cancel', onClick: handleDialogClose
                    },
                    {
                        label: 'Save',
                        disabled: !color || !nameField.value,
                        onClick: handleSaveCategory
                    }
                ]}
            >
                <TextField
                    required
                    fullWidth
                    label="Category Name"
                    variant="outlined"
                    margin="normal"
                    {...nameField}
                />
                <FormControl
                    error={error}
                    fullWidth
                    margin="normal"
                    required
                >
                    <FormLabel>Pick color:</FormLabel>
                    <Box marginBottom={1} marginTop={1}>
                        <CirclePicker
                            color={color}
                            colors={colors}
                            onChangeComplete={handleChangeComplete}
                        />
                    </Box>
                </FormControl>
            </AdmicityDialog>
        </>
    );
};

export default FormCategories;