import React, {useEffect, useMemo, useRef, useState} from 'react';
import withPaginationV2 from '../../../../hoc/pagination/withPaginationV2';
import AdmicityTableV2 from '../../../../shared-components/Table/V2/AdmicityTableV2';
import {useGetSchoolUsersQuery} from '../../../../api/services/schoolManagementService';
import PersonOffRoundedIcon from '@mui/icons-material/PersonOffRounded';
import {ROLES, ROLES_LABELS} from '../../../../constants/roles';
import AdmicityDialog from '../../../../shared-components/AdmicityDialog';
import AdmicityForm from '../../../../shared-components/AdmicityForm';
import useUser from '../../../../utility/hooks/useUser';
import useNotification from '../../../../utility/hooks/useNotification';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import {validateRoles, validRolesCombinations} from '../../../../utility/validationUtil';
import Alert from '@mui/material/Alert';
import FILTER_OPERATIONS from '../../../../constants/filterOperations';
import {useConfirm} from 'material-ui-confirm';
import AdmicityStatus from '../../../../shared-components/AdmicityStatus';
import {
    useActivateUserMutation,
    useDeactivateUserMutation,
    useUpdateUserRoleMutation
} from '../../../../api/services/accountService';

const Table = withPaginationV2(AdmicityTableV2, {func: useGetSchoolUsersQuery});

const getStatusInfo = (isActive) => {
    if (isActive == null) {
        return {
            label: 'None',
            color: 'blueGrey'
        }
    }

    return {
        label: isActive ? 'Active' : 'Deactivated',
        color: isActive ? 'green' : 'red'
    };
};

/* eslint-disable react/prop-types */

const columns = [
    {
        accessorKey: 'firstName',
        header: 'First Name',
    },
    {
        accessorKey: 'lastName',
        header: 'Last Name',
    },
    {
        accessorKey: 'email',
        header: 'Email',
    },
    {
        accessorKey: 'roles',
        header: 'Roles',
        Cell: ({cell}) =>
            cell.getValue()
                ?.map(role => ROLES_LABELS[role] || '')
                .filter(roleName => roleName !== '')
                .join(', '),
        filterFn: FILTER_OPERATIONS.CONTAINS,
        filterVariant: 'select',
        filterSelectOptions: Object.entries(ROLES_LABELS)
            .filter(([key, _]) => key !== ROLES.SUPER_ADMIN && key !== ROLES.NONE)
            .map(([key, value]) => ({value: key, label: value}))
    },
    {
        accessorKey: 'isActive',
        header: 'Status',
        Cell: ({cell}) => <AdmicityStatus {...getStatusInfo(cell.getValue())}/>,
        filterFn: FILTER_OPERATIONS.EQUALS,
        filterVariant: 'select',
        filterSelectOptions: [
            {value: true, label: 'Active'},
            {value: false, label: 'Deactivated'},
        ]
    },
];

/* eslint-enable react/prop-types */

const SchoolUsers = () => {
    const [openEditUserDialog, setOpenEditUserDialog] = useState(false);
    const [userToEdit, setUserToEdit] = useState(undefined);
    const [isEditFormValid, setIsEditFormValid] = useState(false);
    const formRef = useRef(null);
    const [updateRole, updateRoleResult] = useUpdateUserRoleMutation();
    const {user} = useUser();
    const {showSuccessNotification, showDetailedErrorNotification, showErrorNotification} = useNotification();
    const confirm = useConfirm();
    const [deactivateUser, {
        isError: isDeactivateUserError,
        isSuccess: isDeactivateUserSuccess,
        error: deactivateUserError,
    }] = useDeactivateUserMutation();
    const [activateUser, {
        isError: isActivateUserError,
        isSuccess: isActivateUserSuccess,
        error: activateUserError,
    }] = useActivateUserMutation();

    useEffect(() => {
        const isSuccess = updateRoleResult.isSuccess;
        const isError = updateRoleResult.isError;
        const error = updateRoleResult.error;

        if (isSuccess) {
            showSuccessNotification('User role successfully updated');
        } else if (isError) {
            showDetailedErrorNotification('Failed to update user role', error.data.validationErrors);
        }
    }, [updateRoleResult]);

    useEffect(() => {
        if (isDeactivateUserSuccess) {
            showSuccessNotification('User deactivated successfully');
        }
        if (isDeactivateUserError) {
            showErrorNotification('Error deactivating user');
        }
    }, [isDeactivateUserSuccess, isDeactivateUserError, deactivateUserError]);

    useEffect(() => {
        if (isActivateUserSuccess) {
            showSuccessNotification('User activated successfully');
        }
        if (isDeactivateUserError) {
            showErrorNotification('Error activating user');
        }
    }, [isActivateUserSuccess, isActivateUserError, activateUserError]);

    const handleDialogClose = () => {
        setOpenEditUserDialog(false);
        setUserToEdit(undefined);
        setIsEditFormValid(false);
    };

    const handleDialogOpen = () => setOpenEditUserDialog(true);

    const textFields = useMemo(() => [
        {
            label: 'Roles',
            name: 'roles',
            select: true,
            inputType: 'select',
            hint: <>
                Valid combinations:
                <br/>
                {validRolesCombinations.map((combination, index) => (
                    <span key={index}>• {[...combination].map(x => ROLES_LABELS[x] || x).join(', ')}<br/></span>
                ))}
            </>,
            initialValue: userToEdit?.roles ?? [],
            options: Object.entries(ROLES_LABELS)
                .filter(([key, _]) => key !== ROLES.SUPER_ADMIN && key !== ROLES.NONE)
                .map(([key, label]) => ({
                    value: key,
                    title: label
                })),
            SelectProps: {
                multiple: true,
                renderValue: (selected) => {
                    return (
                        <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} variant="outlined"/>
                            ))}
                        </Box>
                    );
                },
            },
            validate: (roles) => {
                const result = validateRoles(roles);

                setIsEditFormValid(() => result.isValid);

                return result
            }
        },
    ], [userToEdit?.roles]);

    const handleUpdateRole = async data => {
        if (userToEdit.roles?.length > 0) {
            await updateRole({
                userId: userToEdit.id,
                roles: data.roles,
            });
        }

        handleDialogClose();
    };

    const handleConfirmUserDeactivation = user =>
        confirm({
            title: 'Confirm Deactivation',
            confirmationText: 'Yes, Deactivate',
            description: `Are you sure you want to deactivate ${user.firstName} ${user.lastName}? They will lose access to their account.`,
        })
            .then(async () => {
                await deactivateUser({userId: user.id});
            })
            .catch(() => {
            });

    const handleActivateUser = async user => await activateUser({userId: user.id})

    return (
        <>
            <Table
                columns={columns}
                tableProps={{
                    enableSorting: false,
                    enableColumnResizing: false,
                    enableHiding: false,
                    muiTableBodyRowProps: ({row}) => row.original.id === user.id
                        ? {
                            sx: {
                                '& .MuiTableCell-root': {
                                    fontWeight: 'bold',
                                    cursor: 'pointer'
                                },

                            }
                        }
                        : {}
                }}
                rowActions={[
                    {
                        label: 'Edit roles',
                        action: (row) => {
                            setUserToEdit({
                                id: row.id,
                                roles: row.roles
                            });
                            setIsEditFormValid(validateRoles(row.roles));
                            handleDialogOpen();
                        },
                        disabled: (row) => row.id === user.id || !row.isActive
                    },
                    {
                        label: 'Activate User',
                        action: handleActivateUser,
                        disabled: (row) => row.id === user.id || row.isActive
                    },
                    {
                        label: 'Deactivate User',
                        action: handleConfirmUserDeactivation,
                        disabled: (row) => row.id === user.id || !row.isActive
                    }
                ]}
                noRowsOverlay={{
                    icon: <PersonOffRoundedIcon/>,
                    text: 'No users have been added'
                }}
            />
            <AdmicityDialog
                handleClose={handleDialogClose}
                title={'Edit roles'}
                open={openEditUserDialog}
                actions={[
                    {label: 'Cancel', onClick: handleDialogClose},
                    {
                        label: 'Save',
                        disabled: updateRoleResult.isLoading || !isEditFormValid,
                        onClick: () => {
                            if (typeof formRef.current.requestSubmit === 'function') {
                                formRef.current.requestSubmit();
                            } else {
                                formRef.current.dispatchEvent(new Event('submit', {cancelable: true}));
                            }
                        }
                    }
                ]}
            >
                <Alert severity="info" sx={{marginBottom: 2}}>
                    Role changes will take effect after the affected user logs out and logs back into the system.
                </Alert>
                <AdmicityForm
                    ref={formRef}
                    textFields={textFields}
                    buttonText="Save"
                    handleSubmit={handleUpdateRole}
                    actionsContainerProps={{
                        container: {
                            display: 'none'
                        }
                    }}
                />
            </AdmicityDialog>
        </>
    );
};

export default SchoolUsers;