import axios from 'axios';
import {refresh} from './AppLayout/ApplicationSlice';
import {clearCookies} from './utility/jwtUtil';

axios.defaults.withCredentials = true;

const api = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

let isRefreshing = false;
let failedRequestsQueue = [];
let store

export const injectStore = _store => {
    store = _store
}

const processQueue = (error, token = null) => {
    failedRequestsQueue.forEach(promise => {
        if (error) {
            promise.reject(error);
        } else {
            promise.resolve(token);
        }
    });

    failedRequestsQueue = [];
};

api.interceptors.response.use(
    response => response,
    async error => {
        const {response} = error;
        const originalRequest = error.config;

        if (!response) {
            return Promise.reject(error);
        }

        if (error.response.status === 401 &&
            !originalRequest._retry &&
            !originalRequest.url.endsWith('/account/refresh')
        ) {
            originalRequest._retry = true;

            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    failedRequestsQueue.push({ resolve, reject });
                })
                    .then(() => api(originalRequest))
                    .catch((err) => Promise.reject(err));
            }

            isRefreshing = true;

            try {
                await store.dispatch(refresh()).unwrap();
                processQueue(null);

                return api(originalRequest);
            } catch (refreshError) {
                isRefreshing = false;

                processQueue(refreshError);
                cleanUpAuthState();

                return Promise.reject(refreshError);
            } finally {
                isRefreshing = false;
            }
        }

        if (error.response.status === 403) {
            window.location.href = '/';
        }

        return Promise.reject(error);
    }
);

function cleanUpAuthState() {
    clearCookies()

    if (window.location.pathname !== '/login') {
        window.location.href = '/login';
    }
}

export default api;