import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import {useSelector} from 'react-redux';
import { useTheme } from '@mui/material/styles';
import {Box} from '@mui/material';

const replaceIfNotNull = (originalText, placeholder, value) => {
    if (value !== null && value !== undefined) {
        const regex = new RegExp(placeholder, 'g');
        return originalText.replace(regex, value);
    }
    return originalText;
};

const RichEditorResultText = ({id, description, placeholderValues, enableValidation}) => {
    const {account} = useSelector(state => state.application);
    const [text, setText] = useState('');
    const theme = useTheme();

    useEffect(() => {
        let resultText = description;

        if (placeholderValues) {
            resultText = replaceIfNotNull(resultText, '@Parent', placeholderValues.parent);
            resultText = replaceIfNotNull(resultText, '@Title', placeholderValues.title || '');
            resultText = replaceIfNotNull(resultText, '@SchoolName', placeholderValues.schoolName);
            resultText = replaceIfNotNull(resultText, '@StudentForename', placeholderValues.studentForename);
            resultText = replaceIfNotNull(resultText, '@StudentFullName', placeholderValues.studentFullName);
            resultText = replaceIfNotNull(resultText, '@HisHer', placeholderValues.hisHer);
            resultText = replaceIfNotNull(resultText, '@HeShe', placeholderValues.heShe);
            resultText = replaceIfNotNull(resultText, '@HimHer', placeholderValues.himHer);
            resultText = replaceIfNotNull(resultText, '@Employee', placeholderValues.employee);
        }

        setText(DOMPurify.sanitize(resultText));
    }, [id, account, placeholderValues]);
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                paddingBottom: '12px',
            }}
        >
            <Box
                sx={{
                    ...theme.typography.body1,
                    color: theme.palette.text.primary,
                }}
            >
                <span dangerouslySetInnerHTML={{__html: text}}/>
                {enableValidation &&
                <span
                    style={{
                        alignSelf: 'flex-start',
                        marginLeft: 5
                    }}
                >*</span>
                }
            </Box>
        </div>

    );
};

RichEditorResultText.propTypes = {
    id: PropTypes.string,
    description: PropTypes.string.isRequired,
    readonly: PropTypes.bool.isRequired,
    placeholderValues: PropTypes.object.isRequired,
    enableValidation: PropTypes.bool.isRequired,
};

export default RichEditorResultText;