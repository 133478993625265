import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Outlet} from 'react-router-dom';
import {resetProfileFilters, setFilterId} from './ProfilesSlice';
import {resetTableState} from '../../../store/tableSlice';

const ProfileTab = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(setFilterId(undefined));
            dispatch(resetProfileFilters());
            dispatch(resetTableState('profiles'));
        };
    }, []);

    useEffect(() => {
        return () => {

        };
    }, [dispatch]);

    return <Outlet/>;
};

export default ProfileTab;
