import React, {useEffect, useMemo} from 'react';
import {useGetContactInvitesQuery, useResendInvitesMutation} from '../../../api/services/schoolManagementService';
import INVITE_STATUSES from '../../../constants/inviteStatuses';
import {formatDate} from '../../../utility/dateUtil';
import SendIcon from '@mui/icons-material/Send';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import {downloadContactInvitesCsvReport, downloadContactInvitesXlsxReport} from '../../../api/services/filesService';
import useFileDownloader from '../../../utility/hooks/useFileDownloader';
import AdmicityStatus from '../../../shared-components/AdmicityStatus';
import {CONTACT_INVITE_STATUSES} from '../../../constants/ContactInviteStatuses';
import withPaginationV2 from '../../../hoc/pagination/withPaginationV2';
import AdmicityTableV2 from '../../../shared-components/Table/V2/AdmicityTableV2';
import DateRangePicker from '../../../shared-components/Table/V2/components/columnFilters/DateRangePicker';
import FILTER_OPERATIONS from '../../../constants/filterOperations';
import moment from 'moment/moment';
import {resetTableState} from '../../../store/tableSlice';
import {useDispatch} from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';

const quickFilterOptions = [
    {value: 0, label: 'All'},
    {value: 1, label: 'Applicants'},
    {value: 2, label: 'Students'}
];

const getInviteStatusInfo = (status) => ({
    label: status,
    color: {
        [CONTACT_INVITE_STATUSES.sent]: 'blueGrey',
        [CONTACT_INVITE_STATUSES.completed]: 'green',
        [CONTACT_INVITE_STATUSES.expired]: 'red',
        [CONTACT_INVITE_STATUSES.failed]: 'red'
    }[status] ?? 'grey'
});

const defaultSorting = {id: 'contactInviteCreatedAt', desc: false};

export const ENABLE_FILTERS = 'ENABLE_FILTERS';

const TableV2 = withPaginationV2(AdmicityTableV2, {func: useGetContactInvitesQuery});

/* eslint-disable react/prop-types */
const columns = [
    {
        accessorKey: 'studentName',
        header: 'Student Name',
        operation: FILTER_OPERATIONS.CONTAINS,
    },
    {
        accessorKey: 'contactName',
        header: 'Contact Name',
        operation: FILTER_OPERATIONS.CONTAINS,
    },
    {
        accessorKey: 'contactEmail',
        header: 'Contact Email',
        operation: FILTER_OPERATIONS.CONTAINS,
        size: 280
    },
    {
        accessorKey: 'contactInviteCreatedAt',
        header: 'Invite Date',
        Filter: DateRangePicker,
        filterFn: FILTER_OPERATIONS.BETWEEN_INCLUSIVE,
        Cell: ({cell}) => cell.getValue() ? formatDate(cell.getValue()) : ''
    },
    {
        accessorKey: 'contactRegistrationDate',
        header: 'Registration Date',
        Filter: DateRangePicker,
        filterFn: FILTER_OPERATIONS.BETWEEN_INCLUSIVE,
        Cell: ({cell}) => cell.getValue() ? formatDate(cell.getValue()) : ''
    },
    {
        accessorKey: 'contactInviteStatus',
        header: 'Status',
        filterFn: FILTER_OPERATIONS.EQUALS,
        filterVariant: 'select',
        filterSelectOptions: INVITE_STATUSES.map(element => ({
            value: element === 'Empty' ? null : element,
            label: element
        })),
        Cell: ({cell}) => cell.getValue() && <AdmicityStatus {...getInviteStatusInfo(cell.getValue())}/>,
    }
];
/* eslint-enable react/prop-types */

const TABLE_ID = 'contactInvites';

const ContactInvites = () => {
    const [resendInvites, {isLoading: isInvitesResending}] = useResendInvitesMutation();
    const downloadFile = useFileDownloader();
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(resetTableState(TABLE_ID));
        };
    }, [dispatch]);

    const generateCsv = async (e, state) => {
        const filters = state.columnFilters.map(x => ({
            propertyName: x.id,
            value: moment.isMoment(x.value) ? x.value.format('YYYY-MM-DDTHH:mm:ss') : x.value,
            operation: state.columnFilterFns[x.id]
        }));

        await downloadFile(
            downloadContactInvitesCsvReport,
            {
                filters,
                ...state.quickFilters,
                sorting: state.sorting?.[0] ?? defaultSorting
            });
    };

    const generateXlsx = async (e, state) => {
        const filters = state.columnFilters.map(x => ({
            propertyName: x.id,
            value: moment.isMoment(x.value) ? x.value.format('YYYY-MM-DDTHH:mm:ss') : x.value,
            operation: state.columnFilterFns[x.id]
        }));

        await downloadFile(
            downloadContactInvitesXlsxReport,
            {
                filters,
                ...state.quickFilters,
                sorting: state.sorting?.[0] ?? defaultSorting
            });
    };

    const toolbarActions = useMemo(() => [
        {
            type: 'quickFilterSelect',
            accessorKey: 'profileFilter',
            label: 'StaffProfile type',
            variant: 'small',
            options: quickFilterOptions,
            defaultValue: 0
        },
        {
            type: 'button',
            label: 'Resend invites',
            endIcon: isInvitesResending ? <CircularProgress color="inherit" size={18}/> : <SendIcon/>,
            renderOnRowSelect: true,
            onClick: (rows) => resendInvites([...new Set(rows.map(x => x.contactId))]),
            ...(isInvitesResending ? {disabled: true} : {})
        }
    ], [isInvitesResending]);

    return (
        <TableV2
            id={TABLE_ID}
            initialState={{
                columnFilters: [],
                sorting: [defaultSorting],
                grouping: ['id'],
                density: 'compact'
            }}
            columns={columns}
            tableProps={{
                enableRowSelection: (row) => row.original.contactId != null,
                enableRowActions: true,
            }}
            toolbarActions={toolbarActions}
            toolbarIconActions={
                [{
                    icon: <FileDownloadRoundedIcon/>,
                    title: 'Download report',
                    type: 'select',
                    hideIfEmpty: true,
                    menuItems: [
                        {
                            label: 'Download XLSX',
                            onClick: generateXlsx
                        },
                        {
                            label: 'Download CSV',
                            onClick: generateCsv
                        },
                    ]
                }]
            }
        />
    );
};

export default ContactInvites;