import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PropTypes from 'prop-types';
import RichEditor from './Shared/RichEditor';
import RichEditorResultText from './Shared/RichEditorResultText';
import { InlineWidget } from 'react-calendly';

export const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
};

const AppointmentBooking = ({
    index,
    id,
    description,
    bookingLink = '',
    onChange,
    onUpdateDescription,
    isEditMode,
    isFormArchived,
    placeholderValues,
    readOnly,
    accessLevel,
    componentToEditIndex,
}) => {
    const [currentValue, setCurrentValue] = useState(description);
    const [errorText, setErrorText] = useState('');
    const [iframeSrc, setIframeSrc] = useState(null);
    const prevPropRef = useRef();
    const CALENDLY_REGEX = /^https:\/\/calendly\.com\/[\w-]+/;
    const GOOGLE_SCHEDULER_REGEX = /^https:\/\/calendar\.google\.com\/calendar\/.+$/;

    useEffect(() => {
        setCurrentValue(description);
    }, [description]);

    useEffect(() => {
        if (prevPropRef.current === index && componentToEditIndex !== prevPropRef.current) {
            onUpdateDescription({
                index,
                description: stripHtmlTags(currentValue),
                formattedDescription: currentValue,
            });
        }
        prevPropRef.current = componentToEditIndex;
    }, [componentToEditIndex, currentValue, index, onUpdateDescription]);

    const isValidBookingLink = (url) => {
        const trimmedUrl = url.trim();
        return CALENDLY_REGEX.test(trimmedUrl) || GOOGLE_SCHEDULER_REGEX.test(trimmedUrl);
    };

    const isCalendlyLink = (url) => CALENDLY_REGEX.test(url.trim());

    const validateUrl = (url) => {
        const trimmedUrl = url.trim();
        if (!trimmedUrl) {
            setErrorText('');
            return false;
        }

        if (!trimmedUrl.startsWith('https://')) {
            setErrorText('URL must start with https://');
            return false;
        }

        if (!isValidBookingLink(trimmedUrl)) {
            setErrorText('Only full Google Calendar scheduler links (e.g. https://calendar.google.com/...) or Calendly links are accepted.');
            return false;
        }

        setErrorText('');
        return true;
    };

    const handleUrlChange = (e) => {
        const newUrl = e.target.value;
        if (validateUrl(newUrl)) {
            onUpdateDescription({
                index,
                bookingLink: newUrl
            });
        } else {
            onUpdateDescription({
                index,
                bookingLink: newUrl
            });
            if (!newUrl) {
                setIframeSrc(null);
            }
        }
    };

    const openIframe = () => {
        if (!errorText && bookingLink) {
            setIframeSrc(bookingLink);
        }
    };

    return (
        <Box>
            {isEditMode
                ? (
                    <RichEditor
                        value={description}
                        onChange={onChange}
                        id={id}
                        index={index}
                        isEditMode={isEditMode}
                        isFormLive={isFormArchived}
                        accessLevel={accessLevel}
                        componentToEditIndex={componentToEditIndex}
                        currentDescription={currentValue}
                        setCurrentDescription={setCurrentValue}
                        sx={{ mb: 2 }}
                    />
                )
                : (
                    <RichEditorResultText
                        id={id}
                        description={description}
                        placeholderValues={placeholderValues}
                        readonly={readOnly}
                        enableValidation={false}
                        sx={{ mb: 2 }}
                    />
                )}

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                {isEditMode && (
                    <TextField
                        placeholder="Enter calendar booking link here"
                        value={bookingLink}
                        onChange={handleUrlChange}
                        error={!!errorText}
                        helperText={errorText}
                        sx={{ flexGrow: 1, minWidth: '200px', pr: 1, py: 1 }}
                    />
                )}
                <Button
                    onClick={openIframe}
                    startIcon={<CalendarMonthIcon />}
                    variant="outlined"
                    disabled={!!errorText || !bookingLink || readOnly}
                    sx={{ minWidth: '200px', whiteSpace: 'nowrap',}}
                >
          Book Appointment
                </Button>
            </Box>

            {
                iframeSrc
                    ? isCalendlyLink
                        ? (
                            <Box sx={{ mt: 2, border: '1px solid #ccc', borderRadius: '8px', overflow: 'hidden' }}>
                                <InlineWidget
                                    url={`${iframeSrc}?hide_gdpr_banner=1`}
                                    styles={{ minWidth: '320px', height: '700px' }}
                                />
                            </Box>
                        )
                        : (
                            <Box sx={{ mt: 2, border: '1px solid #ccc', borderRadius: '8px', overflow: 'hidden' }}>
                                <iframe
                                    src={iframeSrc}
                                    width="100%"
                                    height="auto"
                                    style={{ border: 'none', minHeight: '700px' }}
                                    title="Appointment Booking"
                                    sandbox="allow-scripts allow-same-origin allow-forms"
                                />
                            </Box>
                        )
                    : null
            }
        </Box>
    );
};

AppointmentBooking.propTypes = {
    id: PropTypes.string,
    description: PropTypes.string,
    bookingLink: PropTypes.string,
    index: PropTypes.number,
    onChange: PropTypes.func,
    onUpdateDescription: PropTypes.func,
    isEditMode: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    readOnly: PropTypes.bool,
    accessLevel: PropTypes.number,
    placeholderValues: PropTypes.object,
    componentToEditIndex: PropTypes.number,
};

export default AppointmentBooking;
