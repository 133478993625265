import EmployeeProfile from './EmployeeProfile';
import {useNavigate, useParams} from 'react-router-dom';
import {useGetEmployeeByIdQuery} from '../../../api/services/employeeService';
import {Box, Button} from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import React from 'react';

const AdminEmployeeInformation = () => {
    const {id} = useParams();
    const {data = [], isLoading} = useGetEmployeeByIdQuery(id);
    const navigate = useNavigate();

    const navigateBack = () => navigate('/profiles');

    return <Box>
        <Box marginBottom={1}>
            <Button
                variant="text"
                startIcon={<ArrowBackRoundedIcon/>}
                onClick={navigateBack}
            >
                Back
            </Button>
        </Box>
        <EmployeeProfile data={data} isLoading={isLoading}/>
    </Box>;
};

export default AdminEmployeeInformation;