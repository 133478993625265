import React, {useEffect, useMemo, useState} from 'react';
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AdmicityDialog from '../../../shared-components/AdmicityDialog';
import useTextField from '../../../utility/hooks/useTextField';
import {ROLE_IDS, ROLES, ROLES_LABELS} from '../../../constants/roles';
import {validateEmail} from '../../../utility/validationUtil';
import PersonOffRoundedIcon from '@mui/icons-material/PersonOffRounded';
import {formatDate} from '../../../utility/dateUtil';
import AdmicityStatus from '../../../shared-components/AdmicityStatus';
import {INVITE_STATUSES, INVITE_STATUSES_NAMES} from '../../../constants/registrationInviteStatuses';
import {
    useGetRegistrationInvitesQuery,
    useSendRegistrationInviteMutation
} from '../../../api/services/accountService';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import useUser from '../../../utility/hooks/useUser';
import withPaginationV2 from '../../../hoc/pagination/withPaginationV2';
import AdmicityTableV2 from '../../../shared-components/Table/V2/AdmicityTableV2';

const getInviteStatusInfo = (status) => ({
    label: INVITE_STATUSES_NAMES[status] ?? 'unknown status',
    color: {
        [INVITE_STATUSES.pending]: 'blueGrey',
        [INVITE_STATUSES.completed]: 'green',
        [INVITE_STATUSES.expired]: 'red',
        [INVITE_STATUSES.failed]: 'red'
    }[status] ?? 'grey'
});

/* eslint-disable react/prop-types */

const columns = [
    {
        accessorKey: 'email',
        header: 'Email',
    },
    {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({cell}) => cell.getValue() && <AdmicityStatus {...getInviteStatusInfo(cell.getValue())}/>,
    },
    {
        accessorKey: 'expires',
        header: 'Expires At',
        Cell: ({cell}) => cell.getValue() ? formatDate(cell.getValue()) : ''
    },
    {
        accessorKey: 'completedDate',
        header: 'Completed At',
        Cell: ({cell}) => cell.getValue() ? formatDate(cell.getValue()) : ''
    }
];

/* eslint-enable react/prop-types */

const SchoolMembersSetup = ({schoolInfo}) => {
    const {user} = useUser();
    const [openDialog, setOpenDialog] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState(null);
    const [role, setRole] = useState('');
    const emailField = useTextField({
        initialValue: '',
        validate: validateEmail
    });
    const [sendRegistrationInvite, {
        isError: isDeleteFormAssignmentError,
        isSuccess: isDeleteFormAssignmentSuccess,
        error: sendInviteErrors,
    }] = useSendRegistrationInviteMutation();

    const getRoleOptions = () => {
        if (user.hasRole(ROLES.SUPER_ADMIN)) {
            return [ROLES.SCHOOL_ADMIN];
        }

        if (user.hasRole(ROLES.SCHOOL_ADMIN)) {
            return [ROLES.MEMBER_OF_STAFF, ROLES.SCHOOL_ADMIN, ROLES.EMPLOYEE, ROLES.SENIOR_MEMBER_OF_STAFF];
        }

        return []
    };

    const Table = useMemo(() => withPaginationV2(AdmicityTableV2, {
        func: useGetRegistrationInvitesQuery,
        props: {schoolId: schoolInfo.id}
    }), [schoolInfo.id]);

    useEffect(() => {
        if (isDeleteFormAssignmentError || isDeleteFormAssignmentSuccess) {
            if (isDeleteFormAssignmentError) {
                setIsSubmitted(false);
            }
            if (isDeleteFormAssignmentSuccess) {
                handleDialogClose();
            }
        }
    }, [isDeleteFormAssignmentSuccess, isDeleteFormAssignmentError]);

    useEffect(() => {
        if (sendInviteErrors) {
            setError(sendInviteErrors);
        }
    }, [sendInviteErrors]);

    const sendInvite = async () => {
        setIsSubmitted(true);

        await sendRegistrationInvite({
            email: emailField.value,
            role: ROLE_IDS[role],
            schoolId: schoolInfo.id
        });
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setIsSubmitted(false);
        setError(null);
        setRole('');
        emailField.reset();
    };

    const isFormValid = (!emailField.error && emailField.value !== '') && role !== '';

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                minHeight: '100%'
            }}
        >
            <div>
                <Button
                    variant="contained"
                    sx={{mb: 2, mt: 2}}
                    startIcon={<AddCircleOutlineIcon/>}
                    onClick={() => setOpenDialog(true)}
                >
                    Add user
                </Button>
            </div>
            <Table
                columns={columns}
                tableProps={{
                    enableSorting: false,
                    enableColumnResizing: false,
                    enableTopToolbar: false,
                }}
                noRowsOverlay={{
                    icon: <PersonOffRoundedIcon/>,
                    text: 'No invites have been added'
                }}
            />
            <AdmicityDialog
                handleClose={handleDialogClose}
                title={'Add user'}
                open={openDialog}
                actions={[
                    {label: 'Cancel', onClick: handleDialogClose},
                    {
                        label: 'Send invite',
                        onClick: sendInvite,
                        disabled: isSubmitted || !isFormValid,
                        startIcon: isSubmitted ? <CircularProgress color="inherit" size={16}/> : undefined
                    }
                ]}
            >
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    label="Email"
                    type="email"
                    fullWidth
                    variant="standard"
                    {...emailField}
                />
                <FormControl
                    required
                    fullWidth
                    variant="standard"
                    margin="dense"
                >
                    <InputLabel id="role-select">Role</InputLabel>
                    <Select
                        labelId="role-select"
                        value={role}
                        onChange={(e) => setRole(e.target.value)}
                        label="Role"
                    >
                        {
                            getRoleOptions().map(value => (
                                <MenuItem key={value} value={value}>{ROLES_LABELS[value]}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                {
                    error && error.length !== 0
                        ? <Alert
                            sx={{marginTop: 2}}
                            severity="error"
                        >
                            <AlertTitle>Error</AlertTitle>
                            {
                                error.length === 1
                                    ? <Typography>{error[0]}</Typography>
                                    : <ul style={{paddingLeft: 0, listStylePosition: 'inside', margin: 0}}>
                                        {
                                            error.map((error, index) => (
                                                <li key={index}>{error}</li>
                                            ))
                                        }
                                    </ul>
                            }
                        </Alert>
                        : ''
                }
            </AdmicityDialog>
        </Box>
    );
};

SchoolMembersSetup.propTypes = {
    schoolInfo: PropTypes.object
};

export default SchoolMembersSetup;