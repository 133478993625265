import {
    useGetConsentLookupsQuery,
    useGetMedicalConsentOptionsQuery,
    useManageMedicalConsentsMutation
} from '../../../../api/services/lookupsService';
import {Box, Button} from '@mui/material';
import Typography from '@mui/material/Typography';
import React, {useEffect, useRef, useState} from 'react';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AdmicityDialog from '../../../../shared-components/AdmicityDialog';
import AdmicityForm from '../../../../shared-components/AdmicityForm';
import EditIcon from '@mui/icons-material/Edit';
import useNotification from '../../../../utility/hooks/useNotification';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import Stack from '@mui/material/Stack';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import Uploader from '../../../SchoolManagement/Setup/Uploader';
import {
    downloadConsentFile,
    useDeleteConsentFileMutation,
    useUploadConsentFileMutation
} from '../../../../api/services/filesService';
import objectToFormData from '../../../../utility/objectToFormData';
import useFileDownloader from '../../../../utility/hooks/useFileDownloader';
import {useConfirm} from 'material-ui-confirm';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import {
    useGetConsentDescriptionQuery,
    useUpdateConsentDescriptionMutation
} from '../../../../api/services/schoolManagementService';

const Lookups = () => {
    const {data: medicalConsentOptions = {options: []},} = useGetMedicalConsentOptionsQuery();
    const {data: consents = [],} = useGetConsentLookupsQuery();

    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={{
                width: {
                    xs: '100%',
                    sm: '75%',
                    md: '50%',
                    lg: '50%',
                },
            }}
            gap={2}
        >
            <ConsentsSettings consents={consents}/>
            <Divider/>
            <MedicalConsentSettings medicalConsentOptions={medicalConsentOptions}/>
        </Box>
    );
};

function ConsentsSettings({consents}) {
    const maxDescriptionChars = 500;
    const [openUploadDialog, setOpenUploadDialog] = useState(false);
    const [selectedConsent, setSelectedConsent] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isUploadSizeExceedsLimit, setIsUploadSizeExceedsLimit] = useState(false);
    const [uploadFile, {isLoading: isFileUploading, isError: isUploadError}] = useUploadConsentFileMutation();
    const [deleteFile, {isError: isDeleteError}] = useDeleteConsentFileMutation();
    const {
        data: consentDescriptionData = {description: ''},
        isError: isLoadingConsentDescriptionFailed
    } = useGetConsentDescriptionQuery();
    const [updateConsentDescription, {isError: isUpdateConsentDescriptionError}] = useUpdateConsentDescriptionMutation();
    const downloadFile = useFileDownloader();
    const {showErrorNotification} = useNotification();
    const confirm = useConfirm();
    const [consentDescription, setConsentDescription] = useState('');

    useEffect(() => {
        if (isUploadError) {
            showErrorNotification('Failed to upload consent document');
        }
        if (isDeleteError) {
            showErrorNotification('Failed to delete consent document');
        }
        if (isUpdateConsentDescriptionError) {
            showErrorNotification('Failed to update general consent description');
        }
    }, [isUploadError, isDeleteError, isUpdateConsentDescriptionError]);

    useEffect(() => {
        if (isLoadingConsentDescriptionFailed) {
            showErrorNotification('Failed to load general consent description');
        }

        if (consentDescriptionData.description) {
            setConsentDescription(consentDescriptionData.description);
        }

    }, [consentDescriptionData.description, isLoadingConsentDescriptionFailed]);

    const handleDownloadFile = async (consentCode) =>
        await downloadFile(
            downloadConsentFile,
            consentCode);

    const closeUploadDialog = () => {
        setOpenUploadDialog(false);
        setSelectedFiles([]);
        setIsUploadSizeExceedsLimit(false);
        setSelectedConsent(null);
    };

    const handleFileUpload = async () => {
        if (selectedFiles.length === 0) {
            return;
        }

        await uploadFile(objectToFormData({
            file: selectedFiles[0],
            consentCode: selectedConsent.code
        }));
        closeUploadDialog();
    };

    const handleFileDelete = async consentCode =>
        confirm({
            title: 'Confirm Deletion',
            description: 'Are you sure you want to delete the consent document? Once deleted, it cannot be recovered.',
            confirmationText: 'Delete'
        })
            .then(async () => await deleteFile({consentCode}))
            .catch(_ => {
            });

    const handleConsentDescriptionChange = (event) => {
        if (event.target.value.length <= maxDescriptionChars) {
            setConsentDescription(event.target.value);
        }
    };

    const handleConsentDescriptionSave = async () => {
        await updateConsentDescription({value: consentDescription});
    };

    return (
        <Box>
            <Typography variant="h6" fontWeight="bold" gutterBottom>Parental Consents</Typography>
            <TextField
                label="General description"
                multiline
                rows={4}
                value={consentDescription}
                onChange={handleConsentDescriptionChange}
                helperText={`${consentDescription.length} / ${maxDescriptionChars} characters`}
                variant="outlined"
                fullWidth
                InputLabelProps={{shrink: true}}
            />
            {consentDescriptionData.description !== consentDescription && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleConsentDescriptionSave}
                    sx={{marginTop: 2}}
                >
                    Save description
                </Button>
            )}
            <List sx={{width: '100%'}}>
                {
                    [...consents]
                        ?.sort((a, b) => a.description.localeCompare(b.description))
                        .map((consent, index) => (
                            <ListItem key={`consent-${index}`} disablePadding>
                                {
                                    consent.file != null
                                        ? <ListItemText
                                            primary={consent.description}
                                            secondary={
                                                <Stack
                                                    flexDirection="row"
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                >
                                                    <Stack flexDirection="row" alignItems="center" gap={1}>
                                                        <InsertDriveFileRoundedIcon
                                                            fontSize="small"
                                                            sx={{color: 'primary.main'}}
                                                        />
                                                        <Typography variant="body2">{consent.file.name}</Typography>
                                                    </Stack>
                                                    <Stack flexDirection="row">
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => handleDownloadFile(consent.code)}
                                                        >
                                                            <DownloadRoundedIcon fontSize="small"/>
                                                        </IconButton>
                                                        <IconButton
                                                            size="small"
                                                            sx={{
                                                                ':hover': {
                                                                    color: 'error.light'
                                                                }
                                                            }}
                                                            onClick={() => handleFileDelete(consent.code)}
                                                        >
                                                            <DeleteOutlineRoundedIcon fontSize="small"/>
                                                        </IconButton>
                                                    </Stack>
                                                </Stack>
                                            }/>
                                        : <ListItemText
                                            primary={consent.description}
                                            secondary={
                                                <Link
                                                    component="button"
                                                    onClick={() => {
                                                        setSelectedConsent(consent);
                                                        setOpenUploadDialog(true);
                                                    }}
                                                >Upload document</Link>
                                            }/>
                                }
                            </ListItem>
                        ))
                }
            </List>
            <AdmicityDialog
                handleClose={closeUploadDialog}
                title={'Add Documents'}
                open={openUploadDialog}
                actions={[
                    {
                        label: 'Cancel',
                        onClick: closeUploadDialog
                    },
                    {
                        label: 'Upload',
                        onClick: handleFileUpload,
                        startIcon: isFileUploading ? <CircularProgress color="inherit" size={16}/> : undefined,
                        disabled: selectedFiles?.length === 0 || isUploadSizeExceedsLimit || isFileUploading,
                    }
                ]}
                maxWidth={'md'}
            >
                <Uploader
                    totalUploadSizeInMb={25}
                    onFilesSelect={setSelectedFiles}
                    onUploadLimitExceed={setIsUploadSizeExceedsLimit}
                    singleFile
                    value={selectedFiles}
                />
            </AdmicityDialog>
        </Box>
    );
}

ConsentsSettings.propTypes = {
    consents: PropTypes.array
};

function MedicalConsentSettings({medicalConsentOptions}) {
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const formRef = useRef(null);
    const [selectedConsent, setSelectedConsent] = useState({});
    const [activeConsents, setActiveConsents] = useState([]);
    const {showErrorNotification, showSuccessNotification} = useNotification();

    const [manageConsents, {
        isSuccess,
        isError,
    }] = useManageMedicalConsentsMutation();

    useEffect(() => {
        setActiveConsents(medicalConsentOptions?.options);
    }, [medicalConsentOptions]);

    useEffect(() => {
        if (isError) {
            showErrorNotification('Failed to update medical consents');
        } else if (isSuccess) {
            showSuccessNotification('Medical consents updated successfully');
        }
    }, [isSuccess, isError]);

    const textFields = [{
        label: 'Description',
        name: 'description',
        required: true,
        initialValue: selectedConsent?.description ?? undefined,
        validate: (value) => {
            return {
                isValid: value && value.length <= 100,
                message: getValidationMessage(value)
            };
        }
    }];

    const getValidationMessage = (value) => {
        if (value.length > 0 && value.length <= 100) {
            return '';
        }

        return value.length === 0
            ? 'Please provide a description.'
            : 'Description cannot exceed 100 characters.';
    };

    const deleteConsent = (description) => {
        setActiveConsents(activeConsents.filter(e => e.description !== description));
    };

    const handleAdd = async (payload) => {
        setActiveConsents([...activeConsents, {description: payload.description, id: null}]);
        handleFinishEditing();
    };

    const startEditing = (consent) => {
        setSelectedConsent(consent);
        setOpenEditDialog(true);
    };

    const handleEdit = async (payload) => {
        const newConsents = activeConsents.map(consent => {
            if (consent.description === selectedConsent.description) {
                return {
                    ...consent,
                    description: payload.description
                };
            }
            return consent;
        });

        setActiveConsents(newConsents);
        handleFinishEditing();
    };

    const handleFinishEditing = () => {
        setOpenAddDialog(false);
        setOpenEditDialog(false);
        setSelectedConsent(undefined);
    };

    return (
        <Box>
            <Typography variant="h6" fontWeight="bold">Medical Consents</Typography>
            <Box paddingTop={2}>
                <Button
                    variant="contained"
                    startIcon={<AddCircleOutlineIcon/>}
                    onClick={() => setOpenAddDialog(true)}
                >
                    Add new
                </Button>
            </Box>
            <List sx={{width: '100%'}}>
                {
                    activeConsents.map((entity, index) =>
                        <ListItem
                            key={`medicalConsent-${index}`}
                            disablePadding
                        >
                            <ListItemText
                                primary={
                                    <Stack
                                        flexDirection="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Typography>{entity?.description}</Typography>
                                        <Box display={'flex'}>
                                            <IconButton size="small" onClick={() => startEditing(entity)}>
                                                <EditIcon fontSize="small"/>
                                            </IconButton>
                                            <IconButton size="small"
                                                onClick={() => deleteConsent(entity.description)}>
                                                <DeleteIcon fontSize="small"/>
                                            </IconButton>
                                        </Box>
                                    </Stack>
                                }
                            />
                        </ListItem>)
                }
            </List>
            <Box display={'flex'} justifyContent={'end'} py={2}>
                <Button variant="contained" sx={{width: 150}}
                    onClick={() => manageConsents({activeMedicalConsents: activeConsents})}>
                    Save
                </Button>
            </Box>
            <AdmicityDialog
                handleClose={handleFinishEditing}
                title={openAddDialog ? 'New Medical Consent' : 'Update Medical Consent'}
                open={openAddDialog || openEditDialog}
                actions={[]}
            >
                <AdmicityForm
                    ref={formRef}
                    textFields={textFields}
                    handleSubmit={(data) => openAddDialog
                        ? handleAdd(data)
                        : handleEdit(data)}
                    buttonText="Save"
                />
            </AdmicityDialog>
        </Box>
    );
}

MedicalConsentSettings.propTypes = {
    medicalConsentOptions: PropTypes.object
};

export default Lookups;