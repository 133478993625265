import React, {useState} from 'react';
import {
    Container,
    Divider,
    styled,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useParams} from 'react-router-dom';
import useFileDownloader from '../../../../../utility/hooks/useFileDownloader';
import {useDispatch, useSelector} from 'react-redux';
import {retrieveProfile} from '../../ProfilesSlice';
import MedicalEvents from './MedicalEvents';
import MedicalConditions from './MedicalConditions';
import MedicalNotes from './MedicalNotes';
import MedicalOtc from './MedicalOtc';
import GpInfo from './GpInfo';
import PermissionCheck from '../../Components/PermissionCheck';

const MedicalFields = styled(Container)({
    display: 'flex', flexDirection: 'column', justifyContent: 'start'
});

const MedicalInfoTab = () => {
    const {studentId} = useParams();
    const {selectedProfile, currentSchool} = useSelector(state => state.profilesInfo);
    const dispatch = useDispatch();
    const [isUploading, setIsUploading] = useState(false);

    const downloadFile = useFileDownloader();

    return (
        <MedicalFields xs={12} sm={6} px={3}>

            <Typography variant="h5" py={2}>MEDICAL INFORMATION
                        FOR {selectedProfile.name?.toUpperCase()}</Typography>
            <Typography fontWeight={'bold'}>Please take care to complete this section of {selectedProfile.name}’s
                        Student Profile accurately. It is
                        your responsibility to ensure that it is correct and up-to-date at all times. The information
                        you provide will be relied upon by school staff and any other person or organisation who may
                        have {selectedProfile.name} in their care on behalf of {currentSchool.name}.</Typography>

            <Divider sx={{mt: 2, mb: 2}}/>

            <PermissionCheck
                permissionModule={selectedProfile.isApplicant ? 'ApplicantMedicalConditions' : 'StudentMedicalConditions'}
                returnType="readonly"
                render={(isView) => {
                    return (
                        <MedicalConditions
                            readonly={isView}
                            onConfirmSuccess={async () => dispatch(retrieveProfile({studentId}))}
                            selectedProfile={selectedProfile}
                            currentSchool={currentSchool}
                            downloadFile={downloadFile}
                            studentId={studentId}
                            setIsUploading={setIsUploading}
                            isUploading={isUploading}
                        />
                    )
                }
                }
            />

            <PermissionCheck
                permissionModule={selectedProfile.isApplicant ? 'ApplicantMedicalEvents' : 'StudentMedicalEvents'}
                returnType="readonly"
                render={(isView) => {
                    return (
                        <MedicalEvents
                            readonly={isView}
                            downloadFile={downloadFile}
                            studentId={studentId}
                            isUploading={isUploading}
                            setIsUploading={setIsUploading}
                            onConfirmSuccess={async () => dispatch(retrieveProfile({studentId}))}
                        />
                    )
                }}
            />

            <PermissionCheck
                permissionModule={selectedProfile.isApplicant ? 'ApplicantMedicalNotes' : 'StudentMedicalNotes'}
                returnType={'readonly'}
                render={(isView) => {
                    return (<MedicalNotes
                        readonly={isView}
                        downloadFile={downloadFile}
                        studentId={studentId}
                        isUploading={isUploading}
                        setIsUploading={setIsUploading}
                        onConfirmSuccess={async () => dispatch(retrieveProfile({studentId}))}
                    />
                    )
                }
                }
            />
            <PermissionCheck
                permissionModule={selectedProfile.isApplicant ? 'ApplicantMedicalConsents' : 'StudentMedicalConsents'}
                returnType={'readonly'}
                render={(isView) => {

                    return (<MedicalOtc
                        readOnly={isView}
                        studentId={studentId}
                        onConfirmSuccess={async () => dispatch(retrieveProfile({studentId}))}
                    />)
                }
                }
            />
            <PermissionCheck
                permissionModule={selectedProfile.isApplicant ? 'ApplicantGpInfo' : 'StudentGpInfo'}
                returnType={'readonly'}
                render={(isView) => {
                    return (<GpInfo
                        readOnly={isView}
                        studentId={studentId}
                        downloadFile={downloadFile}
                        isEdit={!isView}
                        onConfirmSuccess={async () => dispatch(retrieveProfile({studentId}))}
                    />
                    )
                }
                }
            />

        </MedicalFields>
    );
};

export default MedicalInfoTab;
