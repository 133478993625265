import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useGetGroupFiltersQuery} from '../../../api/services/groupService';
import {useNavigate} from 'react-router-dom';
import {selectProfile, setFilterId, setFilterMembersType, setProfileFilters} from './ProfilesSlice';
import withPagination from '../../../hoc/pagination/withPagination';
import ProfilesGrid from './Components/ProfilesGrid';
import {useGetProfilesQuery} from '../../../api/services/profilesService';
import {
    Box,
    Chip,
    colors,
    FormControlLabel,
    FormGroup,
    InputAdornment,
    MenuItem,
    Stack,
    styled,
    Switch,
    TextField
} from '@mui/material';
import AdmicityDrawer from '../../../shared-components/AdmicityDrawer';
import {resetPaginationState} from '../../../hoc/pagination/paginationSlice';
import Typography from '@mui/material/Typography';
import useUser from '../../../utility/hooks/useUser';
import SearchIcon from '@mui/icons-material/Search';
import useDebounce from '../../../utility/hooks/useDebounce';
import APPLICANT_STATUSES from '../../../constants/applicantStatuses';
import AdmicityIconMenu from '../../../shared-components/AdmicityIconMenu';
import DownloadReportPopup from './DownloadReportPopup/DownloadReportPopup';
import {GROUP_MEMBERS_TYPE} from '../../../constants/GroupMembersType';
import {ROLES} from '../../../constants/roles';

const SelectQuickFilter = styled(TextField)(({theme}) => ({
    '& .MuiOutlinedInput-root': {
        borderRadius: theme.spacing(1),
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.blueGrey.A100
        },
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
    },
}));

const GROUP_NAME_SEPARATOR = ' • ';

const SwitchQuickFilter = styled(FormGroup)(({theme}) => ({
    borderRadius: theme.spacing(1),
    border: '1px solid',
    borderColor: colors.blueGrey.A100,
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
}));

const ProfilesOverview = () => {
    const {user} = useUser();
    const isParentRole = user.isParent();
    const filterId = useSelector(state => state.profilesInfo.filterId);
    const filterMembersType = useSelector(state => state.profilesInfo.filterMembersType);
    const profileFilters = useSelector(state => state.profilesInfo.profileFilters);
    const {data: groupFilters = {modules: []}} = useGetGroupFiltersQuery(undefined, {
        skip: isParentRole
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const debouncedSearchStudentName = useDebounce(profileFilters.keyword.value, 500);

    const [renderReportType, setRenderReportType] = useState(undefined);

    const fetchFunctionExtraProps = useMemo(() => {
        return {
            filterId,
            filterMembersType,
            isParent: isParentRole,
            status: profileFilters.status.value,
            searchTerms: {
                fullName: debouncedSearchStudentName
            },
            fetchOnlyMyChildren: profileFilters.fetchOnlyMyChildren.value
        };
    }, [filterId, user.roles, profileFilters.status.value, debouncedSearchStudentName, profileFilters.fetchOnlyMyChildren]);

    const fetchFunctionSettings = useMemo(() => ({
        skip: isParentRole ? !user.roles : !filterId || !user.roles
    }), [filterId, user.roles, isParentRole]);

    const Table = useMemo(() => {
        return withPagination(
            ProfilesGrid,
            useGetProfilesQuery,
            fetchFunctionExtraProps,
            fetchFunctionSettings
        );
    }, [fetchFunctionExtraProps, fetchFunctionSettings]);

    useEffect(() => {
        if (!filterId && groupFilters?.modules.length > 0) {
            dispatch(setFilterId(groupFilters.modules[0].id));
            dispatch(setFilterMembersType(GROUP_MEMBERS_TYPE.students));
            dispatch(setProfileFilters({
                group: groupFilters.modules[0].name
            }));
        }
    }, [groupFilters]);

    const navigateToStudentProfile = (profile) => {
        if (filterMembersType === GROUP_MEMBERS_TYPE.employee) {
            if (user.hasRole(ROLES.SCHOOL_ADMIN)) {
                navigate(`employees/${profile.id}`);
            }
        } else {
            dispatch(selectProfile(profile));
            navigate(`students/${profile.id}`);
        }
    };

    return (
        <Box
            display="flex"
            flexGrow={1}
            minHeight="100%"
            marginTop={2}
            sx={{
                flexDirection: {
                    xs: 'column',
                    sm: 'column',
                    md: 'column',
                    lg: 'row',
                    xl: 'row',
                }
            }}
        >
            {
                groupFilters?.modules.length !== 0
                    ? <AdmicityDrawer
                        filters={groupFilters?.modules.length !== 0
                            ? groupFilters.modules
                            : []
                        }
                        filterId={filterId}
                        onApply={(filterId, paths) => {
                            dispatch(setFilterId(filterId));
                            dispatch(setFilterMembersType(paths?.find(f => f.id === filterId)?.membersType));
                            dispatch(resetPaginationState());
                            dispatch(setProfileFilters({
                                group: paths
                                    .filter((f, i) => i === 0 || f.selectable)
                                    .map(p => p.name)
                                    .join(GROUP_NAME_SEPARATOR),
                                keyword: ''
                            }));
                        }}
                        onFilterDeleted={(id) => {
                            if (filterId === id) {
                                dispatch(setProfileFilters({
                                    group: groupFilters.modules[0].name
                                }));
                                dispatch(setFilterId(groupFilters.modules[0].id));
                                dispatch(setFilterMembersType(GROUP_MEMBERS_TYPE.students));
                            }
                        }}
                        defaultSelected={filterId}
                        isParentRole={isParentRole}
                    />
                    : ''
            }
            <Box
                display="flex"
                flexDirection="column"
                width="100%"
                sx={{
                    flexGrow: 1,
                    minHeight: '100%',
                    paddingLeft: {
                        xs: 0,
                        sm: 0,
                        md: 0,
                        lg: 2,
                        xl: 2,
                    },
                    paddingRight: {
                        xs: 0,
                        sm: 0,
                        md: 0,
                        lg: 2,
                        xl: 2,
                    },
                }}
            >
                {
                    !isParentRole
                        ? <>
                            <Stack
                                direction="row"
                                alignItems="center"
                                gap={2}
                                justifyContent="space-between"
                            >
                                <TextField
                                    fullWidth
                                    placeholder="Enter name, surname, or full name"
                                    value={profileFilters.keyword.value}
                                    onChange={(e) => dispatch(setProfileFilters({
                                        keyword: e.target.value
                                    }))}
                                    inputProps={{maxLength: 200}}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon fontSize="small"/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                {
                                    filterMembersType !== GROUP_MEMBERS_TYPE.employee &&
                                    <AdmicityIconMenu
                                        icon="Download"
                                        title="Download report"
                                        menuItems={
                                            [
                                                {
                                                    label: 'Download CSV',
                                                    onClick: () => setRenderReportType('csv')
                                                },
                                                {
                                                    label: 'Download XLSX',
                                                    onClick: () => setRenderReportType('xlsx')
                                                }
                                            ]
                                        }
                                    />
                                }
                            </Stack>
                            <Stack
                                flexDirection="row"
                                flexWrap="wrap"
                                gap={2}
                                alignItems="end"
                                marginTop={2}
                                marginBottom={2}
                            >
                                {
                                    [profileFilters.group, profileFilters.keyword]
                                        .filter(f => f.value)
                                        .map(({label, value, deletable}) => (
                                            <Box
                                                key={label}
                                                display="inline-flex"
                                                flexDirection="row"
                                                alignItems="center"
                                                padding={1}
                                                border="1px dashed"
                                                borderRadius={2}
                                                borderColor={colors.blueGrey.A100}
                                                maxWidth={'100%'}
                                            >
                                                <Typography variant="subtitle2" fontWeight={600}>{label}:</Typography>
                                                <Chip
                                                    size="small"
                                                    sx={{
                                                        borderRadius: 2,
                                                        marginLeft: 1,
                                                        height: '100%',
                                                        '& .MuiChip-label': {
                                                            padding: 0.75,
                                                            overflowWrap: 'break-word',
                                                            whiteSpace: 'normal',
                                                            textOverflow: 'clip'
                                                        }
                                                    }}
                                                    label={value}
                                                    variant="outlined"
                                                    onDelete={deletable
                                                        ? () => dispatch(setProfileFilters({keyword: ''}))
                                                        : undefined
                                                    }
                                                />
                                            </Box>
                                        ))
                                }
                                {
                                    filterMembersType !== GROUP_MEMBERS_TYPE.employee &&
                                    <>
                                        <SelectQuickFilter
                                            size="small"
                                            select
                                            SelectProps={{
                                                renderValue: (value) =>
                                                    value != null
                                                        ? <span>
                                                <Typography
                                                    display="inline"
                                                    variant="subtitle2"
                                                    fontWeight={600}>
                                                    {profileFilters.status.label}: </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    display="inline">
                                                    {APPLICANT_STATUSES.find(x => x.value === value)?.label}
                                                </Typography>
                                        </span>
                                                        : profileFilters.status.label
                                            }}
                                            value={profileFilters.status.value}
                                            onChange={e => {
                                                dispatch(setProfileFilters({
                                                    status: e.target.value
                                                }));
                                                dispatch(resetPaginationState());
                                            }}>
                                            {
                                                APPLICANT_STATUSES.map(option =>
                                                    <MenuItem key={option.label} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                )
                                            }
                                        </SelectQuickFilter>
                                        <SwitchQuickFilter>
                                            <FormControlLabel
                                                control={<Switch
                                                    checked={profileFilters.fetchOnlyMyChildren.value}
                                                    onChange={e => {
                                                        dispatch(setProfileFilters({
                                                            fetchOnlyMyChildren: e.target.checked
                                                        }));
                                                        dispatch(resetPaginationState());
                                                    }}
                                                />}
                                                label={profileFilters.fetchOnlyMyChildren.label}
                                                slotProps={{
                                                    typography: {
                                                        variant: 'subtitle2'
                                                    }
                                                }}/>
                                        </SwitchQuickFilter>
                                    </>
                                }
                            </Stack>
                        </>
                        : ''
                }
                <Table
                    readonly={filterMembersType === GROUP_MEMBERS_TYPE.employee && !user.hasRole(ROLES.SCHOOL_ADMIN)}
                    onProfileSelect={navigateToStudentProfile}
                    noRowsOverlay={{
                        component: groupFilters?.modules.length > 0
                            ? <>
                                <Typography variant="h6">No Profiles Found</Typography>
                                {
                                    !isParentRole
                                        ? <Typography variant="subtitle1">No profiles match the selected filter
                                            criteria.</Typography>
                                        : ''
                                }
                            </>
                            : <>
                                <Typography variant="h6">No Data</Typography>
                                <Typography variant="subtitle1">Data is not synchronised yet.</Typography>
                            </>
                    }}
                />
                {
                    !isParentRole && renderReportType
                        ? <DownloadReportPopup
                            open={renderReportType}
                            groupFilters={groupFilters?.modules}
                            onClose={() => setRenderReportType(undefined)}
                            isCsv={renderReportType === 'csv'}
                        />
                        : null
                }
            </Box>
        </Box>
    );
};

export default ProfilesOverview;