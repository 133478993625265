import {apiService} from '../apiService';
import {axiosBaseQuery} from '../axiosBaseQuery';

const baseQuery = axiosBaseQuery({baseUrl: process.env.REACT_APP_BACKEND_URL}, {responseType: 'blob'});

export const downloadStudentsXlsxReport = (body) =>
    download({
        url: '/student/xlsx',
        method: 'POST',
        data: body
    });

export const downloadStudentsCsvReport = (body) =>
    download({
        url: '/student/csv',
        method: 'POST',
        data: body
    });

export const downloadFormDocument = ({documentId}) =>
    download({
        url: `/forms/download-document/${documentId}`,
        method: 'GET'
    });

export const downloadFormResponsesCsvReport = (body) =>
    download({
        url: `/forms/${body.formId}/requests/csv`,
        method: 'POST',
        data: body
    });

export const downloadFormResponsesPdfReport = (body) =>
    download({
        url: `/forms/${body.formId}/requests/pdf`,
        method: 'POST',
        data: body
    });

export const downloadParentFormResponsesPdfReport = (studentId) =>
    download({
        url: `/parent/requests/pdf${studentId ? `?studentId=${studentId}` : ''}`,
        method: 'GET'
    });

export const downloadFormResponsesXlsxReport = (body) =>
    download({
        url: `/forms/${body.formId}/requests/xlsx`,
        method: 'POST',
        data: body
    });

export const downloadSchoolDocument = ({documentId}) =>
    download({
        url: `/school/download-document/${documentId}`,
        method: 'GET'
    });

export const downloadProfileDocument = ({documentId, studentId}) =>
    download({
        url: `/student/${studentId}/download-document/${documentId}`,
        method: 'GET'
    });

export const downloadProfileSenDocument = ({documentId, studentId}) =>
    download({
        url: `/student/${studentId}/sen/download-document/${documentId}`,
        method: 'GET'
    });

export const downloadProfileEhcpDocument = ({documentId, studentId}) =>
    download({
        url: `/student/${studentId}/ehcp/download-document/${documentId}`,
        method: 'GET'
    });

export const downloadProfileMedicalConditionDocument = ({documentId, studentId}) =>
    download({
        url: `/student/${studentId}/medical/download-document/${documentId}`,
        method: 'GET',
    });

export const downloadProfileMedicalEventDocument = ({documentId, studentId}) =>
    download({
        url: `/student/${studentId}/medical/event/download-document/${documentId}`,
        method: 'GET',
    });

export const downloadProfileMedicalNoteDocument = ({documentId, studentId}) =>
    download({
        url: `/student/${studentId}/medical/note/download-document/${documentId}`,
        method: 'GET',
    });

export const downloadProfileVaccinationDocument = ({documentId, studentId}) =>
    download({
        url: `/student/${studentId}/medical/vaccination/download-document/${documentId}`,
        method: 'GET'
    });

export const downloadContactInvitesCsvReport = (body) =>
    download({
        url: '/school/contact-invites/csv',
        method: 'POST',
        data: body
    });

export const downloadContactInvitesXlsxReport = (body) =>
    download({
        url: '/school/contact-invites/xlsx',
        method: 'POST',
        data: body
    });

export const downloadPdfReport = ({id}) =>
    download({
        url: `/forms/file/download?documentId=${id}`,
        method: 'GET',
    });

export const getSchoolLogo = (id) =>
    download({
        url: `/school/${id}/logo`,
        method: 'GET',
    });

export const downloadConsentFile = (consentCode) =>
    download({
        url: `/lookup/consents/${consentCode}/file`,
        method: 'GET',
    });

export const downloadFormRequestAttachment = ({fileId, formRequestId, studentId, shouldDownloadByCurrentUser}) => {
    const baseUrl = shouldDownloadByCurrentUser
        ? `/forms/my/requests/${formRequestId}/attachment/${fileId}`
        : `/forms/requests/${formRequestId}/attachment/${fileId}`;
    const url = studentId
        ? `${baseUrl}?studentId=${studentId}`
        : baseUrl;

    return download({url, method: 'GET'});
};

const download = async (config) => {
    try {
        const result = await baseQuery(config);

        if (result.error) {
            if (result.error.status === 401) {
                return {
                    error: {
                        customMessage: 'Unable to download the requested file. Please log in to your account to access it'
                    }
                };
            }
            if (result.error.data) {
                return {
                    error: {
                        customMessage: result.error.data
                    }
                };
            }
            return {
                error: {
                    customMessage: 'Something went wrong while downloading',
                }
            };
        }
        const filename = result.headers['content-disposition']
            ?.split('filename=')[1]
            ?.split(';')[0]
            ?.replace(/["']/g, '') ?? 'unknown.ext';

        return {
            filename,
            blob: result.data
        };
    } catch (e) {
        return {
            error: e
        };
    }
};

export const filesService = apiService.injectEndpoints({
    endpoints: (builder) =>
        ({
            uploadSchoolDocuments: builder.mutation({
                query: (files) => {
                    const formData = new FormData();

                    files.forEach(file => formData.append('files[]', file));

                    return ({
                        method: 'POST',
                        url: '/school/upload-documents',
                        data: formData,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    });
                },
                invalidatesTags: [{type: 'SchoolDocuments', id: 'LIST'}],
            }),
            uploadFormPdfDocument: builder.mutation({
                query: (file) => {
                    const formData = new FormData();
                    formData.append('files[]', file);

                    return ({
                        method: 'POST',
                        url: '/school/upload-documents',
                        data: formData,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    });
                },
                invalidatesTags: [{type: 'SchoolDocuments', id: 'LIST'}],
            }),
            uploadFormDocuments: builder.mutation({
                query: (files) => {
                    const formData = new FormData();

                    files.forEach(file => formData.append('files[]', file));

                    return ({
                        method: 'POST',
                        url: '/forms/upload-documents',
                        data: formData,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    });
                },
                invalidatesTags: ['FormDocument'],
            }),
            uploadStudentDocuments: builder.mutation({
                query: ({studentId, data}) => {

                    return ({
                        method: 'POST',
                        url: `/student/${studentId}/documents`,
                        data,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    });
                },
                invalidatesTags: [{type: 'StudentDocuments', id: 'LIST'}],
            }),
            uploadLogo: builder.mutation({
                query: (file) => {
                    const formData = new FormData();
                    formData.append('file', file);

                    return ({
                        method: 'POST',
                        url: '/school/upload-logo',
                        data: formData,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    });
                }
            }),
            deleteLogo: builder.mutation({
                query: (body) => ({
                    method: 'DELETE',
                    url: '/school/delete-logo',
                    data: body
                }),
            }),
            deleteSchoolDocument: builder.mutation({
                query: (body) => ({
                    method: 'DELETE',
                    url: '/school/delete-document',
                    data: body
                }),
                invalidatesTags: [{type: 'SchoolDocuments', id: 'LIST'}],
            }),
            deleteFormDocument: builder.mutation({
                query: (body) => ({
                    method: 'DELETE',
                    url: '/forms/delete-document',
                    data: body
                }),
                invalidatesTags: ['FormDocument'],
            }),
            deleteStudentDocument: builder.mutation({
                query: ({studentId, documentId}) => ({
                    method: 'DELETE',
                    url: `/student/${studentId}/document/${documentId}`,
                }),
                invalidatesTags: [{type: 'StudentDocuments', id: 'LIST'}],
            }),
            getSchoolDocuments: builder.query({
                query: (body) => ({
                    url: '/school/documents',
                    method: 'POST',
                    data: body
                }),
                transformErrorResponse: (_) => ({message: 'Failed to retrieve documents'}),
                providesTags: (_) => [{type: 'SchoolDocuments', id: 'LIST'}]
            }),
            getStudentDocuments: builder.query({
                query: ({studentId, isParent}) => ({
                    url: isParent ? `/parent/student/${studentId}/document` : `/student/${studentId}/document`,
                    method: 'GET',
                }),
                transformErrorResponse: (_) => ({message: 'Failed to retrieve documents'}),
                providesTags: (_) => [{type: 'StudentDocuments', id: 'LIST'}]
            }),
            uploadConsentFile: builder.mutation({
                query: (data) =>
                    ({
                        method: 'POST',
                        url: '/lookup/consents/upload',
                        data,
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    }),
                invalidatesTags: ['ConsentLookups']
            }),
            deleteConsentFile: builder.mutation({
                query: (body) => ({
                    method: 'DELETE',
                    url: '/lookup/consents/file',
                    data: body
                }),
                invalidatesTags: ['ConsentLookups']
            }),
        })
});

export const {
    useUploadSchoolDocumentsMutation,
    useUploadFormDocumentsMutation,
    useUploadStudentDocumentsMutation,
    useDeleteSchoolDocumentMutation,
    useDeleteFormDocumentMutation,
    useDeleteStudentDocumentMutation,
    useGetSchoolDocumentsQuery,
    useGetStudentDocumentsQuery,
    useUploadLogoMutation,
    useDeleteLogoMutation,
    useUploadConsentFileMutation,
    useDeleteConsentFileMutation,
} = filesService;
