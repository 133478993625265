import React, {useEffect, useState} from 'react';
import {Box, Button, Checkbox, CircularProgress, Container, FormControlLabel, styled} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useParams} from 'react-router-dom';
import {FileDownload, UploadFile} from '@mui/icons-material';
import useUser from '../../../../utility/hooks/useUser';
import {
    downloadProfileDocument,
    downloadSchoolDocument,
    useDeleteStudentDocumentMutation,
    useGetSchoolDocumentsQuery,
    useGetStudentDocumentsQuery,
    useUploadStudentDocumentsMutation
} from '../../../../api/services/filesService';
import useFileDownloader from '../../../../utility/hooks/useFileDownloader';
import DocumentList from '../Components/DocumentList';
import useNotification from '../../../../utility/hooks/useNotification';
import {useSelector} from 'react-redux';
import Uploader from '../../../SchoolManagement/Setup/Uploader';
import AdmicityDialog from '../../../../shared-components/AdmicityDialog';
import objectToFormData from '../../../../utility/objectToFormData';

const DocumentFields = styled(Container)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start'
});

styled(FileDownload)(({theme}) => ({
    color: theme.palette.primary.main, cursor: 'pointer'
}));

const ProfileDocumentsTab = () => {
    const {user} = useUser();
    const {studentId} = useParams();
    const [isForParent, setDocumentForParent] = useState(true);
    const {selectedProfile} = useSelector(state => state.profilesInfo);
    const [documents, setDocuments] = useState({
        profileDocuments: [],
        schoolDocuments: []
    });
    const [openAddDocumentsDialog, setOpenAddDocumentsDialog] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [isUploadSizeExceedsLimit, setIsUploadSizeExceedsLimit] = useState(false);
    const {showErrorNotification, showSuccessNotification} = useNotification();

    const [documentsPaginationModels, setDocumentsPaginationModels] = useState({
        schoolDocuments: {
            pageNumber: 0,
            pageSize: 20,
            hasMore: true
        }
    });
    const {
        data: schoolDocuments,
        isLoading: isSchoolDocumentsLoading
    } = useGetSchoolDocumentsQuery(documentsPaginationModels.schoolDocuments);
    const {data: studentDocuments} = useGetStudentDocumentsQuery({studentId, isParent: user.isParent()});
    const [uploadFiles, {
        isSuccess: isUploadFileSuccess,
        isError: isUploadFileError,
    }] = useUploadStudentDocumentsMutation();
    const [deleteFile, {
        isSuccess: isDeleteFileSuccess,
        isError: isDeleteFileError,
    }] = useDeleteStudentDocumentMutation();

    const downloadFile = useFileDownloader();

    useEffect(() => {
        if (studentDocuments) {
            setDocuments(currentDocuments => ({
                ...currentDocuments,
                profileDocuments: studentDocuments
            }));
        }
    }, [studentDocuments]);

    useEffect(() => {
        if (schoolDocuments && !isSchoolDocumentsLoading) {
            setDocuments(currentDocuments => ({
                ...currentDocuments,
                schoolDocuments: [...currentDocuments.schoolDocuments, ...schoolDocuments.items]
            }));
            setDocumentsPaginationModels(currentModels => ({
                ...currentModels,
                schoolDocuments: {
                    ...currentModels.schoolDocuments,
                    hasMore: schoolDocuments.items.length > 0
                }
            }));
        }
    }, [schoolDocuments, isSchoolDocumentsLoading]);

    useEffect(() => {
        if (isDeleteFileError || isUploadFileError) {
            showErrorNotification('Failed to update documents');
        } else if (isDeleteFileSuccess || isUploadFileSuccess) {
            showSuccessNotification('Documents updated successfully');
        }
    }, [isDeleteFileError, isDeleteFileSuccess, isUploadFileError, isUploadFileSuccess]);

    const handleChangeCheckbox = (event) => setDocumentForParent(event.target.checked);

    const deleteDocument = document => deleteFile({studentId, documentId: document.id});

    const handleScrollSchoolDocumentList = event => {
        const {scrollTop, clientHeight, scrollHeight} = event.currentTarget;

        if (documentsPaginationModels.schoolDocuments.hasMore &&
            scrollHeight - scrollTop === clientHeight &&
            !isSchoolDocumentsLoading
        ) {
            setDocumentsPaginationModels(currentModels => ({
                ...currentModels,
                schoolDocuments: {
                    ...currentModels.schoolDocuments,
                    pageNumber: currentModels.schoolDocuments.pageNumber + 1
                }
            }));
        }
    };

    const documentsMap = [
        {
            sectionName: `${selectedProfile.name}'s Documents`,
            documents: documents.profileDocuments,
            allowDelete: true,
            onDownload: async documentId => await downloadFile(downloadProfileDocument, {documentId, studentId}),
            onDelete: deleteDocument
        },
        {
            sectionName: 'School Documents',
            documents: documents.schoolDocuments,
            paginationHandler: handleScrollSchoolDocumentList,
            onDownload: async documentId => await downloadFile(downloadSchoolDocument, {documentId})
        }
    ];

    const handleDialogClose = () => {
        setOpenAddDocumentsDialog(false);
        setSelectedFiles([]);
        setIsUploading(false);
        setIsUploadSizeExceedsLimit(false);
        setDocumentForParent(true);
    };

    const handleFilesSelect = (files) => {
        const newFiles = files.filter(file => !selectedFiles.some(s => s.name === file.name));

        setSelectedFiles([...selectedFiles, ...newFiles]);
    };

    const handleFileDeleteFromUploadContext = (filename) => {
        const files = [...selectedFiles].filter(f => f.name !== filename);

        setSelectedFiles(files);

        if (files.length === 0) {
            setDocumentForParent(true);
        }
    };

    const handleFilesUpload = async () => {
        if (selectedFiles.length === 0) return;

        setIsUploading(true);

        await uploadFiles({
            studentId,
            data: objectToFormData({studentId, isForParent, files: selectedFiles})
        });

        handleDialogClose();
    };

    return <>
        <DocumentFields>
            {
                !user.isParent()
                    ? <div>
                        <Button
                            sx={{mb: 2}}
                            variant="contained"
                            startIcon={<UploadFile/>}
                            onClick={() => setOpenAddDocumentsDialog(true)}
                        >
                            Add documents
                        </Button>
                    </div>
                    : null
            }
            {documentsMap[0].documents?.length === 0 && documentsMap[1].documents?.length === 0 &&
                <Box display={'flex'} justifyContent={'center'} py={2}>
                    <Typography color={'silver'} variant={'h6'}>No documents have been added</Typography>
                </Box>}
            <DocumentList documents={documentsMap}/>
        </DocumentFields>
        <AdmicityDialog
            handleClose={handleDialogClose}
            title={'Add documents'}
            open={openAddDocumentsDialog}
            actions={[
                {label: 'Cancel', onClick: handleDialogClose},
                {
                    label: 'Upload',
                    onClick: handleFilesUpload,
                    disabled: (isUploading || selectedFiles.size === 0) || isUploadSizeExceedsLimit,
                    startIcon: isUploading ? <CircularProgress color="inherit" size={16}/> : undefined
                }
            ]}
            maxWidth={'md'}
        >
            <Uploader
                totalUploadSizeInMb={50}
                onFilesSelect={handleFilesSelect}
                onFileDelete={handleFileDeleteFromUploadContext}
                onUploadLimitExceed={setIsUploadSizeExceedsLimit}
                value={Array.from(selectedFiles.values())}
                readonly={isUploading}
            />
            {
                selectedFiles.length > 0
                    ? <FormControlLabel
                        sx={{
                            paddingTop: 1
                        }}
                        control={<Checkbox checked={isForParent} onChange={event => handleChangeCheckbox(event)}/>}
                        label={'Available for parent'}/>
                    : null
            }
        </AdmicityDialog>
    </>;
};

export default ProfileDocumentsTab;
