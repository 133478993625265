export const FormBuilderComponentTypes = {
    AdditionalNotes: 'additionalNotes',
    AppointmentBooking: 'appointmentBooking',
    Payment: 'payment',
    Attachment: 'attachment',
    Image: 'image',
    CheckboxQuestion: 'checkboxQuestion',
    Dropdown: 'dropdown',
    MultipleChoice: 'multipleChoice',
    Signature: 'signature',
    SingleChoice: 'singleChoice',
    SingleText: 'singleText',
    TextBox: 'textBox',
    TickBoxGrid: 'tickBoxGrid',
    MultipleChoiceGrid: 'multipleChoiceGrid',
    LinearScale: 'linearScale',
    OfferStatusChoice: 'offerStatusChoice',
    FileUploader: 'fileUploader',
    DateTime: 'dateTime',
    Date: 'date',
};

export const FORM_BUILDER_COMPONENTS = [
    {
        type: FormBuilderComponentTypes.AdditionalNotes,
        description: 'Notes'
    },
    {
        type: FormBuilderComponentTypes.AppointmentBooking,
        description: 'Appointment Booking'
    },
    {
        type: FormBuilderComponentTypes.Payment,
        description: 'Payment'
    },
    {
        type: FormBuilderComponentTypes.Attachment,
        description: 'Attachment'
    },
    {
        type: FormBuilderComponentTypes.Image,
        description: 'Image'
    },
    {
        type: FormBuilderComponentTypes.CheckboxQuestion,
        description: 'Checkbox'
    },
    {
        type: FormBuilderComponentTypes.Dropdown,
        description: 'Dropdown'
    },
    {
        type: FormBuilderComponentTypes.MultipleChoice,
        description: 'Multiple Choice'
    },
    {
        type: FormBuilderComponentTypes.Signature,
        description: 'Signature'
    },
    {
        type: FormBuilderComponentTypes.SingleChoice,
        description: 'Single Choice'
    },
    {
        type: FormBuilderComponentTypes.SingleText,
        description: 'Single Line'
    },
    {
        type: FormBuilderComponentTypes.TextBox,
        description: 'Textbox'
    },
    {
        type: FormBuilderComponentTypes.TickBoxGrid,
        description: 'Tick Box Grid'
    },
    {
        type: FormBuilderComponentTypes.MultipleChoiceGrid,
        description: 'Multiple Choice Grid'
    },
    {
        type: FormBuilderComponentTypes.LinearScale,
        description: 'Linear Scale'
    },
    {
        type: FormBuilderComponentTypes.OfferStatusChoice,
        description: 'Application Status Choice'
    },
    {
        type: FormBuilderComponentTypes.FileUploader,
        description: 'File Uploader'
    },
    {
        type: FormBuilderComponentTypes.DateTime,
        description: 'Date and Time'
    },
    {
        type: FormBuilderComponentTypes.Date,
        description: 'Date'
    }
];
