import {apiService} from '../apiService';
import {INVITE_STATUSES} from '../../constants/registrationInviteStatuses';
import {isDateExpired} from '../../utility/dateUtil';

export const accountService = apiService.injectEndpoints({
    endpoints: (builder) => {
        return {
            getPermissions: builder.query({
                query: () => ({
                    url: '/account/permissions/list'
                }),
                providesTags: (_) => [{type: 'Permissions', id: 'LIST'}],
            }),
            getRoles: builder.query({
                query: () => ({
                    url: '/account/roles/list'
                })
            }),
            assignRolePermissions: builder.mutation({
                query: (body) => ({
                    url: '/account/role/assign-permissions',
                    method: 'POST',
                    data: body,
                }),
                invalidatesTags: [{type: 'Permissions', id: 'LIST'}],
            }),
            updateUserRole: builder.mutation({
                query: (body) => ({
                    url: '/account/role',
                    method: 'PUT',
                    data: body,
                }),
                invalidatesTags: ['SchoolUsers'],
            }),
            getRegistrationInvites: builder.query({
                query: (body) => ({
                    url: '/account/invites',
                    method: 'POST',
                    data: body,
                }),
                transformResponse: (response) => ({
                    ...response,
                    items: response.items.map(item => ({
                        id: item.id,
                        email: item.email,
                        expires: item.expires,
                        status: item.isFailed
                            ? INVITE_STATUSES.failed
                            : item.completedDate
                                ? INVITE_STATUSES.completed
                                : isDateExpired(item.expires)
                                    ? INVITE_STATUSES.expired
                                    : INVITE_STATUSES.pending,
                        completedDate: item.completedDate
                    }))
                }),
                providesTags: (_) => [{type: 'Invites', id: 'LIST'}],
                transformErrorResponse: (_) => ({message: 'Failed to retrieve invites'})
            }),
            sendRegistrationInvite: builder.mutation({
                query: (body) => ({
                    url: '/account/send-invite',
                    method: 'POST',
                    data: body,
                }),
                invalidatesTags: [{type: 'Invites', id: 'LIST'}],
                transformErrorResponse: (error) => {
                    const defaultErrorMessage = ['Something went wrong. Please try again later.'];
                    const errorMessages = {
                        400: error?.data?.validationErrors
                            ? error.data.validationErrors.map(x => x.errorMessage)
                            : defaultErrorMessage,
                        500: ['Internal server error.']
                    };

                    return errorMessages[error.status] ?? defaultErrorMessage;
                }
            }),
            deactivateUser: builder.mutation({
                query: (body) => ({
                    url: '/account/deactivate',
                    method: 'POST',
                    data: body,
                }),
                invalidatesTags: ['SchoolUsers'],
            }),
            activateUser: builder.mutation({
                query: (body) => ({
                    url: '/account/activate',
                    method: 'POST',
                    data: body,
                }),
                invalidatesTags: ['SchoolUsers'],
            }),
        };
    },
});

export const {
    useGetPermissionsQuery,
    useGetRolesQuery,
    useAssignRolePermissionsMutation,
    useUpdateUserRoleMutation,
    useGetRegistrationInvitesQuery,
    useSendRegistrationInviteMutation,
    useDeactivateUserMutation,
    useActivateUserMutation
} = accountService;
